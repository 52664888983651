/* eslint func-names: off, eqeqeq: off, camelcase: off, no-self-assign: off, unicorn/prefer-number-properties: off, unicorn/prevent-abbreviations: off, no-eq-null: off, max-depth: off, complexity: off, max-params: off, unicorn/prefer-query-selector: off, no-negated-condition: off, prefer-rest-params: off */

// JAVASCRIPT GERANT L'AFFICHAGE GENERIQUE DES PAGES DES SITES EUROPARL
// Copyright : European Parliament

// Classes JS definies dans ce fichier :
//  . Select          : Script convertissant un selecteur <select> en composant JavaScript personnalisable
//  . Accordion       : Script convertissant l'affichage en accordeon (expand/collapse [+]/[-])

// Fonctionnalites JS gerees par ce fichier :
//  . detection du support/device utilise pour visionner le site (mobile/desktop)
//  . affichage parallaxe de la barre d'outil inferieure
//  . ouverture/fermeture du menu de recherche en mode reduit
//  . ouverture/fermeture du menu de navigation en mode reduit
//  . navigation clavier dans le menu de navigation
//  . convertion des selecteurs <select> presents dans l'entete en composant JavaScript
//  . convertion de la liste des sites "other websites" en select JS pour le mode reduit
//  . affichage accordeon du pied de page en mode reduit
//  . gestion de l'affichage sur les menus lateraux (effet parallaxe + mode accordeon)

/* ============================================================================================================================================================================ */
/* === EXPAND/COLLAPSE (Version 2 [+]/[-]) =============================================================================================================== */
/* ============================================================================================================================================================================ */

function expandQuestions() {
  const headings = document.querySelectorAll('.ep_expand-title');

  Array.prototype.forEach.call(headings, function (h) {
    const btn = h.querySelector('.ep_expand-trigger');
    const target = h.nextElementSibling;

    let button;
    let div;

    if (
      h.closest('.ep_item').classList.contains('ep_expand') &&
      !h.closest('.ep_item').classList.contains('loaded-question')
    ) {
      button = h.parentNode.querySelectorAll('button')[0];
      button.dataset.expanded = true;
      button.setAttribute('aria-expanded', true);
      div = h.parentNode.querySelectorAll('.ep_expand-content')[0];
      div.removeAttribute('hidden');
      h.closest('.ep_item').classList.add('loaded-question');
    } else if (
      h.closest('.ep_item').classList.contains('ep_wrapper-expand-collapse') &&
      !h.closest('.ep_item').classList.contains('loaded-question')
    ) {
      h.closest('.ep_item').classList.remove('ep_wrapper-expand-collapse');
      button = h.parentNode.querySelectorAll('button')[0];
      button.dataset.expanded = false;
      button.setAttribute('aria-expanded', false);
      div = h.parentNode.querySelectorAll('.ep_expand-content')[0];
      div.setAttribute('hidden', true);
      h.closest('.ep_item').classList.add('loaded-question');
    }

    function handleClick(event) {
      const wrapperExpand = event.target.closest('.ep_item');
      const wrapperExpandStyle = wrapperExpand.classList.contains(
        'ep_wrapper-expand-collapse'
      );
      const expanded = btn.getAttribute('aria-expanded') === 'true';
      const expandedData = btn.dataset.expanded === 'true';
      if (wrapperExpandStyle) {
        wrapperExpand.classList.remove('ep_wrapper-expand-collapse');
      } else {
        wrapperExpand.classList.add('ep_wrapper-expand-collapse');
      }

      btn.setAttribute('aria-expanded', !expanded);
      btn.dataset.expanded = !expandedData;
      target.hidden = expanded;
    }

    if (btn && !btn.classList.contains('has-click-handler')) {
      btn.classList.add('has-click-handler');
      btn.addEventListener('click', handleClick);
    }
  });
}

export { expandQuestions };
