/* eslint func-names: off, eqeqeq: off, camelcase: off, no-self-assign: off, unicorn/prefer-number-properties: off, unicorn/prevent-abbreviations: off, no-eq-null: off, max-depth: off, complexity: off, max-params: off, unicorn/prefer-query-selector: off, no-negated-condition: off, prefer-rest-params: off */

// JAVASCRIPT GERANT L'AFFICHAGE GENERIQUE DES PAGES DES SITES EUROPARL
// Copyright : European Parliament

// Classes JS definies dans ce fichier :
//  . Select          : Script convertissant un selecteur <select> en composant JavaScript personnalisable
//  . Accordion       : Script convertissant l'affichage en accordeon (expand/collapse [+]/[-])

// Fonctionnalites JS gerees par ce fichier :
//  . detection du support/device utilise pour visionner le site (mobile/desktop)
//  . affichage parallaxe de la barre d'outil inferieure
//  . ouverture/fermeture du menu de recherche en mode reduit
//  . ouverture/fermeture du menu de navigation en mode reduit
//  . navigation clavier dans le menu de navigation
//  . convertion des selecteurs <select> presents dans l'entete en composant JavaScript
//  . convertion de la liste des sites "other websites" en select JS pour le mode reduit
//  . affichage accordeon du pied de page en mode reduit
//  . gestion de l'affichage sur les menus lateraux (effet parallaxe + mode accordeon)

/* ============================================================================================================================================================================ */
/* === GESTION DES MENUS "TABLE OF CONTENT" =================================================================================================================================== */
/* ============================================================================================================================================================================ */

import { Accordion } from './accordion.js';
import { FollowingScroll } from './follow-scroll.js';

const Data = { convertlist: [], reference: {}, toolbar: null };

// - VALIDATION - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// VALIDATION : controle de l'objet de donnees passe en parametre
function check(d) {
  if (typeof d === 'object') {
    if (d != null) {
      return d.tagName != null;
    }

    return false;
  }

  return false;
}

// - CONVERTION - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

function convert(target, parameters) {
  // Initialisation des variables
  let i;
  let t;
  let b;
  let f;
  let r;
  let data = null;
  // Recherche des balises utiles
  const o = {};
  o.parent = target;
  o.menu = null;
  o.sections = [];
  if (check(o.parent)) {
    if (!o.parent.dataset.tableofcontent) {
      // Recherche de la balise parent
      t = o.parent.querySelectorAll('.ep_tableofcontent-menu');
      if (t.length > 0) o.menu = t[0];
    } else {
      o.parent = null;
    }
  }

  // Verification des balise transmises en parametre
  if (check(o.parent) && check(o.menu)) {
    // Definition de la recherche des balises pour l'affichage en accordeon
    f = function (f_tagparent) {
      let f_t;
      let f_i;
      // Initialisation de l'objet de donnees
      const f_d = {
        parent: f_tagparent,
        title: null,
        ticon: null,
        open: null,
        close: null,
        content: null,
        list: null,
      };
      // Recherche des liens d'ouverture/fermeture sans JS
      f_t = f_d.parent.querySelectorAll('.ep_menu-access');
      f_i = f_t.length - 1;
      for (f_i = f_i; f_i >= 0; f_i--) {
        if (f_t[f_i].classList.contains('ep_openaccess')) f_d.open = f_t[f_i];
        else if (f_t[f_i].classList.contains('ep_closeaccess'))
          f_d.close = f_t[f_i];
        if (f_d.open != null && f_d.close != null) break;
      }

      // Recherche du titre du bloc pour la convertion en lien d'ouverture/fermeture via JS
      f_t = f_d.parent.querySelectorAll('.ep_title');
      if (f_t.length > 0) {
        f_d.title = f_t[0];
      }

      // Recherche du bloc de contenu a deployer
      f_t = f_d.parent.querySelectorAll('.ep_list');
      if (f_t.length > 0) {
        f_d.content = f_t[0];
        f_t = f_d.content.querySelectorAll('ul');
        if (f_t.length > 0) {
          f_d.list = f_t[0];
        }
      }

      // Envoi du resultat
      return check(f_d.parent) &&
        check(f_d.title) &&
        check(f_d.open) &&
        check(f_d.close) &&
        check(f_d.content) &&
        check(f_d.list)
        ? f_d
        : null;
    };

    // Recherche des sections du menu a convertir en accordeon
    t = o.menu.querySelectorAll('.ep_content');
    i = t.length - 1;
    for (i = i; i >= 0; i--) {
      r = f(t[i]);
      if (r != null) o.sections.push(r);
    }

    // Verification des donnees obtenues
    if (o.parent.classList.contains('ep_gridcolumn') && o.menu != null) {
      // Initialisation de l'objet de donnees du widget
      data = {};
      data.index = null; // [INT]                   : Identifiant du widget dans le listing
      data.reference = null; // [STRING]                  : Identifiant de la balise convertie
      data.column = o.parent; // [NODE]                   : Reference de la balise [colonne] parente au menu
      data.menu = o.menu; // [NODE]                   : Balise principale du menu
      data.items = []; // [ARRAY]                   : Liste des references des sections [+]/[-]
      // Sauvegarde des donnees dans la classe
      Data.convertlist.push(data);
      data.index = Data.convertlist.length - 1;
      data.reference = 'tableofcontentid_' + data.index;
      Data.reference[data.reference] = data.index;
      data.column.dataset.tableofcontent = data.index;
      data.column.classList.remove('ep-layout_followingscroll');
      // Autorisation de l'affichage visuel
      data.menu.dataset.converted = true;
      // Traitement du suivi du menu
      t = FollowingScroll.convert(o.parent, o.menu, null);
      if (t.converted) data.follow = t.data.index;
      // Convertion de la liste des elements en mode accordeon [+]/[-]
      if (o.sections.length > 0) {
        r = null;
        i = o.sections.length - 1;
        i = o.sections.length - 1;
        for (i = i; i >= 0; i--) {
          t = Accordion.convert(
            o.sections[i].parent, // Reference du conteneur global
            o.sections[i].title, // Reference du nouveau bouton d'ouverture [+]/[-] (conteneur global)
            o.sections[i].content, // Reference du conteneur a animer
            o.sections[i].list, // Reference de la liste generale des liens
            {
              openbtn: o.sections[i].open, // Reference de la balise du bouton d'ouverture d'origine a supprimer
              closebtn: o.sections[i].close, // Reference de la balise du bouton de fermeture d'origine a supprimer
              cb_afteranim(_e) {
                refresh(data.follow);
              }, // Fonction a appeler lors de la fin de l'animation d'ouverture/fermeture
            }
          );
          if (t.converted) {
            data.items.push(t.data.index);
            if (o.sections[i].parent.dataset.selected == 'true')
              r = t.data.index;
          }
        }
      }

      // Ajout des dependances entre les sections
      i = data.items.length;
      for (i = i; i >= 0; i--) {
        Accordion.setdependency(data.items[i], data.items);
      }

      // Ouverture du menu selectionne lors de l'initialisation de la page
      if (r != null) Accordion.open(r, true, true, true);
    } else data = null;
  }

  // Appel de la fonction "callback" apres la convertion du selecteur si demandee
  b = false;
  if (
    parameters != null &&
    typeof parameters === 'object' &&
    typeof parameters.cb_afterconvert === 'function'
  )
    b = true;
  if (b) parameters.cb_afterconvert(data != null, data);
  else return { converted: data != null, data };
}

// - GESTION DE L'OUVERTURE ET FERMETURE DE PANNEAUX  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// AFFICHAGE : Mise a jour de l'affichage du menu
function refresh(index) {
  index =
    arguments.length[2] && typeof arguments[1] === 'object'
      ? arguments[2]
      : arguments[1];
  if (!isNaN(index) && index >= 0 && index < Data.convertlist.length) {
    FollowingScroll.refresh(Data.convertlist[index]);
  } else {
    let i = Data.convertlist.length - 1;
    for (i = i; i >= 0; i--) {
      FollowingScroll.refresh(Data.convertlist[i]);
    }
  }
}

// - GESTION DES FONCTIONS PUBLIQUES  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const TableOfContent = {
  // Fonction ajoutant les fonctionnalites au menu "Table of Content"
  convert, // Parametres de la fonction : TableOfContent.convert(reference, params)
  //    > reference             // [NODE]               (mandatory)   : Reference de la balise "menu"
  //    > params              // [OBJECT]             (optional)    : Objet de personnalisation du composant
  //      > params.cb_afterconvert    // [FUNCTION] (need a return value = true)    : Fonction appelee apres la convertion du bloc
  //
  // Reponse de la fonction : TableOfContent.convert() = result
  //    > result.converted          // [BOOLEAN]                  : Defini si la fonctionnalite a pu etre ajoutee ou non
  //    > result.data             // [OBJECT]                     : Objet de donnees contenant toutes les informations du composant converti (vaut "null" si la convertion a echoue)

  // Fonction de mise a jour de la position (scroll) du composant
  refresh, // Parametres de la fonction : TableOfContent.refresh(reference)
  //    > reference           // [NUMBER]               (optional) : Reference du composant a manipuler (si non mentionne, tous les composants convertis seront mise a jour)
};

export { TableOfContent };
