/* eslint func-names: off, eqeqeq: off, camelcase: off, no-self-assign: off, unicorn/prefer-number-properties: off, unicorn/prevent-abbreviations: off, no-eq-null: off, max-depth: off, complexity: off, max-params: off, unicorn/prefer-query-selector: off, no-negated-condition: off, prefer-rest-params: off */

// Classes JS definies dans ce fichier :
//  . Select          : Script convertissant un selecteur <select> en composant JavaScript personnalisable
//  . Accordion       : Script convertissant l'affichage en accordeon (expand/collapse [+]/[-])

// Fonctionnalites JS gerees par ce fichier :
//  . detection du support/device utilise pour visionner le site (mobile/desktop)
//  . affichage parallaxe de la barre d'outil inferieure
//  . ouverture/fermeture du menu de recherche en mode reduit
//  . ouverture/fermeture du menu de navigation en mode reduit
//  . navigation clavier dans le menu de navigation
//  . convertion des selecteurs <select> presents dans l'entete en composant JavaScript
//  . convertion de la liste des sites "other websites" en select JS pour le mode reduit
//  . affichage accordeon du pied de page en mode reduit
//  . gestion de l'affichage sur les menus lateraux (effet parallaxe + mode accordeon)

/* ============================================================================================================================================================================ */
/* === CONVERTION DES SELECTEURS STATIQUES EN VERSION DYNAMIQUE =============================================================================================================== */
/* ============================================================================================================================================================================ */

const Data = { convertlist: [], reference: {} };

// - VALIDATION - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// VALIDATION : controle de l'objet de donnees passe en parametre
function check(d) {
  if (typeof d === 'object') {
    if (d != null) {
      return d.tagName != null;
    }

    return false;
  }

  return false;
}

// VALIDATION : convertion du text
function clean(t) {
  return typeof t === 'string'
    ? t
        .replace(/&amp;/g, '&')
        .replace(/&nbsp;/g, ' ')
        .replace(/&#039;/g, "'")
    : t;
}

// - CONVERTION - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Fonction initiale convertissant un selecteur en version JS
function askconvertion(tag, parameters) {
  let result = { converted: false, data: null };
  if (typeof tag === 'string') tag = document.getElementById(tag);
  if (check(tag)) {
    if (!tag.dataset.selectconverted) {
      if (tag.tagName.toLowerCase() == 'select') {
        // Traitement des selecteurs classiques <select>
        result = convert(tag, parameters);
      } else if (
        tag.tagName.toLowerCase() == 'div' &&
        tag.classList.contains('ep_fakeselect')
      ) {
        // Traitement des faux selecteurs HTML
        let i;
        let l;
        let m;
        let o;
        let r = -1;
        // Creation du selecteur
        const s = document.createElement('select');
        s.classList.add('ep_field');
        s.setAttribute('id', tag.id);
        // Recherche des liens a indexer
        l = tag.querySelectorAll('.ep_option');
        m = l.length;
        for (i = 0; i < m; i++) {
          if (
            l[i].querySelectorAll('.ep_name').length > 0 &&
            l[i].querySelectorAll('a').length > 0
          ) {
            // Creation de la balise
            o = document.createElement('option');
            o.append(
              document.createTextNode(
                l[i].querySelectorAll('.ep_name')[0].innerHTML
              )
            );
            // Analyse de l'etat
            o.setAttribute('value', l[i].querySelectorAll('a')[0].href);
            // Ajout de la selection
            if (l[i].classList.contains('ep_selected')) {
              o.setAttribute('selected', 'selected');
              r = i;
            }

            // Integration de la balise
            s.append(o);
          }
        }

        if (s.childNodes.length > 1) {
          // Ajout du placeholder et du label si necessaire
          l = null;
          i = tag.querySelectorAll('.ep_label');
          if (i.length > 0) {
            i = i[0].querySelectorAll('.ep_name');
            if (i.length > 0) {
              // Creation de l'option
              o = document.createElement('option');
              o.append(document.createTextNode(i[0].innerHTML));
              o.setAttribute('value', 'placeholder');
              o.setAttribute('disabled', 'disabled');
              if (r < 0) o.setAttribute('selected', 'selected');
              s.insertBefore(o, s.querySelectorAll('option')[0]);
              // Creation du libelle
              l = document.createElement('label');
              l.setAttribute('for', s.id);
              r = document.createElement('span');
              r.classList.add('ep_name');
              r.append(document.createTextNode(i[0].innerHTML));
              l.append(r);
              r = document.createElement('span');
              r.classList.add('ep_icon');
              l.append(r);
            }
          }

          // Ajout du container du selecteur
          m = document.createElement('div');
          m.classList.add('ep_select');
          // Integration du selecteur
          if (l != null) m.append(l);
          m.append(s);
          // Remplacement du selecteur par la nouvelle balise <select>
          tag.parentNode.insertBefore(m, tag);
          tag.remove();
          // Envoi de la convertion
          result = convert(s, parameters);
        }
      }
    }

    return result;
  }

  // Appel de la fonction "callback" apres la convertion du selecteur si demandee
  let b = false;
  if (
    parameters != null &&
    typeof parameters === 'object' &&
    typeof parameters.cb_afterconvert === 'function'
  )
    b = true;
  if (b) parameters.cb_afterconvert(result.converted, result.data);
  // Envoi du resultat de la demande
  return result;
}

function alwaysTrue() {
  return true;
}

function convert(tag, parameters) {
  // Definition des arguments
  //    > tag               // [NODE]     (mandatory)   : Reference de la balise <select> du selecteur a convertir
  //    > params            // [OBJECT]     (optional)    : Liste de parametres a mettre a jour
  // Variables utiles
  let i;
  let m;
  let o;
  let t;
  let b;
  let l;
  let g;
  let h;
  let data = null;
  // Verification de la balise transmise en parametre
  if (tag.tagName.toLowerCase() == 'select' && !tag.dataset.selectconverted) {
    // Blocage de la convertion
    tag.dataset.selectconverted = true;
    // Verification de la presence d'un ID valide
    if (
      typeof tag.id !== 'string' ||
      tag.id == undefined ||
      tag.id == null ||
      String(tag.id).length < 2
    ) {
      i = new Date();
      tag.setAttribute(
        'id',
        'selectID_' +
          (i.getHours() * 3_600_000 +
            i.getMinutes() * 60_000 +
            i.getSeconds() * 1000 +
            i.getMilliseconds())
      );
    }

    // Analyse de l'objet de parametre
    if (typeof parameters !== 'object') {
      parameters = {};
    } else if (parameters == null) {
      parameters = {};
    }

    // Definition de l'objet
    //    > params.autoexecute                                        // [BOOLEAN] (default value: false)       : Definit si le formulaire doit etre execute au changement de valeur du selecteur
    //    > params.forceplaceholder                                     // [BOOLEAN] (default value: false)       : Definit si le selecteur doit etre parametre sur le placeholder
    //    > params.editable                                         // [BOOLEAN] (default value: false)       : Definit si le champ d'ouverture du selecteur peut etre editable et ayant un systeme d'autocompletion via la liste
    //    > params.usetextcustom                                      // [BOOLEAN] (default value: false)       : Definit si la valeur affichee dans le selecteur converti est la valeur de la balise option ou le libelle
    //    > params.openbykeyboard                                       // [BOOLEAN] (default value: true)      : Definit si le selecteur doit se deployer lors de la navigation clavier
    //    > params.viewdisabled                                       // [BOOLEAN] (default value: false)       : Definit si le selecteur doit afficher ou non les options non accessible
    //    > params.cb_afterconvert                                    // [FUNCTION] (need a return value = true)  : Fonction appelee apres la convertion du selecteur
    //    > params.cb_beforeopen                                      // [FUNCTION] (need a return value = true)  : Fonction appelee avant l'ouverture  de la liste du selecteur
    //    > params.cb_afteropen                                       // [FUNCTION] (need a return value = true)  : Fonction appelee apres l'ouverture  de la liste du selecteur
    //    > params.cb_beforeclose                                       // [FUNCTION] (need a return value = true)  : Fonction appelee avant la fermeture de la liste du selecteur
    //    > params.cb_afterclose                                      // [FUNCTION] (need a return value = true)  : Fonction appelee apres la fermeture de la liste du selecteur
    //    > params.cb_beforesubmit                                    // [FUNCTION] (need a return value = true)  : Fonction appelee avant l'execution du formulaire (si mode "autoexecute")
    //    > params.cb_aftersubmit                                       // [FUNCTION] (need a return value = true)  : Fonction appelee apres l'execution du formulaire (si mode "autoexecute")
    //    > params.cb_selection                                       // [FUNCTION] (need a return value = true)  : Fonction appelee lors de la selection du selecteur
    //    > params.cb_updatefield                                       // [FUNCTION] (need a return value = true)  : Fonction appelee apres l'edition du champ texte (si mode "editable")
    // Initialisation de l'objet de donnees du selecteur
    data = {};
    data.id = tag.id; // [STRING]                   : Identifiant de la balise originale
    data.reference = 'selectconvertid_' + data.id; // [STRING]                  : Identifiant de la balise convertie
    data.index = null; // [INT]                   : Identifiant du selecteur dans le listing
    data.options = null; // [ARRAY]                   : Liste d'objets reprenant les donnees des options originales du selecteur
    data.placeholder = null; // [NODE]                    : Option contenant le placeholder pour le calcul de taille
    data.viewoptions = null; // [ARRAY]                   : Liste d'objets reprenant les donnees des options affichees dans la liste
    data.availableoptions = null; // [ARRAY]                    : Liste d'objets reprenant les donnees des options accessibles (non disabled) affichees dans la liste
    data.original = tag; // [NODE]                    : Selecteur original
    data.button = {
      tag: null,
      text: null,
      icon: null,
      size: null,
      icontext: null,
    }; // [OBJECT]                  : Bouton d'ouverture/fermeture (balise principale et balise de texte)
    data.container = null; // [NODE]                    : Conteneur du selecteur converti
    data.form = null; // [NODE]                   : Formulaire contenant le selecteur converti
    data.dropdown = null; // [NODE]                   : Conteneur des balises "option" converties
    data.dropdownlist = null; // [NODE]                   : Conteneur de la balise "liste" des "option" converties
    data.animatedopen = null; // [NODE]                   : Conteneur supportant les animations d'ouverture
    data.callback = {};
    data.callback.beforeopen = null; // [FUNCTION]                  : Fonction a appeler avant l'ouverture du selecteur
    data.callback.afteropen = null; // [FUNCTION]                 : Fonction a appeler apres l'ouverture du selecteur
    data.callback.beforeclose = null; // [FUNCTION]                 : Fonction a appeler avant la fermeture du selecteur
    data.callback.afterclose = null; // [FUNCTION]                  : Fonction a appeler apres la fermeture du selecteur
    data.callback.beforesubmit = null; // [FUNCTION]                  : Fonction a appeler avant l'execution du formulaire parent
    data.callback.aftersubmit = null; // [FUNCTION]                 : Fonction a appeler apres l'execution du formulaire parent
    data.callback.updatefield = null; // [FUNCTION]                 : Fonction a appeler apres la mise a jour du champ texte
    // Sauvegarde des donnees passees par parametre
    data.usetextcustom = Boolean(parameters.usetextcustom);
    data.forceplaceholder = Boolean(parameters.forceplaceholder);

    data.autoexecute =
      typeof parameters.autoexecute === 'boolean'
        ? Boolean(parameters.autoexecute)
        : null;
    data.editable = Boolean(parameters.editable);
    data.openbykeyboard =
      typeof parameters.openbykeyboard === 'boolean'
        ? Boolean(parameters.openbykeyboard)
        : true;
    data.callback.beforeopen =
      typeof parameters.cb_beforeopen === 'function'
        ? parameters.cb_beforeopen
        : alwaysTrue;
    data.callback.afteropen =
      typeof parameters.cb_afteropen === 'function'
        ? parameters.cb_afteropen
        : alwaysTrue;
    data.callback.beforeclose =
      typeof parameters.cb_beforeclose === 'function'
        ? parameters.cb_beforeclose
        : alwaysTrue;
    data.callback.afterclose =
      typeof parameters.cb_afterclose === 'function'
        ? parameters.cb_afterclose
        : alwaysTrue;
    data.callback.beforesubmit =
      typeof parameters.cb_beforesubmit === 'function' && data.autoexecute
        ? parameters.cb_beforesubmit
        : alwaysTrue;
    data.callback.aftersubmit =
      typeof parameters.cb_aftersubmit === 'function' && data.autoexecute
        ? parameters.cb_aftersubmit
        : alwaysTrue;
    data.callback.updatefield =
      typeof parameters.cb_updatefield === 'function' && data.editable
        ? parameters.cb_updatefield
        : null;
    data.callback.selection =
      typeof parameters.cb_selection === 'function'
        ? parameters.cb_selection
        : alwaysTrue;
    data.disabledview =
      parameters.viewdisabled === true
        ? true
        : tag.dataset.viewdisabled == 'true' ||
          tag.dataset.viewdisabled === true;
    // Recherche du formulaire responsable du selecteur
    i = tag.parentNode;
    while (check(i) && i.tagName.toLowerCase() != 'form') {
      i = i.parentNode;
    }

    if (check(i) && i.tagName.toLowerCase() == 'form') data.form = i;
    // Identification de l'autocompletion
    if (data.autoexecute == null && data.form != null)
      data.autoexecute = Boolean(data.form.dataset.autoexecute == 'true');
    // Creation du conteneur du module
    data.container = document.createElement('div');
    data.container.classList.add('epjs_select');
    data.container.dataset.reference = data.reference;
    // Creation de la liste des boutons
    data.dropdownlist = document.createElement('ol');
    data.dropdownlist.setAttribute('id', data.reference + '-listbox');
    data.dropdownlist.setAttribute('tabindex', '-1');
    data.dropdownlist.setAttribute(
      'aria-controls',
      data.reference + '-openingbutton'
    );
    data.dropdownlist.setAttribute('aria-expanded', false);
    data.dropdownlist.setAttribute('role', 'listbox');
    // Obtention des donnees de l'option
    data.dropdown = document.createElement('fieldset');
    data.dropdown.classList.add('epjs_dropdown');
    data.dropdown.setAttribute('tabindex', -1);
    if (data.disabledview) data.dropdown.dataset.viewdisabled = true;
    data.animatedopen = document.createElement('div');
    data.animatedopen.classList.add('epjs_list');
    data.animatedopen.setAttribute('tabindex', '-1');
    data.dropdown.append(data.animatedopen);
    o = tag.querySelectorAll('option');
    m = o.length;
    g = { original: null };
    data.options = [];
    data.viewoptions = [];
    data.availableoptions = [];
    if (document.body.dataset.ismobile) {
      for (i = 0; i < m; i++) {
        // Sauvegarde des references
        data.options.push({
          original: o[i],
          convert: { button: null, text: null },
          text: clean(o[i].innerHTML),
          textcustom: clean(o[i].dataset.textcustom),
          value: o[i].value,
          index: i,
        });
        data.viewoptions.push(data.options.length - 1);
        if (!o[i].getAttribute('disabled'))
          data.availableoptions.push(data.options.length - 1);
      }

      // Verificiation du forcage du placeholder
      if (data.forceplaceholder && data.options[0].value == 'placeholder') {
        data.original.selectedIndex = 0;
      }
    } else {
      for (i = 0; i < m; i++) {
        // Creation du bouton
        b = document.createElement('span');
        b.classList.add('epjs_option');
        if (o[i].getAttribute('disabled')) b.dataset.disabled = true;
        if (o[i].getAttribute('class'))
          b.classList.add(o[i].getAttribute('class'));
        b.dataset.type = 'option';
        b.dataset.index = i;
        t = document.createElement('span');
        t.classList.add('epjs_name');
        if (o[i].dataset.additionaltext) {
          l = document.createElement('span');
          l.classList.add('epjs_additionaltext');
          l.append(document.createTextNode(o[i].dataset.additionaltext));
          t.append(l);
        }

        l = document.createElement('span');
        l.classList.add('epjs_text');
        l.append(document.createTextNode(clean(o[i].innerHTML)));
        t.append(l);
        b.append(t);
        t = document.createElement('span');
        t.classList.add('epjs_icon');
        t.append(document.createTextNode(' '));
        b.append(t);
        // Sauvegarde des references
        data.options.push({
          original: o[i],
          convert: { button: b, text: t },
          text: clean(o[i].innerHTML),
          textcustom: clean(o[i].dataset.textcustom),
          value: o[i].value,
          index: i,
        });
        // Creation de la balise conteneur
        t = document.createElement('li');
        t.classList.add('epjs_item');
        t.setAttribute('role', 'option');
        t.setAttribute('item', data.viewoptions.length);
        t.setAttribute('tabindex', '-1');
        if (o[i].getAttribute('title'))
          t.setAttribute('title', o[i].getAttribute('title'));
        t.append(b);
        if (o[i].value.toLowerCase() == 'placeholder') {
          // Ajout de la classe pour la non prise en compte de la hauteur
          t.classList.add('epjs_placeholder');
          // Sauvegarde de la reference
          data.placeholder = t;
        } else {
          // Encodage de la balise dans la liste
          data.dropdownlist.append(t);
          // Indexation des options valides
          data.viewoptions.push(data.options.length - 1);
          if (!o[i].getAttribute('disabled'))
            data.availableoptions.push(data.options.length - 1);
        }

        // Traitement visuel des "optgroup"
        if (o[i].parentNode.tagName.toLowerCase() == 'optgroup') {
          // Identification du lien de second niveau
          t.classList.add('epjs_subitem');
          // Creation de la liste de second niveau si necessaire
          if (o[i].parentNode != g.original) {
            // Creation de la liste
            g = {
              original: o[i].parentNode,
              tag: document.createElement('ul'),
              title: null,
            };
            g.tag.classList.add('epjs_group');
            b = document.createElement('li');
            b.classList.add('epjs_item');
            b.append(g.tag);
            data.dropdownlist.insertBefore(b, t);
            // Ajout du titre
            if (o[i].parentNode.dataset.viewlabel != 'false') {
              g.title = document.createElement('div');
              g.title.classList.add('epjs_grouptitle');
              b.insertBefore(g.title, g.tag);
              h = document.createElement('span');
              h.classList.add('epjs_name');
              h.append(
                document.createTextNode(o[i].parentNode.getAttribute('label'))
              );
              g.title.append(h);
              h = document.createElement('span');
              h.classList.add('epjs_icon');
              h.append(document.createTextNode(' '));
              g.title.append(h);
            }
          }

          // Deplacement du lien dans la liste
          g.tag.append(t);
        }
      }

      // Verificiation du forcage du placeholder
      if (data.forceplaceholder && data.options[0].value == 'placeholder') {
        data.original.selectedIndex = -1;
      }

      // Detection de la selection
      if (data.original.selectedIndex >= 0) {
        data.options[
          data.original.selectedIndex
        ].convert.button.dataset.selected = true;
        data.options[data.original.selectedIndex].convert.button.setAttribute(
          'aria-selected',
          true
        );
      } else if (data.options[0].value == 'placeholder') {
        data.original.selectedIndex = 0;
        data.options[
          data.original.selectedIndex
        ].convert.button.dataset.selected = true;
        data.options[data.original.selectedIndex].convert.button.setAttribute(
          'aria-selected',
          true
        );
      }

      data.animatedopen.append(data.dropdownlist);
    }

    // Obtention des traductions du bouton "open/close"
    i = document.body.getAttribute('lang');
    if (typeof i !== 'object' || String(i).length != 2)
      i = document.querySelectorAll('html')[0].getAttribute('lang');
    i = String(i).toLowerCase();
    l = { open: '', close: '' };
    switch (i) {
      case 'bg':
        l.open = 'Отваряне на менюто';
        l.close = 'Затваряне на менюто';
        break;
      case 'cs':
        l.open = 'Otevřít menu';
        l.close = 'Zavřít menu';
        break;
      case 'da':
        l.open = 'Åbn menu';
        l.close = 'Luk menu';
        break;
      case 'de':
        l.open = 'Menü ausklappen';
        l.close = 'Menü einklappen';
        break;
      case 'el':
        l.open = 'Άνοιγμα του μενού';
        l.close = 'Κλείσιμο του μενού';
        break;
      case 'es':
        l.open = 'Abrir menú';
        l.close = 'Cerrar menú';
        break;
      case 'et':
        l.open = 'Ava menüü';
        l.close = 'Sulge menüü';
        break;
      case 'fi':
        l.open = 'Avaa valikko';
        l.close = 'Sulje valikko';
        break;
      case 'fr':
        l.open = 'Oscail an roghchlár';
        l.close = 'Dún an roghchlár';
        break;
      case 'ga':
        l.open = 'Open menu';
        l.close = 'Close menu';
        break;
      case 'hr':
        l.open = 'Otvori izbornik';
        l.close = 'Zatvori izbornik';
        break;
      case 'hu':
        l.open = 'Menü megnyitása';
        l.close = 'Menü bezárása';
        break;
      case 'it':
        l.open = 'Apri menù';
        l.close = 'Chiudi menù';
        break;
      case 'lt':
        l.open = 'Atverti meniu';
        l.close = 'Uždaryti meniu';
        break;
      case 'lv':
        l.open = 'Atvērt izvēlni';
        l.close = 'Aizvērt izvēlni';
        break;
      case 'mt':
        l.open = 'Iftaħ il-menu';
        l.close = 'Agħlaq il-menu';
        break;
      case 'nl':
        l.open = 'Menu openen';
        l.close = 'Menu sluiten';
        break;
      case 'pl':
        l.open = 'Wyświetl menu';
        l.close = 'Zamknij menu';
        break;
      case 'pt':
        l.open = 'Abrir lista';
        l.close = 'Fechar lista';
        break;
      case 'ro':
        l.open = 'Desfășurare meniu';
        l.close = 'Închidere meniu';
        break;
      case 'sk':
        l.open = 'Otvoriť menu';
        l.close = 'Zatvoriť menu';
        break;
      case 'sl':
        l.open = 'Odpri meni';
        l.close = 'Zapri meni';
        break;
      case 'sv':
        l.open = 'Öppna menyn';
        l.close = 'Stäng menyn';
        break;
      default:
        l.open = 'Open menu';
        l.close = 'Close menu';
        break;
    }

    // Creation du libelle
    data.button.tag = document.createElement('div');
    data.button.tag.classList.add('epjs_button');
    if (document.body.dataset.ismobile) {
      data.button.tag.setAttribute('tabindex', '-1');
      data.button.size = document.createElement('span');
      data.button.size.classList.add('epjs_size');
      data.button.size.setAttribute('tabindex', '-1');
      data.button.text = document.createElement('input');
      data.button.text.classList.add('epjs_name');
      data.button.text.setAttribute('tabindex', '-1');
      data.button.text.id = data.reference + '-valuetext';
      data.button.icon = document.createElement('span');
      data.button.icon.classList.add('epjs_icon');
      data.button.icon.append(document.createTextNode(' '));
    } else {
      data.button.size = document.createElement('span');
      data.button.size.classList.add('epjs_size');
      data.button.size.setAttribute('tabindex', '-1');
      data.button.text = document.createElement('input');
      data.button.text.id = data.reference + '-valuetext';
      data.button.text.classList.add('epjs_name');
      data.button.text.setAttribute('type', 'text');

      if (!data.editable) {
        data.button.tag.classList.add('epjs_static');
        data.button.text.setAttribute('readonly', 'readonly');
        data.button.text.setAttribute('aria-readonly', 'true');
      } else {
        data.button.text.setAttribute('role', 'textbox');
        data.button.text.setAttribute('autocomplete', 'off');
        data.button.text.setAttribute('aria-autocomplete', 'list');
      }

      if (!data.editable) {
        data.button.icon = document.createElement('span');
        data.button.icon.classList.add('epjs_icon');
        data.button.icon.append(document.createTextNode(' '));
        data.button.icontext = document.createElement('span');
      } else {
        data.button.icon = document.createElement('button');
        data.button.icon.classList.add('epjs_icon');
        data.button.icon.setAttribute('type', 'button');
        data.button.icon.dataset.openlabel = l.open;
        data.button.icon.dataset.closelabel = l.close;
        data.button.icon.id = data.reference + '-openingbutton';
        data.button.icontext = document.createElement('span');
        data.button.icontext.append(document.createTextNode(l.open));
        data.button.icon.append(data.button.icontext);
      }
    }

    data.button.tag.append(data.button.size);
    data.button.tag.append(data.button.text);
    data.button.tag.append(data.button.icon);
    if (data.original.selectedIndex < 0) {
      data.button.text.append(document.createTextNode(' '));
    } else if (parameters.usetextcustom) {
      data.button.text.value =
        data.options[data.original.selectedIndex].textcustom;
      data.button.tag.setAttribute(
        'title',
        data.options[data.original.selectedIndex].text
      );
    } else {
      data.button.text.value = data.options[data.original.selectedIndex].text;
    }

    if (
      data.original.selectedIndex >= 0 &&
      data.options[data.original.selectedIndex].value == 'placeholder'
    )
      data.button.tag.dataset.placeholder = true;
    if (
      !document.body.dataset.ismobile &&
      data.editable &&
      data.options[0].value == 'placeholder'
    ) {
      data.button.text.setAttribute(
        'placeholder',
        parameters.usetextcustom
          ? data.options[0].textcustom
          : data.options[0].text
      );
      if (data.options[data.original.selectedIndex].value == 'placeholder')
        data.button.text.value = '';
    }

    t = document.createElement('label');
    t.setAttribute('for', data.button.text.id);
    t.classList.add('ep_hidden');
    t.innerHTML = data.button.text.value;
    data.button.tag.append(t);
    // Recherche du label associe au selecteur

    l = null;
    if (data.form != null) {
      t = data.form.querySelectorAll('label');
      i = t.length - 1;
      for (i = i; i >= 0; i--) {
        if (t[i].htmlFor == data.id) {
          if (typeof t[i].id !== 'string' || t[i].id.length <= 0) {
            t[i].id = data.reference + '-labelselect';
          }

          data.button.text.setAttribute('aria-labelledby', t[i].id);
          break;
        }
      }
    }

    // Integration des elements principaux
    data.container.append(data.button.tag);
    data.container.append(data.dropdown);
    // Integration du module dans la page
    // Ajout des classes aux conteneurs
    if (data.form != null) {
      data.form.classList.add('epjs_selectconvert');
    } else {
      data.original.parentNode.parentNode.classList.add('epjs_selectconvert');
    }

    data.original.parentNode.classList.add('epjs_converted');
    // Integration de la balise
    data.original.parentNode.insertBefore(data.container, data.original);
    data.container.append(data.original);
    // Ajout des classes necessaires a la detection de clic
    l = data.container.querySelectorAll('*');
    m = l.length;
    for (i = 0; i < m; i++) {
      l[i].classList.add(data.reference);
    }

    // Sauvegarde des donnees dans la classe
    Data.convertlist.push(data);
    Data.reference[data.id] = Data.convertlist.length - 1;
    data.index = Data.convertlist.length - 1;
    i = data.index;
    if (document.body.dataset.ismobile) {
      data.button.tag.tabIndex = -1;
      data.original.addEventListener('focus', function (e) {
        state(e, i);
      });
      data.original.addEventListener('blur', function (e) {
        state(e, i);
      });
      data.original.addEventListener('change', function (_) {
        return select(this, i);
      });
      // Calcul de la largeur du champ visible
      data.button.size.innerHTML = data.button.text.value;
      data.button.text.style.width = data.button.size.offsetWidth + 5 + 'px';
      data.button.size.removeAttribute('style');
      data.button.size.innerHTML = '';
    } else {
      // Calcul de la largeur du selecteur
      if (data.callback.updatefield != null) updatelist(null, i);
      // calcul(data, null, false);
      // Ajout des evenements
      // Selection du menu au clavier
      if (!data.editable) {
        data.button.text.addEventListener('click', function (_) {
          data.button.text.select();
        });
      }

      data.button.text.addEventListener('focus', function (e) {
        state(e, i);
      });
      data.button.text.addEventListener('blur', function (e) {
        state(e, i);
      });
      data.button.icon.addEventListener('focus', function (e) {
        state(e, i, true);
      });
      data.button.icon.addEventListener('blur', function (e) {
        state(e, i, true);
      });
      // Blocage du selecteur pour le clavier
      data.original.setAttribute('tabindex', '-1');
      // Ajout des actions d'ouverture et de navigation
      data.button.tag.addEventListener('click', function (e) {
        return view(e, i);
      });
      data.button.tag.addEventListener('keydown', function (e) {
        return keyboard(e, i);
      });
      if (data.callback.updatefield != null) {
        data.button.text.addEventListener('paste', function (e) {
          setTimeout(function () {
            updatelist(e, i);
          }, 5);
        });
        data.button.tag.addEventListener('keyup', function (e) {
          return updatelist(e, i);
        });
      }

      // Ajout de l'action de fermeture au clic dans et hors du menu ou au redimensionnement de la fenetre
      document.addEventListener(
        'click',
        function (e) {
          return clickout(e, i);
        },
        false
      );
      document.addEventListener(
        'resize',
        function (e) {
          return clickout(e, i);
        },
        false
      );
      // Ajout de l'action de traitement de la balise "select"
      data.original.addEventListener('change', function (_) {
        return select(this, i);
      });
      // Ajout de l'action de selection des boutons
      m = data.options.length;
      for (b = 0; b < m; b++) {
        data.options[b].convert.button.addEventListener(
          'mouseup',
          function (_) {
            return select(this, i);
          }
        );
        data.options[b].convert.button.addEventListener(
          'keydown',
          function (e) {
            return keyboard(e, i);
          }
        );
      }

      // Ajout de l'action de fermeture au redimensionnement
      window.addEventListener(
        'resize',
        function (_) {
          return resize(i);
        },
        false
      );
    }
  }

  // Envoi du resultat de la demande
  return { converted: data != null, data };
}

// Fonction publique a appeler afin de mettre a jour les parametres definis lors de la convertion
function updatedata(reference, parameters) {
  return updateparams(reference, parameters, false);
}

function updateparams(reference, parameters, reseting) {
  // Definition des arguments
  //    > reference           // [NODE/STRING/NUMBER/OBJECT]  : Reference du selecteur converti a manipuler
  //                                    > NODE:   identification via la balise du selecteur converti
  //                                    > OBJECT:   identification via l'objet de donnees issu de la convertion du selecteur
  //                                    > NUMBER:   identification via l'index de convertion renvoye par la fonction "convert"
  //                                    > STRING:   identification via l'id du selecteur original converti
  //    > params            // [OBJECT]             : Liste de parametres a mettre a jour
  //                                    > la definition de l'objet doit etre identique a l'objet de donnees passe en parametre lors de la convertion
  //    > reseting            // [BOOLEAN]  (system)    : Defini si l'appel de la fonction est interne ou public
  // Variable a renvoyer apres traitement de la demande
  let executed = false;
  let finalmessage = 'unknown error';
  let d = null;
  // Verification du type d'objet transmis
  if (typeof parameters !== 'object') {
    parameters = null;
  } else if (parameters == null) {
    parameters = null;
  }

  if (reference != null && parameters != null) {
    // Identification de l'objet de donnees
    if (reseting === true) d = reference;
    else if (typeof reference === 'object') {
      // >> reference = objet de donnees renvoyes par le widget a sa creation
      if (!isNaN(reference.index)) {
        d = Data.convertlist[reference.index];
      } else if (
        typeof reference.tagName === 'string' &&
        String(reference.tagName).toLowerCase() == 'select' &&
        !isNaN(Data.reference[reference.id]) &&
        reference.dataset.selectconverted
      ) {
        // >> reference = balise HTML du selecteur original
        d = Data.convertlist[Data.reference[reference]];
      }
    } else if (!isNaN(reference)) {
      // >> reference = index de convertion du composant
      d = Data.convertlist[reference];
    } else if (
      typeof reference === 'string' && // >> reference = reference (ID) du composant
      !isNaN(Data.reference[reference])
    ) {
      d = Data.convertlist[Data.reference[reference]];
    }

    // Validation de l'objet obtenu
    if (d != null) {
      if (!isNaN(d.index)) {
        let i;
        executed = true;
        // Mise a jour des parametres transmis
        // eslint-disable-next-line guard-for-in
        for (i in parameters) {
          switch (i) {
            case 'usetextcustom':
              d.usetextcustom = Boolean(parameters.usetextcustom);
              break;
            case 'autoexecute':
              d.autoexecute = Boolean(parameters.autoexecute);
              break;
            case 'openbykeyboard':
              d.openbykeyboard =
                typeof parameters.openbykeyboard === 'boolean'
                  ? Boolean(parameters.openbykeyboard)
                  : true;
              break;
            case 'cb_beforeopen':
              d.callback.beforeopen =
                typeof parameters.cb_beforeopen === 'function'
                  ? parameters.cb_beforeopen
                  : alwaysTrue;

              break;
            case 'cb_afteropen':
              d.callback.afteropen =
                typeof parameters.cb_afteropen === 'function'
                  ? parameters.cb_afteropen
                  : alwaysTrue;

              break;
            case 'cb_beforeclose':
              d.callback.beforeclose =
                typeof parameters.cb_beforeclose === 'function'
                  ? parameters.cb_beforeclose
                  : alwaysTrue;

              break;
            case 'cb_afterclose':
              d.callback.afterclose =
                typeof parameters.cb_afterclose === 'function'
                  ? parameters.cb_afterclose
                  : alwaysTrue;

              break;
            case 'cb_beforesubmit':
              d.callback.beforesubmit =
                typeof parameters.cb_beforesubmit === 'function' &&
                (d.autoexecute || parameters.autoexecute)
                  ? parameters.cb_beforesubmit
                  : alwaysTrue;

              break;
            case 'cb_aftersubmit':
              d.callback.aftersubmit =
                typeof parameters.cb_aftersubmit === 'function' &&
                (d.autoexecute || parameters.autoexecute)
                  ? parameters.cb_aftersubmit
                  : alwaysTrue;

              break;
            case 'cb_updatefield':
              d.callback.updatefield =
                typeof parameters.cb_updatefield === 'function' && d.editable
                  ? parameters.cb_updatefield
                  : null;
              break;
            case 'cb_selection':
              d.callback.selection =
                typeof parameters.cb_selection === 'function'
                  ? parameters.cb_selection
                  : alwaysTrue;

              break;
            case 'viewdisabled':
              d.disabledview =
                parameters.viewdisabled === true
                  ? true
                  : tag.dataset.viewdisabled == 'true' ||
                    tag.dataset.viewdisabled === true;
              break;
            default:
              break;
          }
        }

        // Mise a jour de la valeur d'affichage
        if (d.disabledview) d.dropdown.dataset.viewdisabled = true;
        else delete d.dropdown.dataset.viewdisabled;
      } else {
        d = null;
        finalmessage = 'not recognized select tag';
      }
    } else finalmessage = 'not recognized select tag';
  } else if (parameters == null) finalmessage = 'invalid parameter object';
  // Envoi du resultat de la demande
  return {
    executed,
    data: d,
    message: executed ? '' : finalmessage,
  };
}

// Fonction publique a appeler afin de selectionner une valeur specifique dans le selecteur
function updateselection(reference, selectedvalue, autoexecute) {
  // Definition des arguments
  //    > reference           // [NODE/STRING/NUMBER/OBJECT]  : Reference du selecteur converti a manipuler
  //                                    > NODE:   identification via la balise du selecteur converti
  //                                    > OBJECT:   identification via l'objet de donnees issu de la convertion du selecteur
  //                                    > NUMBER:   identification via l'index de convertion renvoye par la fonction "convert"
  //                                    > STRING:   identification via l'id du selecteur original converti
  //    > selectedvalue           // [STRING]             : valeur de la balise "option" du selecteur original a selectioner
  //    > autoexecute           // [BOOLEAN] (optional)       : demande d'execution du formulaire et des callback du selecteur
  //                                    > true:       execution forcee du formulaire
  //                                    > false:        blocage de l'execution du formulaire et des callback intermediaires des selecteurs
  //                                    > null/undefined:   execution du formulaire selon les parametres definis dans le selecteur
  // Variable a renvoyer apres traitement de la demande
  let executed = false;
  let finalmessage = 'unknown error';
  let d = null;
  // Verification de la selection
  if (typeof selectedvalue === 'number') selectedvalue = String(selectedvalue);
  if (reference != null && typeof selectedvalue === 'string') {
    // Identification de l'objet de donnees
    if (typeof reference === 'object') {
      if (!isNaN(reference.index)) {
        // >> reference = objet de donnees renvoyes par le widget a sa creation
        d = Data.convertlist[reference.index];
      } else if (
        typeof reference.tagName === 'string' &&
        String(reference.tagName).toLowerCase() == 'select' &&
        reference.dataset.selectconverted &&
        !isNaN(Data.reference[reference.id])
      ) {
        // >> reference = balise HTML du selecteur original
        d = Data.convertlist[Data.reference[reference.id]];
      }
    } else if (!isNaN(reference)) {
      // >> reference = index de convertion du composant
      d = Data.convertlist[reference];
    } else if (
      typeof reference === 'string' && // >> reference = reference (ID) du composant
      !isNaN(Data.reference[reference])
    ) {
      d = Data.convertlist[Data.reference[reference]];
    }

    // Validation de l'objet obtenu
    if (d != null) {
      if (!isNaN(d.index)) {
        let t = null;
        // Recherche de la valeur demandee dans la liste des options
        let i = d.options.length - 1;
        for (i = i; i >= 0; i--) {
          if (selectedvalue == d.options[i].value) {
            t = i;
            break;
          }
        }

        if (t != null) {
          executed = true;
          // Suppression de la selection si le selecteur est reinitialise sur le placeholder
          if (d.options[t].value == 'placeholder') {
            // Nettoyage des selections
            d.original.selectedIndex = -1;
            if (!document.body.dataset.ismobile) {
              i = d.options.length - 1;
              for (i = i; i >= 0; i--) {
                delete d.options[i].convert.button.dataset.selected;
                d.options[i].convert.button.removeAttribute('aria-selected');
              }
            }

            // Affichage du texte
            d.button.tag.dataset.placeholder = true;
            if (d.usetextcustom) {
              d.button.text.value = d.options[t].textcustom;
              d.button.tag.setAttribute('title', d.options[t].text);
            } else {
              d.button.text.value = d.options[t].text;
            }

            // Mise a jour de la largeur du champ visible
            d.button.size.innerHTML = d.button.text.value;
            d.button.text.style.width = d.button.size.offsetWidth + 5 + 'px';
            d.button.size.removeAttribute('style');
            d.button.size.innerHTML = '';
            // Selection de l'option trouvee
          } else {
            // Selection du l'option
            d.original.selectedIndex = i;
            select(d.original, d.index, autoexecute);
          }
        } else finalmessage = "not recognized 'selection' value";
      } else {
        d = null;
        finalmessage = 'not recognized select tag';
      }
    } else finalmessage = 'not recognized select tag';
  } else if (typeof selectedvalue !== 'string')
    finalmessage = "invalid 'selection' value";
  // Envoi du resultat de la demande
  return {
    executed,
    data: d,
    message: executed ? '' : finalmessage,
  };
}

// Fonction publique a appeler afin de selectionner une valeur specifique dans le selecteur
function reset(reference, parameters) {
  // Definition des arguments
  //    > reference           // [NODE/STRING/NUMBER/OBJECT]  : Reference du selecteur converti a manipuler
  //                                    > NODE:   identification via la balise du selecteur converti
  //                                    > OBJECT:   identification via l'objet de donnees issu de la convertion du selecteur
  //                                    > NUMBER:   identification via l'index de convertion renvoye par la fonction "convert"
  //                                    > STRING:   identification via l'id du selecteur original converti
  //    > params            // [OBJECT]     (optional)    : Liste de parametres a mettre a jour
  //                                    > la definition de l'objet doit etre identique a l'objet de donnees passe en parametre lors de la convertion
  // Variable a renvoyer apres traitement de la demande
  let executed = false;
  const finalmessage = 'not recognized select tag';
  let d = null;
  // Verification de la selection
  if (reference != null) {
    // Identification de l'objet de donnees
    if (typeof reference === 'object') {
      if (!isNaN(reference.index)) {
        // >> reference = objet de donnees renvoyes par le widget a sa creation
        d = Data.convertlist[reference.index];
      } else if (
        typeof reference.tagName === 'string' &&
        String(reference.tagName).toLowerCase() == 'select'
      ) {
        if (
          reference.dataset.selectconverted &&
          !isNaN(Data.reference[reference.id])
        ) {
          // >> reference = balise HTML du selecteur original
          d = Data.convertlist[Data.reference[reference]];
        } else if (!reference.dataset.selectconverted) {
          // >> reference = balise HTML du selecteur original non converties
          d = false;
        }
      }
    } else if (!isNaN(reference)) {
      // >> reference = index de convertion du composant
      d = Data.convertlist[reference];
    } else if (
      typeof reference === 'string' && // >> reference = reference (ID) du composant
      !isNaN(Data.reference[reference])
    ) {
      d = Data.convertlist[Data.reference[reference]];
    }

    if (d === false) {
      // Selecteur non converti -> Lancement de la convertion
      return convert(reference, parameters);
    }

    if (d != null) {
      if (!isNaN(d.index)) {
        executed = true;
        // Mise a jour des parametres de personnalisation du composant si demande
        if (typeof parameters === 'object') updateparams(d, parameters, true);
        // Modification des balises du selecteur
        let g;
        let h;
        let i;
        let b;
        let t;
        let l;
        // Obtention de la liste des nouvelles options
        const o = d.original.querySelectorAll('option');
        const m = o.length;
        // Reinitialisation des references
        g = { original: null };
        d.options = [];
        d.viewoptions = [];
        d.availableoptions = [];
        d.placeholder = null;
        // Prise en charge des nouvelles options
        if (document.body.dataset.ismobile) {
          for (i = 0; i < m; i++) {
            // Sauvegarde des references
            d.options.push({
              original: o[i],
              convert: { button: null, text: null },
              text: clean(o[i].innerHTML),
              textcustom: clean(o[i].dataset.textcustom),
              value: o[i].value,
              index: i,
            });
            d.viewoptions.push(d.options.length - 1);
            if (!o[i].getAttribute('disabled'))
              d.availableoptions.push(d.options.length - 1);
          }

          // Verificiation du forcage du placeholder
          if (d.forceplaceholder && d.options[0].value == 'placeholder') {
            d.original.selectedIndex = 0;
          }
        } else {
          // Suppression des balises
          i = d.dropdownlist.children.length - 1;
          for (i = i; i >= 0; i--) {
            d.dropdownlist.children[i].remove();
          }

          // Integration des balises
          for (i = 0; i < m; i++) {
            // Creation du bouton
            b = document.createElement('span');
            b.classList.add('epjs_option');
            if (o[i].getAttribute('disabled')) b.dataset.disabled = true;
            if (o[i].getAttribute('class'))
              b.classList.add(o[i].getAttribute('class'));
            b.dataset.type = 'option';
            b.dataset.index = i;
            t = document.createElement('span');
            t.classList.add('epjs_name');
            if (o[i].dataset.additionaltext) {
              l = document.createElement('span');
              l.classList.add('epjs_additionaltext');
              l.append(document.createTextNode(o[i].dataset.additionaltext));
              t.append(l);
            }

            l = document.createElement('span');
            l.classList.add('epjs_text');
            l.append(document.createTextNode(clean(o[i].innerHTML)));
            t.append(l);
            b.append(t);
            t = document.createElement('span');
            t.classList.add('epjs_icon');
            t.append(document.createTextNode(' '));
            b.append(t);
            // Sauvegarde des references
            d.options.push({
              original: o[i],
              convert: { button: b, text: t },
              text: clean(o[i].innerHTML),
              textcustom: clean(o[i].dataset.textcustom),
              value: o[i].value,
              index: i,
            });
            // Creation de la balise conteneur
            t = document.createElement('li');
            t.classList.add('epjs_item');
            t.setAttribute('role', 'option');
            t.setAttribute('item', d.viewoptions.length);
            t.setAttribute('tabindex', '-1');
            if (o[i].getAttribute('title'))
              t.setAttribute('title', o[i].getAttribute('title'));
            t.append(b);
            if (o[i].value.toLowerCase() != 'placeholder') {
              // Encodage de la balise dans la liste
              d.dropdownlist.append(t);
              // Indexation des options valides
              d.viewoptions.push(d.options.length - 1);
              if (!o[i].getAttribute('disabled'))
                d.availableoptions.push(d.options.length - 1);
            } else {
              // Ajout de la classe pour la non prise en compte de la hauteur
              t.classList.add('epjs_placeholder');
              // Sauvegarde de la reference
              d.placeholder = t;
            }

            // Traitement visuel des "optgroup"
            if (o[i].parentNode.tagName.toLowerCase() == 'optgroup') {
              // Identification du lien de second niveau
              t.classList.add('epjs_subitem');
              // Creation de la liste de second niveau si necessaire
              if (o[i].parentNode != g.original) {
                // Creation de la liste
                g = {
                  original: o[i].parentNode,
                  tag: document.createElement('ul'),
                  title: null,
                };
                g.tag.classList.add('epjs_group');
                b = document.createElement('li');
                b.classList.add('epjs_item');
                b.append(g.tag);
                d.dropdownlist.insertBefore(b, t);
                // Ajout du titre
                if (o[i].parentNode.dataset.viewlabel != 'false') {
                  g.title = document.createElement('div');
                  g.title.classList.add('epjs_grouptitle');
                  b.insertBefore(g.title, g.tag);
                  h = document.createElement('span');
                  h.classList.add('epjs_name');
                  h.append(
                    document.createTextNode(
                      o[i].parentNode.getAttribute('label')
                    )
                  );
                  g.title.append(h);
                  h = document.createElement('span');
                  h.classList.add('epjs_icon');
                  h.append(document.createTextNode(' '));
                  g.title.append(h);
                }
              }

              // Deplacement du lien dans la liste
              g.tag.append(t);
            }
          }

          // Verificiation du forcage du placeholder
          if (d.forceplaceholder && d.options[0].value == 'placeholder') {
            d.original.selectedIndex = -1;
          }

          // Detection de la selection
          if (d.original.selectedIndex >= 0) {
            d.options[
              d.original.selectedIndex
            ].convert.button.dataset.selected = true;
            d.options[d.original.selectedIndex].convert.button.setAttribute(
              'aria-selected',
              true
            );
          } else if (d.options[0].value == 'placeholder') {
            d.original.selectedIndex = 0;
            d.options[
              d.original.selectedIndex
            ].convert.button.dataset.selected = true;
            d.options[d.original.selectedIndex].convert.button.setAttribute(
              'aria-selected',
              true
            );
          }

          // Mise a jour de la largeur du selecteur
          g = d.index;
          if (d.callback.updatefield != null) updatelist(null, g);
          calcul(d, null, false);
          // Ajout de la classe CSS d'identification
          l = d.dropdownlist.querySelectorAll('*');
          i = l.length - 1;
          for (i = i; i >= 0; i--) {
            l[i].classList.add(d.reference);
          }

          // Ajout de l'action de selection des boutons
          for (i = 0; i < m; i++) {
            // Ajout de l'evenement
            d.options[i].convert.button.addEventListener(
              'mouseup',
              function (_) {
                return select(this, g);
              }
            );
            d.options[i].convert.button.addEventListener(
              'keydown',
              function (e) {
                return keyboard(e, g);
              }
            );
          }
        }

        // Verificiation du forcage du placeholder
        if (d.forceplaceholder && d.options[0].value == 'placeholder') {
          d.original.selectedIndex = -1;
        }

        // Detection de la selection
        if (d.original.selectedIndex >= 0) {
          d.options[
            d.original.selectedIndex
          ].convert.button.dataset.selected = true;
          d.options[d.original.selectedIndex].convert.button.setAttribute(
            'aria-selected',
            true
          );
        } else if (d.options[0].value == 'placeholder') {
          d.original.selectedIndex = 0;
          d.options[
            d.original.selectedIndex
          ].convert.button.dataset.selected = true;
          d.options[d.original.selectedIndex].convert.button.setAttribute(
            'aria-selected',
            true
          );
        }
      } else d = null;
      // Envoi du resultat de la demande
      return {
        executed,
        data: d,
        message: executed ? '' : finalmessage,
      };
    }
  } else
    return {
      executed,
      data: d,
      message: executed ? '' : finalmessage,
    };
}

// Fonction calculant les dimensions du selecteur (largeur et hauteur)
function calcul(data, callback, adjustheight) {
  let t;
  let b;
  let l;
  let s;
  // Verification de la presence d'un placeholder (ajout pour le calcul)
  if (data.placeholder != null) {
    data.dropdownlist.append(data.placeholder);
    s = data.placeholder.querySelectorAll('.epjs_option')[0];
    delete s.dataset.disabled;
  }

  // Sauvegarde de la hauteur initiale de la page
  // Ouverture du menu hors de la zone visible
  data.button.tag.style.width = '';
  data.animatedopen.style.width = '';
  data.dropdown.style.position = 'absolute';
  data.dropdown.style.transform = 'translate(-9000px, 0)';
  data.animatedopen.style.width = '2000px';
  data.animatedopen.style.minwidth = '10px';
  data.container.dataset.open = 'true';
  // Recuperation de la longueur du bouton le plus long
  l = 0;
  const m = data.options.length;
  for (b = 0; b < m; b++) {
    t = data.options[b].convert.button;
    t.style.width = 'auto';
    l = Math.max(l, t.offsetWidth);
    t.style.width = '';
  }

  // Recuperation de la longueur du champ texte
  data.button.size.innerHTML = data.button.text.value;
  setTimeout(function () {
    data.button.text.style.width = data.button.size.offsetWidth + 5 + 'px';
    l = Math.max(l, data.button.size.offsetWidth + 5);
    data.button.size.removeAttribute('style');
    data.button.size.innerHTML = '';
    // Assignation de la valeur obtenue
    data.button.tag.style.minWidth = l + 'px';
    data.dropdown.style.minWidth = l + 'px';
    data.animatedopen.style.width = l + 'px';
    // Prise en compte des marges laterales
    t = data.button.tag.offsetWidth - data.button.text.offsetWidth;
    l += t;
    // Assignation de la valeur obtenue
    data.button.tag.style.width = l + 'px';
    data.dropdown.style.width = l + 'px';
    data.animatedopen.style.minwidth = '';
    data.animatedopen.style.width = 'auto';
    // Verification de la hauteur de la page par rapport a la longueur de la liste
    if (adjustheight !== false) height(data, true);
    // Fermeture du menu
    delete data.container.dataset.open;
    data.dropdown.style.position = '';
    data.dropdown.style.transform = '';
  }, 1);
  // Verification de la presence d'un placeholder (suppression apres le calcul)
  if (data.placeholder != null) {
    data.placeholder.remove();
    s.dataset.disabled = true;
  }

  // Execution de la fonction de rappel passee en parametre
  if (typeof callback === 'function')
    setTimeout(function (e) {
      callback(e, data.index);
    }, 5);
}

function height(data, alreadyopened) {
  let m;
  let y;
  let t;
  // Ouverture de la liste avant traitement
  if (alreadyopened !== true) {
    // Ouverture de la liste pour le calcul
    data.container.dataset.open = 'true';
    data.dropdown.style.position = 'absolute';
    data.dropdown.style.transform = 'translate(-9000px, 0)';
  }

  // Sauvegarde de la hauteur initiale de la page
  const h = window.innerHeight + window.pageYOffset;
  // Recuperation de la position verticale du selecteur dans la fenetre
  y = 0;
  t = data.dropdownlist;
  while (check(t)) {
    if (getComputedStyle(t).position) {
      y +=
        getComputedStyle(t).position.toLowerCase() != 'static'
          ? t.offsetTop
          : 0;
    }

    t = t.parentNode;
  }

  // Calcul de la longueur de la liste
  m = Math.max(data.dropdownlist.scrollHeight, data.animatedopen.offsetHeight);
  // Verification de la hauteur disponible pour le selecteur
  if (h > y + m + 20) {
    data.dropdownlist.style.maxHeight = '100000px';
    // data.animatedopen.setAttribute('data-height', m + 'px');
  } else {
    m = h - y - 20;
    data.dropdownlist.style.maxHeight = m + 'px';
    // data.animatedopen.setAttribute('data-height', m + 'px');
  }

  // Fermeture du menu
  if (alreadyopened !== true) {
    delete data.container.dataset.open;
    data.dropdown.style.position = '';
    data.dropdown.style.transform = '';
  }
}

// - GESTION DES ACTIONS SUR LES ELEMENTS DU MENU   - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// ACTION DU MENU : Selection d'une option (commun entre version mobile et desktop)
function select(element, index, autoexecute) {
  if (!isNaN(index) && !element.dataset.selected && !element.dataset.disabled) {
    let i;
    // Identification de l'objet de donnees
    const d = Data.convertlist[index];
    // Recuperation de la valeur
    if (element.dataset.type == 'option') {
      // Selection appelee depuis la liste JS
      if (d.original.selectedIndex >= 0) {
        delete d.options[d.original.selectedIndex].convert.button.dataset
          .selected;
        d.options[d.original.selectedIndex].convert.button.removeAttribute(
          'aria-selected'
        );
      }

      i = element.dataset.index;
      d.options[i].convert.button.dataset.selected = true;
      d.options[i].convert.button.setAttribute('aria-selected', true);
      d.original.selectedIndex = i;
    } else {
      // Selection appelee depuis le selecteur original ou via la fonction "updateselection"
      i = d.original.selectedIndex;
      // Nettoyage du menu s'il existe
      if (!document.body.dataset.ismobile && d.original.selectedIndex >= 0) {
        // Nettoyage des styles des autres boutons
        let b = d.options.length - 1;
        for (b = b; b >= 0; b--) {
          delete d.options[b].convert.button.dataset.selected;
          d.options[b].convert.button.removeAttribute('aria-selected');
        }

        // Selection du bouton
        d.options[i].convert.button.dataset.selected = true;
        d.options[i].convert.button.setAttribute('aria-selected', true);
      }
    }

    // Changement de valeur dans le bouton d'ouverture
    if (d.usetextcustom) {
      d.button.text.value = d.options[i].textcustom;
      d.button.tag.setAttribute('title', d.options[i].text);
    } else {
      d.button.text.value = d.options[i].text;
    }

    delete d.button.tag.dataset.placeholder;
    // Mise a jour de la largeur du champ visible
    d.button.size.innerHTML = d.button.text.value;
    setTimeout(function () {
      d.button.text.style.width = d.button.size.offsetWidth + 5 + 'px';
      d.button.size.removeAttribute('style');
      d.button.size.innerHTML = '';
    }, 1);
    // Execution du formulaire si demande
    if (
      autoexecute !== false &&
      d.callback.selection(d) &&
      d.form != null &&
      (d.autoexecute || autoexecute === true) &&
      d.callback.beforesubmit(d)
    ) {
      d.form.requestSubmit();
      d.callback.aftersubmit(d);
    }
  }

  // Fermeture du menu si on n'est pas en mode mobile
  if (element.dataset.type == 'option') display(index, true);
}

// ACTION DU MENU : Ajout de l'effet de survol sur le bouton visuel en mode "mobile"
function state(e, index, buttoncall) {
  if (!isNaN(index)) {
    // Identification de l'objet de donnees
    const d = Data.convertlist[index];
    // Identification de l'action a executer
    if (e.type.toLowerCase() == 'focus') {
      d.button.tag.dataset.focus = true;
    } else {
      delete d.button.tag.dataset.focus;
    }

    // Verification du blocage du curseur (affichage du curseur texte dans le bouton d'ouverture du selecteur)
    if (
      !buttoncall &&
      !document.body.dataset.ismobile &&
      e.type.toLowerCase() == 'focus' &&
      !d.editable
    )
      d.button.text.select();
  }
}

// ACTION DU MENU : Navigation clavier sur le menu "desktop"
function keyboard(e, index) {
  if (!isNaN(index)) {
    let i;
    let t;
    // Identification de l'objet de donnees
    const d = Data.convertlist[index];
    // Verification de la touche choisie
    let k = null;
    if (e.keyCode) {
      k = e.keyCode;
    } else if (e) k = e.which ? e.which : 0;
    // Traitement de la demande si la touche "alt" est enfoncee
    if (!d.container.dataset.open && (k == 38 || k == 56)) {
      // - - - - - - - - -> fermeture de la liste (touche "8" ou "arrow top" + touche "alt")
      k = e.altKey ? 13 : -1;
    } else if (!d.container.dataset.open && (k == 40 || k == 50)) {
      // - - - - -> ouverture de la liste (touche "2" ou "arrow bottom" + touche "alt")
      k = e.altKey ? 40 : -1;
    }

    // Analyse de l'action demandee
    if (k == 38 || k == 40 || k == 50 || k == 56) {
      // - - - - - - - - - - - - - - - - - - -> deplacement de la selection (touche "2" ou "8" ou "arrow bottom" ou "arrow top")
      // Rafraichissement de la liste d'options accessibles
      d.availableoptions = [];
      t = d.viewoptions.length;
      for (i = 0; i < t; i++) {
        if (!d.options[d.viewoptions[i]].original.getAttribute('disabled')) {
          d.availableoptions.push(d.viewoptions[i]);
        }
      }

      // Lancement de la navigation dans les options disponibles
      t = true;
      if (d.availableoptions.length === 0) t = false;
      else if (
        d.availableoptions.length == 1 &&
        d.options[d.availableoptions[0]].value == 'placeholder'
      )
        t = false;
      if (t) {
        // Verification de l'autorisation d'affichage de la liste
        if (!d.openbykeyboard) {
          d.container.dataset.hiddenlist = true;
          d.animatedopen.dataset.height = 10;
        }

        // Ouverture du menu
        if (!d.container.dataset.open) display(index, null);
        // Recherche du bouton ayant actuellement le focus
        t = -1;
        i = d.availableoptions.length - 1;
        for (i = i; i >= 0; i--) {
          if (d.options[d.availableoptions[i]].convert.button.dataset.focus) {
            t = i;
            break;
          }
        }

        // Recherche du sens du deplacement
        i = k == 38 || k == 56 ? -1 : 1;
        // Suppression de l'effet "focus"
        if (t > -1)
          delete d.options[d.availableoptions[t]].convert.button.dataset.focus;
        else t = d.original.selectedIndex - i;
        // Recherche du bouton suivant pour l'assignation du survol
        t += i;
        t = Math.min(d.availableoptions.length - 1, Math.max(t, 0));
        t = d.availableoptions[t];
        d.options[t].convert.button.dataset.focus = true;
        // Changement de valeur dans le bouton d'ouverture
        if (!d.editable) {
          if (d.usetextcustom) {
            d.button.text.value = d.options[t].textcustom;
            d.button.tag.setAttribute('title', d.options[t].text);
          } else {
            d.button.text.value = d.options[t].text;
          }

          if (d.options[t].value != 'placeholder')
            delete d.button.tag.dataset.placeholder;
        }

        // Mise a jour de la position de la liste des valeurs
        if (d.options[t].value != 'placeholder') {
          t = d.options[t].convert.button.parentNode;
          i = t.offsetTop;
          k = i + t.offsetHeight;
          if (k > d.dropdownlist.offsetHeight + d.dropdownlist.scrollTop) {
            d.dropdownlist.scrollTop = k - d.dropdownlist.offsetHeight;
          } else if (i < d.dropdownlist.scrollTop) {
            d.dropdownlist.scrollTop = i;
          }
        }
      }

      // Blocage de l'action par defaut
      return false;
    }

    if (k == 33 || k == 34) {
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - -> deplacement de la liste (touche "Page UP" ou "Page Down")
      // Recherche du bouton ayant actuellement le focus
      t = -1;
      i = d.options.length - 1;
      for (i = i; i >= 0; i--) {
        if (d.options[i].convert.button.dataset.focus) {
          t = i;
          break;
        }
      }

      // Identification du lien
      if (t < 0) {
        i = { top: 0, bottom: d.dropdownlist.scrollHeight };
      } else if (d.options[t].value == 'placeholder') {
        i = { top: 0, bottom: d.dropdownlist.scrollHeight };
      } else {
        t = d.options[t].convert.button.parentNode;
        i = {};
        i.bottom = t.offsetTop;
        i.top = i.bottom + t.offsetHeight - d.dropdownlist.offsetHeight;
      }

      // Mise a jour de la position de la liste des valeurs
      d.dropdownlist.scrollTop = k == 33 ? i.top : i.bottom;
      // Blocage de l'action par defaut
      return false;
    }

    if (k == 9 || k == 27) {
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - -> fermeture sans selection (touche "tab" ou "escape")
      t = d.options[d.original.selectedIndex];
      t =
        d.original.selectedIndex > -1 &&
        typeof d.options[d.original.selectedIndex] === 'object'
          ? t
          : { value: '' };
      // Reinitialisation de la valeur affichee
      if (d.editable) {
        d.button.text.value = '';
      } else if (d.usetextcustom) {
        d.button.text.value = d.options[d.original.selectedIndex].textcustom;
        d.button.tag.setAttribute(
          'title',
          d.options[d.original.selectedIndex].text
        );
      } else {
        d.button.text.value = d.options[d.original.selectedIndex].text;
      }

      if (t.value == 'placeholder') d.button.tag.dataset.placeholder = true;
      // Fermeture de la liste
      display(index, true, true);
      return true;
    }

    if ((k == 32 && !d.editable) || k == 13) {
      //  - - - - - - - - - - - - - - - - - - -> selection d'une option (touche "space" [si non editable] ou "enter")
      // Verification de l'etat du menu
      if (d.container.dataset.open) {
        // Selection de l'option
        i = d.options.length - 1;
        for (i = i; i >= 0; i--) {
          if (d.options[i].convert.button.dataset.focus) {
            select(d.options[i].convert.button, index);
            break;
          }
        }
      } else {
        // Ouverture du menu
        display(index, null);
      }

      // Blocage de l'action par defaut
      return false;
    }

    if (k >= 65 && k <= 90) {
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - -> selection d'une option dans la liste (mode non editable)
      if (!d.editable) {
        // Recherche de la premiere option dont le libelle correspond au texte tape
        // eslint-disable-next-line unicorn/prefer-code-point
        k = String.fromCharCode(k).toLowerCase();
        t = [];
        t[0] = -1;
        t[1] = d.options.length;
        if (d.usetextcustom) {
          for (i = 0; i < t[1]; i++) {
            if (d.options[i].textcustom.toLowerCase().indexOf(k) == 0) {
              t[0] = i;
              break;
            }
          }
        } else {
          for (i = 0; i < t[1]; i++) {
            if (d.options[i].text.toLowerCase().indexOf(k) == 0) {
              t[0] = i;
              break;
            }
          }
        }

        // Selection de la premiere option correspondante a la touche ou fermeture du menu
        if (t[0] >= 0 && !d.options[t[0]].convert.button.dataset.disabled) {
          // Nettoyage de la selection
          for (i = 0; i < t[1]; i++) {
            delete d.options[i].convert.button.dataset.focus;
          }

          // Selection de l'option
          t = t[0];
          d.options[t].convert.button.dataset.focus = true;
          // Changement de valeur dans le bouton d'ouverture
          if (d.usetextcustom) {
            d.button.text.value = d.options[t].textcustom;
            d.button.tag.setAttribute('title', d.options[t].text);
          } else {
            d.button.text.value = d.options[t].text;
          }

          if (d.options[t].value != 'placeholder')
            delete d.button.tag.dataset.placeholder;
          // Ouverture du menu et deplacement du scroll
          if (!d.container.dataset.open) {
            display(index, null);
            d.dropdownlist.scrollTop =
              d.options[t].convert.button.parentNode.offsetTop;
          } else {
            t = d.options[t].convert.button.parentNode;
            i = t.offsetTop;
            k = i + t.offsetHeight;
            if (k > d.dropdownlist.offsetHeight + d.dropdownlist.scrollTop) {
              d.dropdownlist.scrollTop = k - d.dropdownlist.offsetHeight;
            } else if (i < d.dropdownlist.scrollTop) {
              d.dropdownlist.scrollTop = i;
            }
          }
        } else {
          // Fermeture du menu
          display(index, true);
        }
      } else {
        delete d.button.tag.dataset.placeholder;
      }

      return true;
    }

    if (k == 8 && d.editable) {
      // - - - - - - - - - - - - - - - - - - - - - - - - - - -> mise a jour de la liste a la suppression d'un element de la liste (touche "backspace" si editable)
      if (d.button.text.value.length === 0) {
        t = d.options[d.original.selectedIndex];
        t =
          d.original.selectedIndex > -1 &&
          typeof d.options[d.original.selectedIndex] === 'object'
            ? t
            : { value: '' };
        // Reinitialisation de la valeur affichee
        if (d.editable) {
          d.button.text.value = '';
        } else if (d.usetextcustom) {
          d.button.text.value = d.options[d.original.selectedIndex].textcustom;
          d.button.tag.setAttribute(
            'title',
            d.options[d.original.selectedIndex].text
          );
        } else {
          d.button.text.value = d.options[d.original.selectedIndex].text;
        }

        if (t.value == 'placeholder') d.button.tag.dataset.placeholder = true;
        // Fermeture de la liste
        display(index, true, true);
      }

      return true;
    }

    return true;
  }

  return true;
}

// ACTION DU MENU : Mise a jour de la liste des options coherentes avec le texte encode dans le selecteur
function updatelist(e, index) {
  if (!isNaN(index)) {
    let i;
    let m;
    // Identification de l'objet de donnees
    const d = Data.convertlist[index];
    // Verification de la touche choisie
    let k = null;
    if (e.keyCode) k = e.keyCode;
    else if (e) k = e.which ? e.which : 0;
    // Verification qu'on n'ait pas fait de copier coller
    if (e == null) e = { type: 'none' };
    // Mise a jour de la liste via une fonction externe
    if (
      ((k >= 65 && k <= 90) ||
        k == 32 ||
        k == 42 ||
        k == 46 ||
        k == 8 ||
        e.type.toLowerCase() == 'paste') &&
      d.callback.updatefield(e, d)
    ) {
      // Mise a jour des options accessibles
      d.viewoptions = [];
      m = d.options.length;
      for (i = 0; i < m; i++) {
        // Suppression du focus
        delete d.options[i].convert.button.dataset.focus;
        // Suppression de la selection
        delete d.options[i].convert.button.dataset.selected;
        d.original.selectedIndex = -1;
        // Verification de la validite du lien
        if (
          !d.options[i].convert.button.dataset.disabled &&
          d.options[i].value != 'placeholder'
        ) {
          d.viewoptions.push(i);
        }
      }

      // Mise a jour de l'ouverture ou non de la liste
      if (d.button.text.value.length === 0 && (k == 42 || k == 8)) {
        if (d.viewoptions.length === 0) {
          display(index, true);
        } else if (
          d.button.text.value.length === 0 &&
          d.viewoptions.length == 1 &&
          d.options[d.viewoptions[0]].value == 'placeholder'
        ) {
          display(index, true);
        } else if (!d.container.dataset.open) display(index, null);
      } else if (d.viewoptions.length > 0) {
        if (!d.container.dataset.open) display(index, null);
      } else if (d.viewoptions.length === 0) {
        display(index, true);
      }

      // Mise a jour de la hauteur de la liste
      d.animatedopen.style.height = d.dropdownlist.offsetHeight + 'px';
      delete d.animatedopen.dataset.height;
      d.dropdownlist.scrollTop = 0;
    }
  }
}

// - GESTION DE L'OUVERTURE ET FERMETURE DE PANNEAUX  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// OUVERTURE/FERMETURE : Changement de l'animation d'ouverture ou fermeture
function display(index, hide, nofocus) {
  // Identification de l'objet de donnees
  const d = Data.convertlist[index];
  // Identification de l'affichage desire
  let v = hide ? false : !d.container.dataset.open;
  // Verification du contenu tape
  if (v && d.editable && d.autocomplete) {
    v = d.button.tag.dataset.validationtext;
  }

  // Affichage du contenu
  if (v) {
    // Rafraichissement de la liste d'options accessibles
    v = d.viewoptions.length - 1;
    for (v = v; v >= 0; v--) {
      if (d.options[d.viewoptions[v]].original.getAttribute('disabled')) {
        d.options[d.viewoptions[v]].convert.button.dataset.disabled = true;
      } else {
        delete d.options[d.viewoptions[v]].convert.button.dataset.disabled;
      }
    }

    // Execution de l'ouverture
    if (d.callback.beforeopen == null || d.callback.beforeopen(null, d)) {
      // Assignation du focus si non contredit
      if (d.editable && nofocus !== true) {
        d.button.text.focus();
      } else {
        d.button.tag.focus();
      }

      // Verification de la largeur du menu dropdown
      if (
        Number.parseFloat(d.animatedopen.dataset.height) == 0 ||
        d.button.tag.style.width == '0'
      ) {
        // Calcul des dimensions
        calcul(d, view);
      } else {
        height(d, false);
        // Ouverture du menu
        d.container.dataset.open = 'true';
        d.dropdownlist.scrollTop = 0;
        d.dropdownlist.setAttribute('aria-expanded', true);
        d.animatedopen.style.height = d.animatedopen.dataset.height;
        d.button.icontext.innerHTML = d.button.icon.dataset.closelabel;
      }
    }
  } else if (
    d.callback.beforeclose == null ||
    d.callback.beforeclose(null, d)
  ) {
    v = Boolean(d.container.dataset.open);
    // Fermeture du menu
    delete d.container.dataset.open;
    d.dropdownlist.setAttribute('aria-expanded', false);
    delete d.container.dataset.hiddenlist;
    d.button.icontext.innerHTML = d.button.icon.dataset.openlabel;
    d.animatedopen.style.height = '';
    // Assignation du focus si non contredit
    if (d.editable && nofocus !== true) {
      d.button.text.focus();
    } else {
      d.button.tag.focus();
    }

    // Nettoyage des selections dans les options du menu
    for (let i = d.options.length - 1; i >= 0; i--) {
      delete d.options[i].convert.button.dataset.focus;
    }

    // Suppression de la hauteur par defaut
    if (d.options.length != d.viewoptions.length)
      delete d.animatedopen.dataset.height;
    // Calcul des dimensions
    if (d.button.tag.style.width == '0' || d.button.tag.style.width == '0px')
      calcul(d, null, false);
  }

  return true;
}

function view(e, index) {
  if (!isNaN(index)) {
    return display(index, false);
  }
}

function clickout(e, index) {
  if (!isNaN(index) && check(e.target)) {
    const i = Data.convertlist[index];
    if (e.target.classList != null) {
      if (!e.target.classList.contains(i.reference))
        return display(index, true, true);
    } else if (
      e.target.className != null &&
      typeof e.target.className === 'string' &&
      !i.reference.test(e.target.className)
    )
      return display(index, true, true);
  }
}

// OUVERTURE/FERMETURE : Rafraichissement de la taille du selecteur lors du redimensionnement de la fenetre
function resize(index) {
  if (!isNaN(index)) {
    display(index, true, true);
  }
}

// OUVERTURE/FERMETURE : Fermeture generale de tous les selecteurs
function closecomponents(exceptions) {
  let i;
  let a;
  let e;
  let l = Data.convertlist;
  // Verification de la validite des exceptions
  if (
    exceptions != null &&
    typeof exceptions === 'object' &&
    exceptions.length > 0
  ) {
    // Duplication de la liste
    a = [];
    e = l.length;
    for (i = 0; i < e; i++) {
      a.push(l[i]);
    }

    l = a;
    // Analyse des exceptions transmises
    e = null;
    a = [];
    i = exceptions.length - 1;
    for (i = i; i >= 0; i--) {
      e = exceptions[i];
      if (typeof e === 'object') {
        // >> exception = objet de donnees renvoyes par le widget a sa creation
        if (!isNaN(e.index)) {
          if (Data.convertlist[e.index]) {
            l[e.index] = null;
          }
        } else if (
          typeof e.tagName === 'string' &&
          String(e.tagName).toLowerCase() == 'select' &&
          e.dataset.selectconverted &&
          !isNaN(Data.reference[e.id])
        ) {
          // >> exception = balise HTML du selecteur original
          l[Data.reference[e]] = null;
        }
      } else if (!isNaN(e)) {
        // >> exception = index de convertion du composant
        if (Data.convertlist[e]) {
          l[e] = null;
        }
      } else if (
        typeof e === 'string' && // >> exception = reference (ID) du composant
        !isNaN(Data.reference[e])
      ) {
        l[Data.reference[e]] = null;
      }
    }
  }

  // Fermeture des selecteurs demandes
  i = l.length - 1;
  for (i = i; i >= 0; i--) {
    if (l[i] != null) {
      display(l[i], true, true);
    }
  }
}

// - GESTION DES FONCTIONS PUBLIQUES  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const selectHandler = {
  // Fonction de convertion du selecteur natif en composant JS
  convert: askconvertion, // Parametres de la fonction : Select.convert(reference, params)
  //    > reference           // [STRING/NODE]            (mandatory)   : Reference du selecteur a convertir.
  //                                            > STRING:   id du selecteur
  //                                            > NODE:   balise HTML du selecteur
  //    > params              // [OBJECT]             (optional)    : Objet de personnalisation du selecteur
  //      > params.autoexecute        // [BOOLEAN]  (default value: false)      : Definit si le formulaire doit etre execute au changement de valeur du selecteur
  //      > params.forceplaceholder     // [BOOLEAN]  (default value: false)      : Definit si le selecteur doit etre parametre sur le placeholder
  //      > params.editable       // [BOOLEAN]  (default value: false)      : Definit si le champ d'ouverture du selecteur peut etre editable et ayant un systeme d'autocompletion via la liste
  //      > params.usetextcustom    // [BOOLEAN]  (default value: false)      : Definit si la valeur affichee dans le selecteur converti est la valeur de la balise option ou le libelle
  //      > params.openbykeyboard     // [BOOLEAN]  (default value: true)         : Definit si le selecteur doit se deployer lors de la navigation clavier
  //      > params.viewdisabled       // [BOOLEAN]  (default value: false)      : Definit si le selecteur doit afficher ou non les options non accessible
  //      > params.cb_afterconvert    // [FUNCTION] (need a return value = true)    : Fonction appelee apres la convertion du selecteur
  //      > params.cb_beforeopen    // [FUNCTION] (need a return value = true)    : Fonction appelee avant l'ouverture  de la liste du selecteur
  //      > params.cb_afteropen       // [FUNCTION] (need a return value = true)    : Fonction appelee apres l'ouverture  de la liste du selecteur
  //      > params.cb_beforeclose     // [FUNCTION] (need a return value = true)    : Fonction appelee avant la fermeture de la liste du selecteur
  //      > params.cb_afterclose    // [FUNCTION] (need a return value = true)    : Fonction appelee apres la fermeture de la liste du selecteur
  //      > params.cb_beforesubmit    // [FUNCTION] (need a return value = true)    : Fonction appelee avant l'execution du formulaire (si mode "autoexecute")
  //      > params.cb_aftersubmit     // [FUNCTION] (need a return value = true)    : Fonction appelee apres l'execution du formulaire (si mode "autoexecute")
  //      > params.cb_selection       // [FUNCTION] (need a return value = true)    : Fonction appelee lors de la selection du selecteur
  //      > params.cb_updatefield     // [FUNCTION] (need a return value = true)    : Fonction appelee apres l'edition du champ texte (si mode "editable")
  // Reponse de la fonction : Select.convert() = result
  //    > result.converted          // [BOOLEAN]                  : Defini si la convertion du selecteur a reussi ou non
  //    > result.data             // [OBJECT]                     : Objet de donnees contenant toutes les informations du selecteur converti (vaut "null" si la convertion a echoue)

  // Fonction de mise a jour des parametres definis dans un selecteur deja converti
  updateparams: updatedata, // Parametres de la fonction : Select.updateparams(reference, params)
  //    > reference           // [STRING/NODE/NUMBER/OBJECT]    (mandatory) : Reference du selecteur converti a manipuler
  //                                            > STRING:   identification via l'id du selecteur original converti
  //                                            > NODE:   balise HTML du selecteur original converti
  //                                            > NUMBER:   identification via l'index de convertion renvoye par la fonction "convert"
  //                                            > OBJECT:   identification via l'objet de donnees issu de la convertion du selecteur (result.data)
  //    > params              // [OBJECT]             (mandatory) : Objet de personnalisation du selecteur contenant les nouvelles valeurs a remplacer
  //      > params.autoexecute        // [BOOLEAN]  (default value: false)      : Definit si le formulaire doit etre execute au changement de valeur du selecteur
  //      > params.usetextcustom    // [BOOLEAN]  (default value: false)      : Definit si la valeur affichee dans le selecteur converti est la valeur de la balise option ou le libelle
  //      > params.openbykeyboard     // [BOOLEAN]  (default value: true)         : Definit si le selecteur doit se deployer lors de la navigation clavier
  //      > params.viewdisabled       // [BOOLEAN]  (default value: false)      : Definit si le selecteur doit afficher ou non les options non accessible
  //      > params.cb_beforeopen    // [FUNCTION] (need a return value = true)    : Fonction appelee avant l'ouverture  de la liste du selecteur
  //      > params.cb_afteropen       // [FUNCTION] (need a return value = true)    : Fonction appelee apres l'ouverture  de la liste du selecteur
  //      > params.cb_beforeclose     // [FUNCTION] (need a return value = true)    : Fonction appelee avant la fermeture de la liste du selecteur
  //      > params.cb_afterclose    // [FUNCTION] (need a return value = true)    : Fonction appelee apres la fermeture de la liste du selecteur
  //      > params.cb_beforesubmit    // [FUNCTION] (need a return value = true)    : Fonction appelee avant l'execution du formulaire (si mode "autoexecute")
  //      > params.cb_aftersubmit     // [FUNCTION] (need a return value = true)    : Fonction appelee apres l'execution du formulaire (si mode "autoexecute")
  //      > params.cb_selection       // [FUNCTION] (need a return value = true)    : Fonction appelee lors de la selection du selecteur
  //      > params.cb_updatefield     // [FUNCTION] (need a return value = true)    : Fonction appelee apres l'edition du champ texte (si mode "editable")
  // Reponse de la fonction : Select.updateparams() = result
  //    > result.executed           // [BOOLEAN]                  : Defini si la demande a reussi ou non
  //    > result.data             // [OBJECT]                     : Objet de donnees contenant toutes les informations du selecteur converti
  //    > result.message        // [STRING]                     : Message d'erreur

  // Fonction de mise a jour de la selection du composant
  updateselection, // Parametres de la fonction : Select.updateselection(reference, selectedvalue, autoexecute)
  //    > reference           // [STRING/NODE/NUMBER/OBJECT]    (mandatory) : Reference du selecteur converti a manipuler
  //                                            > STRING:   identification via l'id du selecteur original converti
  //                                            > NODE:   balise HTML du selecteur original converti
  //                                            > NUMBER:   identification via l'index de convertion renvoye par la fonction "convert"
  //                                            > OBJECT:   identification via l'objet de donnees issu de la convertion du selecteur (result.data)
  //    > selectedvalue         // [STRING]             (mandatory) : Reference de l'option du selecteur original a selectionner, identification de l'option concernee via sa valeur
  //    > autoexecute           // [BOOLEAN]  (default value: true)   (optional)  : Defini si le composant doit executer les actions de selection (callback, etc) du selecteur et formulaire associe
  // Reponse de la fonction : Select.updateselection() = result
  //    > result.converted          // [BOOLEAN]                  : Defini si la mise a jour du selecteur a reussi ou non
  //    > result.data             // [OBJECT]                     : Objet de donnees contenant toutes les informations du selecteur converti
  //    > result.message        // [STRING]                     : Message d'erreur

  // Fonction de mise a jour de la largeur du composant
  resize, // Parametres de la fonction : Select.resize(reference)
  //    > reference           // [STRING/NODE/NUMBER/OBJECT]    (mandatory) : Reference du selecteur converti a manipuler
  //                                            > STRING:   identification via l'id du selecteur original converti
  //                                            > NODE:   balise HTML du selecteur original converti
  //                                            > NUMBER:   identification via l'index de convertion renvoye par la fonction "convert"
  //                                            > OBJECT:   identification via l'objet de donnees issu de la convertion du selecteur (result.data)

  // Fonction de mise a jour de la largeur du composant
  reset, // Parametres de la fonction : Select.reset(reference, params)
  //    > reference           // [STRING/NODE/NUMBER/OBJECT]    (mandatory) : Reference du selecteur converti a manipuler
  //                                            > STRING:   identification via l'id du selecteur original converti
  //                                            > NODE:   balise HTML du selecteur original converti
  //                                            > NUMBER:   identification via l'index de convertion renvoye par la fonction "convert"
  //                                            > OBJECT:   identification via l'objet de donnees issu de la convertion du selecteur (result.data)
  //    > params              // [OBJECT]             (optional)    : Objet de personnalisation du selecteur (identique a l'objet de donnees utilise pour la fonction de convertion)
  //      > params.autoexecute        // [BOOLEAN]  (default value: false)      : Definit si le formulaire doit etre execute au changement de valeur du selecteur
  //      > params.usetextcustom    // [BOOLEAN]  (default value: false)      : Definit si la valeur affichee dans le selecteur converti est la valeur de la balise option ou le libelle
  //      > params.openbykeyboard     // [BOOLEAN]  (default value: true)         : Definit si le selecteur doit se deployer lors de la navigation clavier
  //      > params.viewdisabled       // [BOOLEAN]  (default value: false)      : Definit si le selecteur doit afficher ou non les options non accessible
  //      > params.cb_beforeopen    // [FUNCTION] (need a return value = true)    : Fonction appelee avant l'ouverture  de la liste du selecteur
  //      > params.cb_afteropen       // [FUNCTION] (need a return value = true)    : Fonction appelee apres l'ouverture  de la liste du selecteur
  //      > params.cb_beforeclose     // [FUNCTION] (need a return value = true)    : Fonction appelee avant la fermeture de la liste du selecteur
  //      > params.cb_afterclose    // [FUNCTION] (need a return value = true)    : Fonction appelee apres la fermeture de la liste du selecteur
  //      > params.cb_beforesubmit    // [FUNCTION] (need a return value = true)    : Fonction appelee avant l'execution du formulaire (si mode "autoexecute")
  //      > params.cb_aftersubmit     // [FUNCTION] (need a return value = true)    : Fonction appelee apres l'execution du formulaire (si mode "autoexecute")
  //      > params.cb_selection       // [FUNCTION] (need a return value = true)    : Fonction appelee lors de la selection du selecteur
  //      > params.cb_updatefield     // [FUNCTION] (need a return value = true)    : Fonction appelee apres l'edition du champ texte (si mode "editable")
  // Reponse de la fonction : Select.updateparams() = result
  //    > result.executed           // [BOOLEAN]                  : Defini si la mise a jour du selecteur a reussi ou non
  //    > result.data             // [OBJECT]                     : Objet de donnees contenant toutes les informations du selecteur converti
  //    > result.message        // [STRING]                     : Message d'erreur

  // Fonction de fermeture globale des selecteurs de la page
  closeall: closecomponents, // Parametres de la fonction : Select.closeall(exceptions)
  //    > exceptions            // [ARRAY]              (optional)    : Liste de references de composant a ne pas fermer
  //      > exceptions[x]         // [STRING/NODE/NUMBER/OBJECT]            : Reference du selecteur converti a manipuler
  //                                            > STRING:   identification via l'id du selecteur original converti
  //                                            > NODE:   balise HTML du selecteur original converti
  //                                            > NUMBER:   identification via l'index de convertion renvoye par la fonction "convert"
  //                                            > OBJECT:   identification via l'objet de donnees issu de la convertion du selecteur (result.data)
};

export { selectHandler as Select };
