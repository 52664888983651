// JAVASCRIPT GERANT L'AFFICHAGE GENERIQUE DES PAGES DES SITES EUROPARL
// Copyright : European Parliament

// Classes JS definies dans ce fichier :
//  . Select          : Script convertissant un selecteur <select> en composant JavaScript personnalisable
//  . Accordion       : Script convertissant l'affichage en accordeon (expand/collapse [+]/[-])

// Fonctionnalites JS gerees par ce fichier :
//  . detection du support/device utilise pour visionner le site (mobile/desktop)
//  . affichage parallaxe de la barre d'outil inferieure
//  . ouverture/fermeture du menu de recherche en mode reduit
//  . ouverture/fermeture du menu de navigation en mode reduit
//  . navigation clavier dans le menu de navigation
//  . convertion des selecteurs <select> presents dans l'entete en composant JavaScript
//  . convertion de la liste des sites "other websites" en select JS pour le mode reduit
//  . affichage accordeon du pied de page en mode reduit
//  . gestion de l'affichage sur les menus lateraux (effet parallaxe + mode accordeon)

import { Select } from './select.js';
import { Accordion } from './accordion.js';
import { FollowingScroll } from './follow-scroll.js';
import { TableOfContent } from './table-of-content.js';
import { expandQuestions } from './expand-collapse.js';
import { globals, init } from './initialize.js';
import { loader } from './amd-loader.js';

window.Select = Select;
window.Accordion = Accordion;
window.FollowingScroll = FollowingScroll;
window.TableOfContent = TableOfContent;

for (const [key, value] of globals) {
  window[key] = value;
}

document.addEventListener('readystatechange', init);

window.addEventListener('load', init);
window.addEventListener('load', expandQuestions);
window.addEventListener('scroll', expandQuestions);

loader(window);
