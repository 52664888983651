/* eslint func-names: off, eqeqeq: off, camelcase: off, no-self-assign: off, unicorn/prefer-number-properties: off, unicorn/prevent-abbreviations: off, no-eq-null: off, max-depth: off, complexity: off, max-params: off, unicorn/prefer-query-selector: off, no-negated-condition: off, prefer-rest-params: off */

// JAVASCRIPT GERANT L'AFFICHAGE GENERIQUE DES PAGES DES SITES EUROPARL
// Copyright : European Parliament

// Classes JS definies dans ce fichier :
//  . Select          : Script convertissant un selecteur <select> en composant JavaScript personnalisable
//  . Accordion       : Script convertissant l'affichage en accordeon (expand/collapse [+]/[-])

// Fonctionnalites JS gerees par ce fichier :
//  . detection du support/device utilise pour visionner le site (mobile/desktop)
//  . affichage parallaxe de la barre d'outil inferieure
//  . ouverture/fermeture du menu de recherche en mode reduit
//  . ouverture/fermeture du menu de navigation en mode reduit
//  . navigation clavier dans le menu de navigation
//  . convertion des selecteurs <select> presents dans l'entete en composant JavaScript
//  . convertion de la liste des sites "other websites" en select JS pour le mode reduit
//  . affichage accordeon du pied de page en mode reduit
//  . gestion de l'affichage sur les menus lateraux (effet parallaxe + mode accordeon)

/* ============================================================================================================================================================================ */
/* === GESTION DES ACTIONS DE SUIVI PARALLAXE ================================================================================================================================= */
/* ============================================================================================================================================================================ */

const Data = { convertlist: [], reference: {}, toolbar: null };

// - VALIDATION - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// VALIDATION : controle de l'objet de donnees passe en parametre
function check(d) {
  if (typeof d === 'object') {
    if (d != null) {
      return d.tagName != null;
    }

    return false;
  }

  return false;
}

// - CONVERTION - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

function convert(columntarget, contenttarget, parameters) {
  // Initialisation des variables
  let i;
  let m;
  let t;
  let b;
  let l;
  let data = null;
  // Recherche des balises utiles
  const o = {};
  o.parent = columntarget;
  o.component = null;
  if (check(o.parent)) {
    if (
      !o.parent.dataset.following &&
      o.parent.classList.contains('ep_gridcolumn')
    ) {
      // Analyse de la demande specifique d'une balise a suivre
      if (check(contenttarget)) {
        o.component = contenttarget;
      } else {
        // Recherche de la balise a animer
        l = [];
        b = null;
        m = o.parent.childNodes.length - 1;
        for (i = 0; i < m; i++) {
          t = o.parent.childNodes[i];
          if (
            check(t) &&
            (t.classList.contains('ep_gridrow') ||
              t.classList.contains('ep_gridcolumn-content'))
          ) {
            b = t;
            break;
          }
        }

        if (b != null) {
          m = b.childNodes.length - 1;
          for (i = 0; i < m; i++) {
            if (check(b.childNodes[i])) l.push(b.childNodes[i]);
          }

          if (l.length == 1) o.component = l[0];
          else o.parent = null;
        } else o.parent = null;
      }
    } else o.parent = null;
  }

  // Verification des balise transmises en parametre
  if (check(o.parent) && check(o.component)) {
    if (o.parent.classList.contains('ep_gridcolumn') && o.component != null) {
      // Recherche de la barre d'outil utilisee en survol lors du parallaxe
      if (Data.toolbar == null) {
        t = document.querySelector('#website-header');
        if (check(t)) {
          t = t.querySelectorAll('.toolbar');
          i = t.length - 1;
          for (i = i; i >= 0; i--) {
            if (t[i].classList.contains('toolbar_bottom')) {
              Data.toolbar = t[i];
              break;
            }
          }
        }
      }

      // Initialisation de l'objet de donnees du widget
      data = {};
      data.index = null; // [INT]                   : Identifiant du widget dans le listing
      data.reference = null; // [STRING]                  : Identifiant de la balise convertie
      data.column = o.parent; // [NODE]                   : Reference de la balise "colonne" contenant les elements a animer
      data.component = o.component; // [NODE]                   : Balise principale a animer
      // Sauvegarde des donnees dans la classe
      Data.convertlist.push(data);
      data.index = Data.convertlist.length - 1;
      data.reference = 'followingscrollid_' + data.index;
      Data.reference[data.reference] = data.index;
      data.column.dataset.following = data.index;
      // Ajout des evenements de mises a jour
      if (Data.convertlist.length < 2 && Data.toolbar != null) {
        document.addEventListener(
          'scroll',
          function (e) {
            update(e);
          },
          false
        );
        window.addEventListener(
          'resize',
          function (e) {
            update(e);
          },
          false
        );
      }
    } else data = null;
  } else data = null;
  // Appel de la fonction "callback" apres la convertion du selecteur si demandee
  b = false;
  if (
    parameters != null &&
    typeof parameters === 'object' &&
    typeof parameters.cb_afterconvert === 'function'
  )
    b = true;
  if (b) parameters.cb_afterconvert(data != null, data);
  else return { converted: data != null, data };
}

// - GESTION DE L'ACTION DE SUIVI DU SCROLL - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// AFFICHAGE : Mise a jour de l'affichage du composant
function refresh(index, call_by_scroll) {
  // Calcul de la hauteur du scroll
  const s = Math.max(
    document.body.scrollTop,
    document.documentElement.scrollTop
  );
  // Verification de la demande : tout mettre a jour ou juste un specifique ?
  if (!isNaN(index) && index >= 0 && index < Data.convertlist.length) {
    parallax(s, Data.convertlist[index], call_by_scroll);
  } else {
    let i = Data.convertlist.length - 1;
    for (i = i; i >= 0; i--) {
      parallax(s, Data.convertlist[i], call_by_scroll);
    }
  }
}

function askrefresh(index) {
  if (
    arguments.length == 1 ||
    (arguments.length == 2 && typeof arguments[0] === 'object')
  ) {
    refresh(arguments[1], false);
  } else {
    refresh(index, false);
  }
}

// AFFICHAGE : Repositionnement du composant selon le scroll de la page
function parallax(scrollposition, data, delay) {
  let y = 0;
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  let morey = 0;
  const limit = { height: 0, y: 0 };
  const target = { height: 0, y: 0 };
  // Calcul de la hauteur du composant et de la zone visible
  target.y = data.component.offsetTop;
  target.height = data.component.offsetHeight;
  limit.y = data.column.offsetTop;
  limit.height = data.column.offsetHeight;
  // Correction du scroll (prise en compte de la hauteur de la barre d'outil parallaxe)
  if (document.body.dataset.headerparallax) {
    morey = Data.toolbar.offsetHeight;
  }

  // Analyse de la hauteur du composant
  if (target.height < limit.height) {
    if (target.height > height) {
      // Calcul de la position a assigner au composant
      if (target.y + target.height > scrollposition + height) {
        y = 0;
      } else if (scrollposition + height < limit.y + limit.height) {
        y = Math.max(0, scrollposition + height - (target.y + target.height));
      } else {
        y = Math.max(0, limit.height - target.height);
      }
    } else {
      // Calcul de la position a assigner au composant
      y = Math.max(
        0,
        Math.min(
          limit.height - target.height,
          scrollposition + morey - limit.y + 60
        )
      );
    }
  } else {
    y = 0;
  }

  // Definition du delais d'animation (si la fonction est bien appelee par les evenements de scroll)
  data.component.style.transitionDelay = delay ? '' : '0s';
  // Assignation du la nouvelle coordonnee
  data.component.style.transform = 'translate(0, ' + y + 'px)';
  data.component.style.webkitTransform = 'translate(0, ' + y + 'px)';
}

// Appel de l'action par la fonction elle-meme
function update(_e) {
  refresh(-1, true);
}

// - GESTION DES FONCTIONS PUBLIQUES  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const FollowingScroll = {
  // Fonction d'ajout de la fonctionnalite de suivi du scroll a un composant HTML selon les limites de la colonne
  convert, // Parametres de la fonction : FollowingScroll.convert(container, reference, params)
  //    > container             // [NODE]               (mandatory)   : Reference de la balise "colonne" contenant le bloc a manipuler
  //    > reference             // [NODE]               (mandatory)   : Reference de la balise a manipuler
  //    > params              // [OBJECT]             (optional)    : Objet de personnalisation du composant
  //      > params.cb_afterconvert    // [FUNCTION] (need a return value = true)    : Fonction appelee apres la convertion du bloc
  //
  // Reponse de la fonction : FollowingScroll.convert() = result
  //    > result.converted          // [BOOLEAN]                  : Defini si la fonctionnalite a pu etre ajoutee ou non
  //    > result.data             // [OBJECT]                     : Objet de donnees contenant toutes les informations du composant converti (vaut "null" si la convertion a echoue)

  // Fonction de mise a jour de la position du composant
  refresh: askrefresh, // Parametres de la fonction : FollowingScroll.refresh(reference)
  //    > reference           // [NUMBER]               (optional) : Reference du composant a manipuler (si non mentionne, tous les composants convertis seront mise a jour)
};

export { FollowingScroll };
