/* global define */
/* eslint func-names: off, eqeqeq: off, camelcase: off, no-self-assign: off, unicorn/prefer-number-properties: off, unicorn/prevent-abbreviations: off, no-eq-null: off, max-depth: off, complexity: off, max-params: off, unicorn/prefer-query-selector: off, no-negated-condition: off, prefer-rest-params: off */

// JAVASCRIPT GERANT L'AFFICHAGE GENERIQUE DES PAGES DES SITES EUROPARL
// Copyright : European Parliament

// Classes JS definies dans ce fichier :
//  . Select          : Script convertissant un selecteur <select> en composant JavaScript personnalisable
//  . Accordion       : Script convertissant l'affichage en accordeon (expand/collapse [+]/[-])

// Fonctionnalites JS gerees par ce fichier :
//  . detection du support/device utilise pour visionner le site (mobile/desktop)
//  . affichage parallaxe de la barre d'outil inferieure
//  . ouverture/fermeture du menu de recherche en mode reduit
//  . ouverture/fermeture du menu de navigation en mode reduit
//  . navigation clavier dans le menu de navigation
//  . convertion des selecteurs <select> presents dans l'entete en composant JavaScript
//  . convertion de la liste des sites "other websites" en select JS pour le mode reduit
//  . affichage accordeon du pied de page en mode reduit
//  . gestion de l'affichage sur les menus lateraux (effet parallaxe + mode accordeon)

import { Select } from './select.js';
import { Accordion } from './accordion.js';
import { FollowingScroll } from './follow-scroll.js';
import { TableOfContent } from './table-of-content.js';

/* ============================================================================================================================================================================ */
/* === INITIALISATION DES FONCTIONNALITES DU SITE ============================================================================================================================= */
/* ============================================================================================================================================================================ */

const Data = {};
Data.initscript = true; // Indicateur d'ajout d'evenement
Data.toolbar = null; // Donnees utiles pour l'affichage parallaxe de l'entete du site
Data.language = null; // Donnees utiles pour la convertion du selecteur linguistique
Data.otherwebsites = null; // Donnees utiles pour la convertion du selecteur des autres sites
Data.navigation = null; // Donnees utiles pour l'ouverture/fermeture du menu de navigation
Data.search = null; // Donnees utiles pour l'ouverture/fermeture du menu de recherche
Data.footer = null; // Donnees utiles pour l'ouverture/fermeture du pied de page
Data.tableofcontent = null; // Donnees utiles pour l'ouverture/fermeture du menu sommaire (optionnel)
Data.accordions = null; // Donnees utiles pour la convertion des boites en accordeon
Data.timer = 0; // Interval pour les mouvements de scroll

// - VALIDATION - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// VALIDATION : controle de la balise de donnees passe en parametre
function check(d) {
  if (typeof d === 'object') {
    if (d != null) {
      return d.tagName != null;
    }

    return false;
  }

  return false;
}

// - INITIALISATION - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

function init(e) {
  let i;
  let d;
  let t;
  let r;
  let m;
  let f;
  let o;
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Activation des traitements JavaScript
  document.body.dataset.jsactive = true;
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Identification du type de support utilise pour la lecture ecran
  // Liste des appareils mobiles reconnus
  m = [
    'android',
    'avantgo',
    'blackberry',
    'blazer',
    'eudoraweb',
    'handspring',
    'htc',
    'iphone',
    'ipad',
    'ipod',
    'kyocera',
    'lg',
    'motorola',
    'netfront',
    'nokia',
    'palm',
    'playstation portable',
    'samsung',
    'smartphone',
    'sonyericsson',
    'symbian',
    'wap',
    'windows ce',
  ];
  i = ['iphone', 'ipad', 'ipod'];
  // Identification du navigateur et de l'OS
  d = navigator.userAgent;
  d = String(d).toLowerCase();
  // Recherche du navigateur dans la liste
  t = m.length - 1;
  for (t = t; t >= 0; t--) {
    if (d.includes(m[t])) {
      document.body.dataset.ismobile = true;
      break;
    }
  }

  if (document.body.dataset.ismobile) {
    t = i.length - 1;
    for (t = t; t >= 0; t--) {
      if (d.includes(i[t])) {
        document.body.dataset.ios = true;
        break;
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  let wailinks = null;
  const site = document.querySelector('#website');
  const content = document.querySelector('#website-body');
  if (check(site)) {
    // MENU RACCOURCIS : Recherche du conteneur
    t = document.querySelector('#waimenu');
    if (check(t)) {
      wailinks = t.querySelectorAll('a');
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // BARRE D'OUTILS : Recherche des balises (superieure et inferieure)
    d = { top: null, bottom: null };
    t = site.querySelectorAll('.toolbar');
    i = t.length - 1;
    for (i = i; i >= 0; i--) {
      if (t[i].classList.contains('toolbar_top')) d.top = t[i];
      else if (t[i].classList.contains('toolbar_bottom')) d.bottom = t[i];
      if (d.top != null && d.bottom != null) break;
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // BARRE D'OUTILS : Traitement de la barre superieure (menu linguistique)
    if (Data.language == null && d.top != null) {
      // Convertion du menu linguistique original
      t = document.querySelector('#language-select');
      if (check(t)) {
        o = Select.convert(t, { autoexecute: true });
        if (o.converted) Data.language = o.data;
      }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // BARRE D'OUTILS : Traitement de la barre superieure (autres planetes)
    if (Data.otherwebsites == null && d.top != null) {
      // Duplication du menu "autres sites" par un selecteur JS pour les petites resolutions
      t = document.querySelector('#otherwebsites');
      if (check(t)) {
        r = { parent: t, list: null, links: null, placeholder: null };
        t = r.parent.querySelectorAll('ol');
        if (t.length > 0) t = t[0];
        if (check(t)) {
          // Fonction temporaire du traitement des liens
          f = function (f_id, f_parent, f_placeholder, f_links) {
            let f_m;
            let f_i;
            let f_o;
            // Creation du selecteur
            const f_t = document.createElement('select');
            f_t.setAttribute('id', f_id + '-selectmobile');
            f_t.setAttribute('aria-labelledby', f_id + '-label');
            f_t.classList.add('ep_field');
            // Nettoyage du texte du placeholder
            f_i = f_placeholder.querySelectorAll('.ep_name')[0];
            f_m = f_i.querySelectorAll('span');
            if (f_m.length > 0) {
              f_i = '';
              for (f_o = 0; f_o < f_m.length; f_o++) {
                if (!f_m[f_o].classList.contains('ep_hidden'))
                  f_i += f_m[f_o].innerHTML;
              }
            } else {
              f_i = f_i.innerHTML;
            }

            // Creation du placeholder
            f_o = document.createElement('option');
            f_o.setAttribute('value', 'placeholder');
            f_o.setAttribute('selected', 'selected');
            f_o.setAttribute('disabled', 'disabled');
            f_o.dataset.target = '_self';
            f_o.append(document.createTextNode(f_i));
            f_t.append(f_o);
            // Integration des valeurs
            f_m = f_links.length;
            for (f_i = 0; f_i < f_m; f_i++) {
              f_o = document.createElement('option');
              f_o.setAttribute('value', f_links[f_i].href);
              f_o.dataset.target =
                f_links[f_i].getAttribute('target') == '_blank'
                  ? '_blank'
                  : '_self';
              if (f_links[f_i].getAttribute('title'))
                f_o.setAttribute('title', f_links[f_i].getAttribute('title'));
              f_o.append(
                document.createTextNode(
                  f_links[f_i].querySelectorAll('.ep_name')[0].innerHTML
                )
              );
              f_t.append(f_o);
            }

            // Creation des balises de contenu
            f_i = document.createElement('div');
            f_i.classList.add('ep_select');
            f_i.append(f_t);
            f_m = document.createElement('form');
            f_m.setAttribute('id', f_id + '-formmobile');
            f_m.setAttribute('method', 'get');
            f_m.setAttribute('action', '');
            f_m.classList.add('epjs_mobile');
            f_m.append(f_i);
            // Integration du selecteur
            f_parent.append(f_m);
            // Convertion du selecteur
            f_i = Select.convert(f_t, {
              autoexecute: true,
              cb_selection(selectdata) {
                selectdata.form.action = selectdata.original.value;
                selectdata.form.target =
                  selectdata.options[
                    selectdata.original.selectedIndex
                  ].original.dataset.target;
                Select.updateselection(selectdata, 'placeholder', false);
                if (selectdata.form.target == '_blank')
                  window.open(selectdata.form.action);
                else location.href = selectdata.form.action;
                return false;
              },
            });
            // Autorisation d'acces pour la version mobile
            if (f_i.converted) {
              f_parent.dataset.converted = true;
              return f_i.data.index;
            }

            return null;
          };

          // Recherche des liens a integrer
          r.list = t;
          t = r.list.querySelectorAll('.ep_item');
          if (t.length > 0) {
            r.links = [];
            m = t.length;
            for (i = 0; i < m; i++) {
              o = t[i].querySelectorAll('a');
              if (o[0].classList.length === 0) {
                r.links.push(o[0]);
              }
            }
          }

          // Recherche du libelle pour le placeholder
          t = document.querySelector('#otherwebsites-access');
          if (check(t)) r.placeholder = t;
          // Verification de l'acces aux differentes donnees
          if (r.parent != null && r.links != null && r.placeholder != null) {
            // Initialisation de l'objet de donnees
            Data.otherwebsites = { main: null, more: null };
            // Traitement du selecteur principal utilise en petite resolution
            Data.otherwebsites.main = f(
              'otherwebsites',
              r.parent,
              r.placeholder,
              r.links
            );
            // Traitement du second selecteur pour les grandes resolutions uniquement
            t = document.querySelector('#more-otherwebsites');
            if (check(t)) {
              r = { parent: t, list: null, links: null, placeholder: null };
              // Recherche du placeholder
              t = document.querySelector('#more-otherwebsites-access');
              if (check(t)) r.placeholder = t;
              // Recherche des liens
              t = r.parent.querySelectorAll('ol');
              if (t.length > 0) t = t[0];
              if (check(t)) {
                t = t.querySelectorAll('a');
                if (t.length > 0) r.links = t;
              }

              // Traitement du selecteur
              if (
                r.parent != null &&
                r.links != null &&
                r.placeholder != null
              ) {
                Data.otherwebsites.more = f(
                  'more-otherwebsites',
                  r.parent,
                  r.placeholder,
                  r.links
                );
              }

              // Verification de la convertion des listes
              if (
                Data.otherwebsites.main == null &&
                Data.otherwebsites.more == null
              ) {
                Data.otherwebsites = null;
              } else {
                if (Data.otherwebsites.main != null)
                  setTimeout(function () {
                    Select.resize(Data.otherwebsites.main);
                  }, 50);
                if (Data.otherwebsites.more != null)
                  setTimeout(function () {
                    Select.resize(Data.otherwebsites.more);
                  }, 50);
              }
            }
          }
        }
      }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // BARRE D'OUTILS : Traitement de la barre inferieure (effet parallaxe)

    // I'm commmenting this out to avoid the instanciation of Data.toolbar in this function (window.init).
    // The goal is to prevent bogus interactions with the refactored header
    // tmk [2022-07-08]

    // if (Data.toolbar == null && d.bottom != null) {
    //   // Recherche de la balise 'header' situee au dessus de la barre d'outil (balise utile pour le calcul du scroll minimum avant d'activer le parallaxe)
    //   t = document
    //     .querySelector('#website-header')
    //     .querySelectorAll('.ep_header')[0];
    //   // Effet Parallaxe : Initialisation de l'objet de donnees
    //   Data.toolbar = {};
    //   Data.toolbar.bar = d.bottom; // Reference de la balise de la barre
    //   Data.toolbar.scrollsize = 0; // Valeur de la hauteur de la scrollbar
    //   Data.toolbar.page = document.body; // Cible pour l'ajout du parametre de traitement
    //   Data.toolbar.header = t; // Reference de la hauteur minimum pour l'activation du parallaxe
    // }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // BARRE D'OUTILS : Traitement de la barre inferieure (moteur de recherche)
    if (Data.search == null && Data.toolbar != null) {
      // Traitement du moteur de recherche
      m = document.querySelector('#search');
      if (check(m)) {
        t = document.querySelector('#search-field');
        d = m.querySelectorAll('.ep_send');
        d = d.length > 0 ? d[0] : null;
        r = m.querySelectorAll('.ep_title');
        r = r.length > 0 ? r[0] : null;
        if (check(t) && check(d) && check(r)) {
          // Initialisation de l'objet de donnees
          Data.search = {};
          Data.search.reducemode = false; // Definition du mode d'affichage du menu : deploye (false) ou reduit (true)
          Data.search.scanmode = 0; // Indicateur de scan de l'etat du champ lorsque le focus est assigne au champ
          Data.search.timer = 0; // Timer pour l'attente avant la cloture du menu
          Data.search.parent = m.parentNode; // Reference au menu
          Data.search.form = m; // Reference au formulaire
          Data.search.field = t; // Reference au champ texte
          Data.search.button = d; // Reference au bouton d'execution du formulaire
          Data.search.access = r; // Reference au bouton d'ouverture/fermeture
          Data.search.animate = Data.search.field.parentNode.parentNode; // Reference du conteneur a animer
          // Suppression des liens d'ouverture/fermeture indesirables
          t = Data.search.parent.querySelectorAll('.ep_menu-access');
          if (t.length > 1) t[1].remove();
          if (t.length > 0) t[0].remove();
          // Activation des mises a jour visuelles
          Data.search.parent.dataset.converted = true;
          // Traitement des balises pour l'ouverture et la fermeture du menu
          Data.search.access.setAttribute('tabindex', 0);
          Data.search.access.setAttribute('aria-expanded', false);
          Data.search.access.setAttribute(
            'aria-controls',
            'search-contentform'
          );
          // Data.search.animate.setAttribute('aria-hidden', true);
          Data.search.animate.setAttribute('id', 'search-contentform');
          // Ajout des evenements pour l'ouverture et la fermeture du menu
          Data.search.access.addEventListener('click', search_display, false);
          Data.search.animate.addEventListener(
            'transitionend',
            search_focus,
            false
          );
          Data.search.button.addEventListener('focus', search_button, false);
          Data.search.button.addEventListener('blur', search_button, false);
          // Ajout des evenements pour l'activation ou non du formulaire selon le contenu du champ texte
          Data.search.form.addEventListener('submit', search_submit, false);
          // Ajout de l'evenement pour la navigation clavier
          Data.search.form.addEventListener('keydown', search_key, true);
          Data.search.form.addEventListener('keyup', search_validation, true);
          Data.search.field.addEventListener('focus', search_scan, false);
          Data.search.field.addEventListener('blur', search_scan, false);
          // Ajout des evenements pour la fermeture du menu via clic hors de la zone
          t = Data.search.parent.querySelectorAll('*');
          r = t.length - 1;
          Data.search.parent.classList.add('epjs_search');
          for (r = r; r >= 0; r--) {
            t[r].classList.add('epjs_search');
          }

          document.addEventListener('touchstart', search_check, false);
          document.addEventListener('click', search_check, false);
          // Verification de l'acces au formulaire
          search_validation();
        }
      }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // BARRE D'OUTILS : Traitement de la barre inferieure (menu de navigation)
    if (Data.navigation == null && Data.toolbar != null) {
      d = {
        parent: null,
        display: { parent: null, content: null, open: null, close: null },
        links: null,
      };
      // Recherche des balises
      t = document.querySelector('#navigation');
      if (check(t)) {
        d.parent = t;
        // Recherche des liens d'ouverture/fermeture du menu
        t = d.parent.querySelectorAll('.ep_menu-access');
        i = t.length - 1;
        for (i = i; i >= 0; i--) {
          if (t[i].classList.contains('ep_openaccess')) d.display.open = t[i];
          else if (t[i].classList.contains('ep_closeaccess'))
            d.display.close = t[i];
          if (d.display.open != null && d.display.close != null) break;
        }

        // Recherche du conteneur principal a animer
        t = d.parent.querySelectorAll('.ep_menu-container');
        if (t.length > 0) {
          d.display.parent = t[0];
          // Recherche du conteneur de lien
          t = d.display.parent.childNodes.length;
          for (i = 0; i < t; i++) {
            if (
              check(d.display.parent.childNodes[i]) &&
              d.display.parent.childNodes[i].tagName.toLowerCase() == 'div'
            ) {
              d.display.content = d.display.parent.childNodes[i];
              break;
            }
          }

          // Recherche de la liste des liens du menu (par niveau)
          if (d.display.content != null) {
            t = d.display.content.querySelectorAll('.ep_menu');
            if (t.length > 0) {
              t = t[0];
              d.links = {
                parent: t,
                list: { all: [], level0: [], level1: [], level2: [] },
                ismenu: { level0: [], level1: [], level2: [] },
              };
              // Creation de la fonction de recuperation de noeud selon la profondeur
              f = function (f_parentnode, f_parentlevel, f_parentindex) {
                let f_i;
                let f_m;
                let f_t = f_parentnode.querySelectorAll('ol');
                if (f_t.length > 0) {
                  f_t = f_t[0];
                  f_m = f_t.childNodes.length;
                  for (f_i = 0; f_i < f_m; f_i++) {
                    if (check(f_t.childNodes[f_i])) {
                      if (
                        f_t.childNodes[f_i].classList.contains('ep_item') &&
                        f_t.childNodes[f_i].classList.contains('ep_directlink')
                      ) {
                        d.links.list.all.push({
                          ismenu: false,
                          tag: f_t.childNodes[f_i],
                          level: f_parentlevel,
                          parent: f_parentindex,
                          children: [],
                          order: d.links.list['level' + f_parentlevel].length,
                        });
                        d.links.list['level' + f_parentlevel].push(
                          d.links.list.all.length - 1
                        );
                        if (f_parentindex != null)
                          d.links.list.all[f_parentindex].children.push(
                            d.links.list.all.length - 1
                          );
                      } else if (
                        f_t.childNodes[f_i].classList.contains('ep_item') &&
                        f_t.childNodes[f_i].classList.contains('ep_hasmenu')
                      ) {
                        d.links.list.all.push({
                          ismenu: true,
                          tag: f_t.childNodes[f_i],
                          level: f_parentlevel,
                          parent: f_parentindex,
                          children: [],
                          order: d.links.list['level' + f_parentlevel].length,
                        });
                        d.links.ismenu['level' + f_parentlevel].push(
                          d.links.list.all.length - 1
                        );
                        d.links.list['level' + f_parentlevel].push(
                          d.links.list.all.length - 1
                        );
                        if (f_parentindex != null)
                          d.links.list.all[f_parentindex].children.push(
                            d.links.list.all.length - 1
                          );
                      }
                    }
                  }
                }
              };

              // Identification des balises de liens du premier niveau selon les criteres
              f(t, 0, null);
              // Identification des balises de liens du second niveau selon les criteres
              if (d.links.ismenu.level0.length > 0) {
                m = d.links.ismenu.level0.length;
                for (i = 0; i < m; i++) {
                  t = d.links.list.all[d.links.ismenu.level0[i]];
                  f(t.tag, 1, d.links.ismenu.level0[i]);
                }
              }

              // Identification des balises de liens du troisieme niveau selon les criteres
              if (d.links.ismenu.level1.length > 0) {
                m = d.links.ismenu.level1.length;
                for (i = 0; i < m; i++) {
                  t = d.links.list.all[d.links.ismenu.level1[i]];
                  f(t.tag, 2, d.links.ismenu.level1[i]);
                }
              }

              // Verification de la quantite de liens obtenus
              if (d.links.list.all.length <= 0) d.links = null;
            }
          }
        }
      }

      // Verification de l'acces aux balises
      if (
        d.parent != null &&
        d.display.parent != null &&
        d.display.content != null &&
        d.display.open != null &&
        d.display.close != null &&
        d.links != null
      ) {
        // Recherche du tag "categorie" optionnel a afficher en parallaxe
        // Initialisation de l'objet de donnees
        Data.navigation = {};
        // General
        Data.navigation.menu = d.parent; // Reference du conteneur principal du menu
        Data.navigation.page = document.querySelector('#website'); // Reference de la page pour le deplacement du scroll dans la navigation parallaxe
        Data.navigation.headerpage = Data.toolbar.header; // Reference de l'entete de la page pour le deplacement lors de la suppression de la scrollbar
        Data.navigation.headerbar = Data.navigation.menu.parentNode; // Reference du conteneur de la barre de navigation pour l'alignement de l'effet parallaxe lors de la suppression de la scrollbar
        Data.navigation.bodypage = document.querySelector('#website-body'); // Reference du corps de la page pour le deplacement lors de la suppression de la scrollbar
        Data.navigation.footerpage = document.querySelector('#website-footer'); // Reference du pied de la page pour le deplacement lors de la suppression de la scrollbar
        // Donnees utiles pour la version reduite
        Data.navigation.anim = d.display.content; // Reference du conteneur a animer
        Data.navigation.menuaccess = null; // Reference du bouton d'ouverture et fermeture du menu reduit
        Data.navigation.filter = null; // Reference du selecteur du filtre du menu
        Data.navigation.focus = []; // Reference des balises a cibler lors de l'assignation du focus
        Data.navigation.scrollfollow = false; // Indicateur du suivi du scroll manuel sur le menu
        Data.navigation.cursorY = 0; // Position initial du curseur lors du debut du scroll
        Data.navigation.scrollY = 0; // Position initial du scroll du menu lors du debut du deplacement
        Data.navigation.scrollratio = 0; // Ratio entre la hauteur du menu et la hauteur de la page
        Data.navigation.access = null; // Reference du bouton ayant appele pour la navigation clavier
        // Donnees utiles pour la version deployee
        Data.navigation.bar = d.links.parent; // Reference du conteneur des liens
        Data.navigation.links = null; // Liste des index et balises de tous les elements du menu
        Data.navigation.ismenu = null; // Reference des index des liens de type "menu"
        Data.navigation.selectedlink = null; // Index du lien actuellement accessible via clavier (focus)
        // Ajout du parametre indiquant le traitement du menu
        Data.navigation.menu.dataset.converted = true;
        // Ajout de l'action d'ecoute pour les clics hors du menu reduit et/ou deploye
        document.addEventListener('click', navigation_checkclick, false);
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - GENERAL
        // Convertion du selecteur de filtre (si present)
        t = Data.navigation.menu.querySelectorAll('.ep_menu-filter');
        if (t.length > 0) {
          t = t[0];
          // Recherche des balises de selection
          t = t.querySelectorAll('select');
          if (t.length > 0) {
            t = Select.convert(t[0], {
              autoexecute: true,
              openbykeyboard: false,
            });
            if (t.converted) {
              Data.navigation.filter = t.data;
              // Redimensionnement du selecteur pour Firefox
              navigation_select();
            }
          }
        }

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - AFFICHAGE DU MENU PRINCIPAL (version reduite)
        // Suppression des boutons d'ouverture et de fermeture du menu inutile pour la version JS
        t = d.display.open.querySelectorAll('a');
        if (t.length > 0) t[0].parentNode.remove();
        t = d.display.close.querySelectorAll('a');
        if (t.length > 0) t[0].parentNode.remove();
        // Ajout de la classe CSS utile pour la gestion d'ouverture/fermeture du menu
        t = Data.navigation.menu.querySelectorAll('*');
        i = t.length - 1;
        for (i = i; i >= 0; i--) {
          t[i].classList.add('epjs_navigation');
        }

        // Sauvegarde du bouton d'acces au menu
        Data.navigation.menuaccess =
          d.display.open.querySelectorAll('.ep_button')[0].parentNode;
        Data.navigation.menuaccess.setAttribute('tabindex', '0');
        // Ajout des actions sur le bouton d'acces pour l'ouverture du menu reduit
        Data.navigation.menuaccess.addEventListener(
          'click',
          navigation_switch,
          false
        );
        Data.navigation.menuaccess.addEventListener(
          'keydown',
          navigation_access,
          false
        );
        // Recherche du premier element cliquable du menu pour la gestion du focus entrant et sortant en mode reduit
        t = Data.navigation.anim.querySelectorAll('.ep_category');
        if (t.length > 0) {
          t = t[0];
          if (t.querySelectorAll('a').length > 0)
            Data.navigation.focus[0] = {
              type: 'category',
              tag: t.querySelectorAll('a')[0],
            };
        }

        if (Data.navigation.focus[0] == null)
          Data.navigation.focus[0] = { type: 'menu', tag: null };
        else
          Data.navigation.focus[0].tag.addEventListener(
            'keydown',
            navigation_keyboard,
            false
          );
        if (Data.navigation.filter != null) {
          Data.navigation.focus[1] = {
            type: 'filter',
            tag: document.getElementById(Data.navigation.filter.button.text.id),
          };
        }

        if (Data.navigation.focus[1] == null)
          Data.navigation.focus[1] = { type: 'menu', tag: null };
        else
          Data.navigation.focus[1].tag.addEventListener(
            'keydown',
            navigation_keyboard,
            false
          );
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - AFFICHAGE DU MENU PRINCIPAL (version deployee)
        // Initialisation de l'objet de donnees temporaires pour le traitement des liens du "treeview"
        o = {};
        o.links = { all: [], level0: [], level1: [], level2: [] }; // Reference des index et balises de tous les elements du menu
        o.ismenu = { all: [], level0: [], level1: [], level2: [] }; // Reference des index des liens de type "menu"
        // Traitement de tous les liens obtenus
        m = d.links.list.all.length;
        for (i = 0; i < m; i++) {
          t = d.links.list.all[i];
          // Recuperation du lien principal (ouverture du menu ou acces au site)
          r = t.tag.querySelectorAll('.ep_button');
          t.button = r.length > 0 ? r[0] : null;
          if (t.button != null) {
            r = t.ismenu
              ? t.button.querySelectorAll('span')
              : t.button.querySelectorAll('a');
            t.button = r.length > 0 ? r[0] : null;
          }

          // Stockage des informations
          o.links.all.push({
            index: o.links.all.length, // Index du lien dans la liste
            block: t.tag, // Reference a la balise du lien
            ismenu: t.ismenu, // Type de element du menu (lien direct ou sous-menu)
            level: t.level, // Niveau de profondeur du lien dans le menu de navigation
            parent: t.parent, // Index de l'element parent ("null" s'il ne fait pas partie d'un sous-menu)
            children: t.children, // Index des elements enfant
            button: t.button, // Reference a la balise servant de lien ou d'ouverture/fermeture du menu
            animtag: null, // Reference a la balise d'animation d'ouverture/fermeture de contenu
            list: null, // Reference a la balise de contenu de liens
            order: t.order, // Position de la balise dans la liste par niveau
          });
          // Sauvegarde de l'element par niveau
          o.links['level' + t.level].push(i);
          // Traitement des liens de type "menu"
          if (t.ismenu) {
            // Stockage de l'information
            o.ismenu.all.push(i);
            o.ismenu['level' + t.level].push(i);
          }
        }

        d = false;
        // Traitement des liens "menu" par niveau
        if (o.ismenu.all.length > 0) {
          d = true;
          m = o.ismenu.all.length;
          for (i = 0; i < m; i++) {
            t = o.links.all[o.ismenu.all[i]];
            // Recherche des balises utiles pour l'affichage en accordeon
            r = t.block.querySelectorAll('ol');
            if (r.length > 0) {
              t.list = r[0];
              t.animtag = r[0].parentNode;
            }

            // Correction des liens de premier niveau
            if (t.level == 0) {
              // Recherche des balises ouvrantes et fermantes du menu a supprimer (inutile pour la gestion en "treeview")
              r = t.block.querySelectorAll('.ep_closeaccess');
              if (r.length > 0) r[0].remove();
              r = t.block.querySelectorAll('.ep_openaccess');
              if (r.length > 0) {
                r = r[0];
                r.classList.remove('ep_openaccess');
                r = r.querySelectorAll('a');
                if (r.length > 0) {
                  r[0].parentNode.setAttribute('id', r[0].id);
                  r[0].remove();
                }
              }
            }

            // Verification de l'acces aux balises
            if (t.button == null || t.animtag == null || t.list == null) {
              d = false;
              break;
            }
          }
        }

        // Traitement du menu
        if (o.links.all.length > 0) {
          // Sauvegarde des valeurs obtenues
          Data.navigation.links = o.links;
          Data.navigation.ismenu = o.ismenu;
          // Traitement des elements du menu selon les niveaux
          m = Data.navigation.links.all.length;
          for (let i = 0; i < m; i++) {
            d = Data.navigation.links.all[i];
            // Ajout des references a la balise
            d.block.dataset.navindex = i;
            d.button.dataset.navindex = i;
            d.button.parentNode.dataset.navindex = i;
            // Ajout des parametres d'accessibilite
            d.block.removeAttribute('tabindex');
            d.button.setAttribute('tabindex', '-1');
            // Gestion du mode "menu"
            if (d.ismenu) {
              // Ajout de l'identification du bloc
              d.animtag.dataset.navindex = i;
              d.list.dataset.navindex = i;
              // Ajout des parametres de fermeture
              d.block.setAttribute('aria-expanded', false);
              // Ajout des actions d'ouverture/fermeture
              d.button.addEventListener('click', navigation_expand, false);
              d.button.addEventListener('touchend', navigation_expand, false);
              d.animtag.addEventListener(
                'transitionend',
                navigation_expandend,
                false
              );
              if (d.level == 1) {
                d.list.addEventListener('mouseover', navigation_mouse, false);
                d.list.addEventListener('mouseout', navigation_mouse, false);
              }
            }

            // Gestion des actions de survol
            if (d.level <= 1) {
              d.button.parentNode.addEventListener(
                'mouseover',
                navigation_mouse,
                false
              );
              d.button.addEventListener('mouseout', navigation_mouse, false);
            }

            // Gestion des actions clavier
            d.button.addEventListener('keydown', navigation_keyboard, false);
          }

          // Autorisation d'acces au premier lien du menu (navigation clavier)
          Data.navigation.links.all[0].button.setAttribute('tabindex', '0');
          Data.navigation.selectedlink = Data.navigation.links.all[0];
          // Ajout de la fermeture du menu au survol de la page
          document.addEventListener('mouseover', navigation_checkover, false);
          document.addEventListener('touchstart', navigation_checkover, false);
          // Ajout du marqueur sur les balises du menu
          i = Data.navigation.links.level0.length - 1;
          for (i = i; i >= 0; i--) {
            // Identification du lien de premier niveau
            d =
              Data.navigation.links.all[Data.navigation.links.level0[i]].block;
            // Ajout du marqueur
            d.classList.add('epjs_navigationlinks');
            // Ajout du marqueur sur les balises interieures
            d = d.querySelectorAll('*');
            m = d.length - 1;
            for (m = m; m >= 0; m--) {
              d[m].classList.add('epjs_navigationlinks');
            }
          }
        }

        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - DEFINITION DU MODE D'AFFICHAGE SELON L'ECRAN
        // Definition du mode d'affichage du menu
        navigation_mode(Data.navigation.menuaccess.offsetWidth > 1);
      }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // PIED DE PAGE : Recherche des balises
    d = { footer: null, specific: null, general: null };
    t = document.querySelector('#website-footer');
    if (Data.footer == null && check(t)) {
      d.footer = t;
      // Fonction temporaire du traitement des liens
      f = function (f_classparent) {
        let f_t;
        let f_i;
        // Initialisation de l'objet de donnees
        const f_d = {
          parent: null,
          title: null,
          ticon: null,
          open: null,
          close: null,
          content: null,
          list: null,
        };
        // Recherche du bloc parent
        const f_p = d.footer.getElementsByClassName(f_classparent);
        if (f_p.length > 0) {
          f_d.parent = f_p[0];
          // Recherche des liens d'ouverture/fermeture sans JS
          f_t = f_d.parent.querySelectorAll('.ep_menu-access');
          f_i = f_t.length - 1;
          for (f_i = f_i; f_i >= 0; f_i--) {
            if (f_t[f_i].classList.contains('ep_openaccess'))
              f_d.open = f_t[f_i];
            else if (f_t[f_i].classList.contains('ep_closeaccess'))
              f_d.close = f_t[f_i];
            if (f_d.open != null && f_d.close != null) break;
          }

          // Recherche du titre du bloc pour la convertion en lien d'ouverture/fermeture via JS
          f_t = f_d.parent.querySelectorAll('.ep_title');
          if (f_t.length > 0) {
            f_d.title = f_t[0];
          }

          // Recherche du bloc de contenu a deployer
          f_t = f_d.parent.querySelectorAll('.ep_list');
          if (f_t.length > 0) {
            f_d.content = f_t[0];
            f_t = f_d.content.classList.contains('ep-layout_category')
              ? f_d.content.querySelectorAll('div')
              : f_d.content.querySelectorAll('ul');
            if (f_t.length > 0) {
              f_d.list = f_t[0];
            }
          }
        }

        // Envoi du resultat
        return check(f_d.parent) &&
          check(f_d.title) &&
          check(f_d.open) &&
          check(f_d.close) &&
          check(f_d.content) &&
          check(f_d.list)
          ? f_d
          : null;
      };

      // Analyse des liens specifiques au site
      d.specific = f('ep_websitelinks');
      // Analyse des liens generiques aux sites Europarl
      d.general = f('ep_europarllinks');
      // Verification des donnees obtenues
      if (d.general != null) {
        Data.footer = {};
        Data.footer.tabparent = d.general.parent.parentNode;
        Data.footer.specific = null;
        Data.footer.general = null;
        // Traitement de la liste de liens generiques
        // Initialisation de l'objet de donnees
        t = Accordion.convert(
          d.general.parent, // Reference du conteneur global
          d.general.title, // Reference du nouveau bouton d'ouverture [+]/[-] (conteneur global)
          d.general.content, // Reference du conteneur a animer
          d.general.list, // Reference de la liste generale des liens
          {
            openbtn: d.general.open, // Reference de la balise du bouton d'ouverture d'origine a supprimer
            closebtn: d.general.close, // Reference de la balise du bouton de fermeture d'origine a supprimer
            clickonicon: true, // Autorisation de la creation d'un bouton icone receptionant l'action de clic
          }
        );
        if (t.converted) Data.footer.general = t.data;
        // Traitement de la liste de liens specifiques
        if (Data.footer.general != null && d.specific != null) {
          // Initialisation de l'objet de donnees
          t = Accordion.convert(
            d.specific.parent, // Reference du conteneur global
            d.specific.title, // Reference du nouveau bouton d'ouverture [+]/[-] (conteneur global)
            d.specific.content, // Reference du conteneur a animer
            d.specific.list, // Reference de la liste generale des liens
            {
              openbtn: d.specific.open, // Reference de la balise du bouton d'ouverture d'origine a supprimer
              closebtn: d.specific.close, // Reference de la balise du bouton de fermeture d'origine a supprimer
              clickonicon: true, // Autorisation de la creation d'un bouton icone receptionant l'action de clic
            }
          );
          if (t.converted) {
            Data.footer.specific = t.data;
            // Ajout des dependances entre les deux blocs
            Accordion.setdependency(Data.footer.specific.index, [
              Data.footer.specific.index,
              Data.footer.general.index,
            ]);
            Accordion.setdependency(Data.footer.general.index, [
              Data.footer.specific.index,
              Data.footer.general.index,
            ]);
          }
        } else {
          Data.footer = null;
        }
      }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // MENU SOMMAIRE : Recherche des balises
    if (check(content)) {
      t = site.querySelectorAll('.ep-layout_tableofcontent');
      if (t.length > 0) {
        i = t.length - 1;
        for (i = i; i >= 0; i--) {
          TableOfContent.convert(t[i], null);
        }
      }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // BLOC DE SUIVI : Recherche des balises devant suivre le scroll de la page
    if (check(content)) {
      t = site.querySelectorAll('.ep-layout_followingscroll');
      if (t.length > 0) {
        i = t.length - 1;
        for (i = i; i >= 0; i--) {
          FollowingScroll.convert(t[i], null);
        }
      }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // SELECTEURS : Recherche des balises <select> a convertir en JS
    if (check(content)) {
      t = site.querySelectorAll('select');
      if (t.length > 0) {
        i = t.length - 1;
        for (i = i; i >= 0; i--) {
          Select.convert(t[i], {
            autoexecute: Boolean(t[i].dataset.autoexecute),
          });
        }
      }

      t = site.querySelectorAll('.ep_fakeselect');
      if (t.length > 0) {
        i = t.length - 1;
        for (i = i; i >= 0; i--) {
          Select.convert(t[i]);
        }
      }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // BLOC ACCORDEON : Recherche des balises devant se convertir en accordeon dans au moins un breakpoint
    if (Data.accordions == null) Data.accordions = [];
    if (check(content)) {
      t = site.querySelectorAll('.ep-layout_accordion');
      if (t.length > 0) {
        // Lancement de la convertion des listes d'accordeon
        i = t.length - 1;
        for (i = i; i >= 0; i--) {
          if (!t[i].dataset.converted) {
            d = { parent: t[i], index: null, items: [], initopen: -1 };
            // parent, newbutton, animatedcontent, realcontent, params
            // Recherche des molecules "enfant" a convertir
            f = {
              index: 0,
              max: 0,
              list: d.parent.querySelectorAll('.ep_gridcolumn'),
            };
            if (f.list.length > 0) {
              f.index = 0;
              f.max = f.list.length;
              for (f.index = 0; f.index < f.max; f.index++) {
                // Acces a la molecule a convertir -> verification de la composition necessaire
                if (
                  f.list[f.index].className.match('ep-m_') &&
                  !f.list[f.index].dataset.accordion
                ) {
                  r = {
                    molecule: f.list[f.index],
                    header: null, // Entete de la molecule servant de bouton d'ouverture/fermeture
                    body: null, // Corps de la molecule a animer
                    content: null, // Contenu reel de la molecule servant au calcul de la hauteur
                  };
                  // Recherche du titre servant de support au bouton d'ouverture/fermeture
                  r.header = r.molecule.querySelectorAll(
                    '.ep-layout_accordiontitle'
                  );
                  r.header = r.header.length > 0 ? r.header[0] : null;
                  // Recherche du contenu reel permattant le calcul de hauteur de l'animation
                  r.content = r.molecule.querySelectorAll(
                    '.ep-layout_accordioncontent'
                  );
                  r.content = r.content.length > 0 ? r.content[0] : null;
                  // Recherche du contenu a animer
                  if (r.content != null && r.header != null) {
                    r.body = r.content.parentNode;
                  }

                  // Activation de la fonctionnalite
                  if (
                    r.content != null &&
                    r.content != null &&
                    r.body != null
                  ) {
                    r = Accordion.convert(
                      r.molecule,
                      r.header,
                      r.body,
                      r.content
                    );
                    if (r.converted) {
                      // Sauvegarde de la reference
                      d.index = r.data.index;
                      d.items.push(r.data.index);
                      // Verification de la demande d'ouverture a l'initialisation
                      if (f.list[f.index].dataset.accordionopened)
                        d.initopen = r.data.index;
                    }
                  }
                }
              }
            }

            // Verification de l'autorisation de convertion d'affichage
            if (d.items.length > 0) {
              // Autorisation de l'application des effets visuels
              d.parent.dataset.converted = 'true';
              // Ajout de la dependance entre les conteneurs de meme liste
              f = { index: 0, max: d.items.length };
              for (f.index = 0; f.index < f.max; f.index++) {
                Accordion.setdependency(d.items[f.index], d.items);
              }

              // Ouverture de l'element demande (si precise)
              if (d.initopen >= 0) Accordion.open(d.initopen, true, true, true);
              // Indexation de la liste
              Data.accordions.push(d);
            }
          }
        }
      }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Ajout des ecouteurs d'evenements
    if (
      Data.initscript &&
      (Data.navigation != null || Data.search != null || Data.footer != null)
    ) {
      Data.initscript = false;
      // Ajout des evenements de mises a jour
      // document.addEventListener('scroll', scroll, false);
      window.addEventListener('resize', refresh, false);
      // Initialisation des scripts
      refresh();
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Blocage du double traitement
    if (e.type == 'load') {
      // Mise a jour du contenu
      refresh();
      // Traitement des boutons de raccourcis correspondant aux widgets afin de les adapter a leur cible suite aux adaptations JS
      if (wailinks != null && (Data.language !== null || Data.search != null)) {
        i = wailinks.length - 1;
        for (i = i; i >= 0; i--) {
          if (
            Data.language !== null &&
            wailinks[i].href.match('#language-select')
          ) {
            const languagetarget = Data.language.button.text.id;
            wailinks[i].addEventListener('click', function () {
              document.getElementById(languagetarget).focus();
              return false;
            });
          } else if (
            Data.search !== null &&
            wailinks[i].href.match('#search-field')
          ) {
            wailinks[i].classList.add('epjs_search');
            wailinks[i].addEventListener('click', window.searchaccessfield);
          }
        }
      }

      // Suppression des donnees du champ linguistique (plus utiles)
      Data.language = Data.language != null;
      // Enregistrement du fichier "behaviour.js" comme module "AMD"
      if (typeof define === 'function' && define.amd)
        define('behaviour.js', [], function () {});
      // Suppression de la fonction inutile
      delete this;
    }
  }
}

// - GESTION COMMUNE DES COMPOSANTS - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// COMPOSANTS : Mise a jour des scripts lors du changement de vue
function refresh(_e) {
  let d;
  let i;
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  clearInterval(Data.timer);
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // BARRE D'OUTIL : Effet parallaxe
  if (Data.toolbar != null) {
    // Mise a jour de la hauteur de l'entete pour les calculs de positions parallaxes
    Data.toolbar.scrollsize =
      Data.toolbar.header.offsetTop + Data.toolbar.header.offsetHeight;
    // Mise a jour de l'affichage selon le scroll
    // scroll(e);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // MENU "SOMMAIRE" : Correction du positionnement
  TableOfContent.refresh();
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // ELEMENT DE SUIVI : Correction du positionnement
  FollowingScroll.refresh();
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // EFFET ACCORDEON ([+]/[-]) : Verification de la demande fonctionnelle (detection faite via la presence d'une propriete css)
  if (Data.accordions.length > 0) {
    d = Data.accordions.length - 1;
    for (d = d; d >= 0; d--) {
      if (
        Number.parseFloat(
          getComputedStyle(Data.accordions[d].parent, ':before').width
        ) > 0
      ) {
        i = Data.accordions[d].items.length - 1;
        for (i = i; i >= 0; i--) {
          Accordion.activate(Data.accordions[d].items[i]);
        }
      } else {
        i = Data.accordions[d].items.length - 1;
        for (i = i; i >= 0; i--) {
          Accordion.desactivate(Data.accordions[d].items[i]);
        }
      }
    }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // PIED DE PAGE : Effet accordeon ([+]/[-])
  if (Data.footer != null) {
    // Activation de l'effet accordeon (presence ou non des boutons d'ouverture/fermeture)
    if (Data.footer.general.iconbutton.offsetWidth > 10) {
      Accordion.activate(Data.footer.general.index);
      if (Data.footer.specific != null) {
        Accordion.activate(Data.footer.specific.index, true);
        Data.footer.tabparent.setAttribute('role', 'tablist');
        Data.footer.tabparent.setAttribute('multiselectable', 'true');
      }
    } else {
      Accordion.desactivate(Data.footer.general.index);
      if (Data.footer.specific != null)
        Accordion.desactivate(Data.footer.specific.index, true);
      Data.footer.tabparent.removeAttribute('role');
      Data.footer.tabparent.removeAttribute('multiselectable');
    }
  }
}

// COMPOSANTS : Mise a jour du visuel selon le scroll du navigateur (autres navigateurs)
function scroll(_e) {
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Recuperation de la position du scroll selon les navigateurs
  const s = Math.max(
    document.body.scrollTop,
    document.documentElement.scrollTop
  );
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // BARRE D'OUTIL : Effet parallaxe
  if (s >= Data.toolbar.scrollsize) {
    Data.toolbar.page.dataset.headerparallax = true;
    navigation_refresh();
    search_refresh();
  } else {
    delete Data.toolbar.page.dataset.headerparallax;
    navigation_refresh();
    search_refresh();
  }
}

// - GESTION DE L'AFFICHAGE (MENU DE NAVIGATION)  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// NAVIGATION (GENERAL) : Rafraichissement du menu
function navigation_refresh() {
  if (Data.navigation != null) {
    // Identification du type d'affichage utilise par le menu : reduit ou deploye
    if (Data.navigation.menuaccess.offsetWidth > 1) {
      if (Data.navigation.mode != 'treeview') {
        // Mise a jour du mode d'affichage du menu
        navigation_mode(true);
        // Reinitialisation des menus
        navigation_closeall();
      } else if (Data.navigation.menu.dataset.expanded == 'true') {
        navigation_calculatemenu();
      }
    } else if (Data.navigation.mode == 'treeview') {
      // Mise a jour du mode d'affichage du menu
      navigation_mode(false);
      // Reinitialisation des menus
      navigation_closeall();
    }
  }
}

// MENU DE NAVIGATION (GENERAL) : Mise a jour de la largeur du selecteur de filtre
function navigation_select() {
  if (Data.navigation.filter != null) {
    if (Data.navigation.filter.placeholder != null) {
      // Ajout du placeholder pour application de sa taille
      Data.navigation.filter.dropdownlist.append(
        Data.navigation.filter.placeholder
      );
      const t =
        Data.navigation.filter.placeholder.querySelectorAll('.epjs_option')[0];
      // Ouverture du menu hors de la zone visible
      Data.navigation.filter.button.tag.style.width = '';
      Data.navigation.filter.dropdown.style.position = 'absolute';
      Data.navigation.filter.dropdown.style.transform = 'translate(-9000px, 0)';
      Data.navigation.filter.container.dataset.open = 'true';
      Data.navigation.filter.placeholder.style.display = 'inline-block';
      delete t.dataset.disabled;
      Data.navigation.filter.container.style.maxWidth =
        Data.navigation.filter.placeholder.offsetWidth + 60 + 'px';
      // Fermeture du menu
      delete Data.navigation.filter.container.dataset.open;
      Data.navigation.filter.dropdown.style.position = '';
      Data.navigation.filter.dropdown.style.transform = '';
      Data.navigation.filter.dropdown.style.minWidth =
        Data.navigation.filter.container.offsetWidth + 'px';
      Data.navigation.filter.dropdown.style.width =
        Data.navigation.filter.container.offsetWidth + 'px';
      // Suppression du placeholder apres le calcul
      t.dataset.disabled = true;
      Data.navigation.filter.placeholder.remove();
    } else {
      Data.navigation.filter.container.style.maxWidth = '';
    }
  }
}

// MENU DE NAVIGATION (GENERAL) : Fermeture du menu lors du clic hors du menu
function navigation_checkclick(e) {
  if (
    check(e.target) &&
    e.target.hasAttribute('class') &&
    e.target.getAttribute('class') != null &&
    !e.target.getAttribute('class').includes('epjs_navigation')
  )
    return navigation_closeall();
}

// MENU DE NAVIGATION (GENERAL) : Modification du mode d'affichage pour l'accessibilite et la logique de navigation clavier (treeview = navigation verticale, menubar = navigation horizontale)
function navigation_mode(treeview) {
  let m;
  let d;
  // Activation du mode "tree view" utilise par la version reduite et parallaxe
  if (treeview && Data.navigation.mode != 'treeview') {
    // Definition du mode
    Data.navigation.mode = 'treeview';
    Data.navigation.bar.setAttribute('role', 'tree');
    // Traitement des elements du menu (niveau 0)
    if (Data.navigation.links != null) {
      m = Data.navigation.links.level0.length;
      for (let i = 0; i < m; i++) {
        d = Data.navigation.links.all[Data.navigation.links.level0[i]];
        d.block.setAttribute('role', 'treeitem');
        if (d.ismenu) d.list.setAttribute('role', 'group');
      }

      // Traitement des elements du menu (niveau 1)
      m = Data.navigation.links.level1.length;
      for (let i = 0; i < m; i++) {
        d = Data.navigation.links.all[Data.navigation.links.level1[i]];
        d.block.setAttribute('role', 'treeitem');
        if (d.ismenu) d.animtag.removeAttribute('role');
      }
    }
    // Activation du mode "menu" utilise par la version deployee hors du parallaxe
  } else if (!treeview && Data.navigation.mode == 'treeview') {
    // Definition du mode
    Data.navigation.mode = 'menuview';
    Data.navigation.bar.setAttribute('role', 'menubar');
    // Mise a jour du selecteur
    navigation_select();
    // Traitement des elements du menu (niveau 0)
    if (Data.navigation.links != null) {
      m = Data.navigation.links.level0.length;
      for (let i = 0; i < m; i++) {
        d = Data.navigation.links.all[Data.navigation.links.level0[i]];
        d.block.setAttribute('role', 'menuitem');
        if (d.ismenu) d.list.setAttribute('role', 'menu');
      }

      // Traitement des elements du menu (niveau 1)
      m = Data.navigation.links.level1.length;
      for (let i = 0; i < m; i++) {
        d = Data.navigation.links.all[Data.navigation.links.level1[i]];
        d.block.setAttribute('role', 'menuitem');
        if (d.ismenu) d.animtag.setAttribute('role', 'tree');
      }
    }
  }
}

// MENU DE NAVIGATION (GENERAL) : Demande de fermeture globale du menu principal
function navigation_closeall(target, focus) {
  let d;
  // Fermeture du menu reduit
  navigation_display(false, focus);
  // Fermeture de tous les menus du menu deploye
  if (Data.navigation.links != null) {
    let i = Data.navigation.links.all.length - 1;
    for (i = i; i >= 0; i--) {
      d = Data.navigation.links.all[i];
      if (d.ismenu) navigation_view(d, false, false);
    }

    // Analyse du bouton a cibler (focus)
    if (isNaN(target) || target == null) target = 0;
    // Activation de l'acces au bouton du menu
    d = Data.navigation.links.all[target];
    d.button.setAttribute('tabindex', 0);
    Data.navigation.selectedlink = d;
    if (focus) d.button.focus();
  }
}

// NAVIGATION (GENERAL) : Demande d'ouverture ou de fermeture via la navigation clavier
function navigation_keyboard(e, forcedaction, forcedthis) {
  // Identification de l'objet de donnees
  if (Data.navigation != null) {
    let l;
    let i;
    let m;
    // Identification de l'objet de donnees
    const t = forcedthis == null ? this : forcedthis;
    const d = Data.navigation.links.all[t.dataset.navindex];
    // Verification de la touche choisie
    let k = null;
    if (e.keyCode) k = e.keyCode;
    else if (e) k = e.which ? e.which : 0;
    // Analyse de l'action demandee selon le mode d'affichage
    let action = null;
    if (typeof forcedaction === 'string') {
      action = forcedaction;
    } else if (Data.navigation.mode == 'treeview') {
      switch (true) {
        case k == 56 || k == 38:
          action = 'previous';
          break; // - - -> deplacement de la selection dans le niveau     (touche "8" ou "arrow up")
        case k == 50 || k == 40:
          action = 'next';
          break; // - - -> deplacement de la selection dans le niveau     (touche "2" ou "arrow down")
        case k == 54 || k == 39:
          action = 'open';
          break; // - - -> ouverture du menu ferme                (touche "6" ou "arrow right")
        case k == 52 || k == 37:
          action = 'close';
          break; // - - -> fermeture du menu ouvert               (touche "4" ou "arrow left")
        default:
          break;
      }
    } else {
      switch (true) {
        case (k == 52 || k == 37) && d.level == 0: // - - -> deplacement de la selection dans le niveau 0     (touche "4" ou "arrow left")
        case (k == 56 || k == 38) && d.level >= 1:
          action = 'previous';
          break; // - - -> deplacement de la selection dans les niveaux > 0   (touche "8" ou "arrow up")
        case (k == 54 || k == 39) && d.level == 0: // - - -> deplacement de la selection dans le niveau 0     (touche "6" ou "arrow right")
        case (k == 50 || k == 40) && d.level >= 1:
          action = 'next';
          break; // - - -> deplacement de la selection dans les niveaux > 0   (touche "2" ou "arrow down")
        case (k == 50 || k == 40) && d.level == 0: // - - -> ouverture du menu ferme dans le niveau 0       (touche "2" ou "arrow down")
        case (k == 52 || k == 39) && d.level >= 1:
          action = 'open';
          break; // - - -> ouverture du menu ferme dans les niveaux > 0     (touche "6" ou "arrow right")
        case (k == 56 || k == 38) && d.level == 0: // - - -> fermeture du menu ouvert dans le niveau 0      (touche "8" ou "arrow up")
        case (k == 54 || k == 37) && d.level >= 1:
          action = 'close';
          break; // - - -> fermeture du menu ouvert dans les niveaux > 0    (touche "4" ou "arrow left")
        default:
          break;
      }
    }

    // Traitement de l'action demandee
    if (action == 'previous' || action == 'next') {
      // - - - - - - - - - - - - - - - - - - - - - - - - - - -> deplacement de la selection dans le niveau
      // Identification du niveau de profondeur du bouton appelant
      l = Data.navigation.links['level' + d.level];
      // Identification de la position du bouton au sein du niveau
      i = d.order;
      // Recherche du bouton suivant pour la selection
      if (action == 'previous' && i >= 0) i--;
      else if (action == 'next' && i < l.length - 1) i++;
      if (i < 0)
        i =
          Data.navigation.mode != 'treeview' && d.level == 1
            ? d.parent
            : d.index;
      else i = l[i];
      if (i != d.index) {
        i = Data.navigation.links.all[i];
        // Verification que le niveau suivant n'est pas ouvert (mode "tree view" uniquement) pour le deplacement du focus
        if (Data.navigation.mode == 'tree view') {
          m = 0;
          while (
            i.ismenu &&
            i.block.getAttribute('aria-expanded') == 'true' &&
            m < 10
          ) {
            l = Data.navigation.links['level' + i.level];
            // Deplacement de la selection dans la liste ouverte
            if (i.ismenu && action == 'previous' && i.order > 0)
              i = Data.navigation.links.all[i.children[i.children.length - 1]];
            else if (i.ismenu && action == 'next' && i.order < l.length - 1)
              i = Data.navigation.links.all[i.children[0]];
            else break;
            // Blocage des boucles infinies
            m--;
          }
          // Verification qu'on ne change pas de niveau (vers niveau superieur)
        } else if (i.level < d.level) {
          navigation_expand(i, false, false);
        }

        // Assignation du focus
        i.button.setAttribute('tabindex', 0);
        i.button.focus();
        d.button.setAttribute('tabindex', -1);
        Data.navigation.access = i;
      }

      // Blocage de l'action initiale de la touche
      e.preventDefault();
      return false;
    }

    if (action == 'open') {
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -> ouverture du menu ferme
      if (d.ismenu && d.block.getAttribute('aria-expanded') !== 'true') {
        navigation_expand(d, true, true);
      }

      // Blocage de l'action initiale de la touche
      e.preventDefault();
      return false;
    }

    if (action == 'close') {
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -> fermeture du menu ouvert (touche "6" ou "arrow right")
      // Identification du bouton parent
      i = Data.navigation.links.all[d.parent];
      if (
        d.level > 0 &&
        i.ismenu &&
        i.block.getAttribute('aria-expanded') == 'true'
      ) {
        navigation_expand(i, false, true);
        i.button.setAttribute('tabindex', 0);
        i.button.focus();
        d.button.setAttribute('tabindex', -1);
        Data.navigation.access = i;
      }

      // Blocage de l'action initiale de la touche
      e.preventDefault();
      return false;
    }

    if (k == 13 || k == 32) {
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -> validation de la selection (touche "enter" ou "space"){
      if (d.ismenu) {
        // Ouverture/fermeture du menu
        navigation_keyboard(
          e,
          d.block.getAttribute('aria-expanded') !== 'true' ? 'open' : 'close',
          this
        );
        // Blocage de l'action initiale de la touche
        e.preventDefault();
        return false;
      }

      // Aucune action specifique pour l'execution du lien
      return true;
    }

    if (k == 36 || k == 35) {
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -> deplacement direct de la selection dans le premier ou dernier niveau (touche "home" ou touche "end")
      i = null;
      if (
        k == 36 &&
        Data.navigation.links.all[Data.navigation.links.level0[0]] != null
      ) {
        i = Data.navigation.links.all[Data.navigation.links.level0[0]];
        if (i.ismenu && i.block.getAttribute('aria-expanded') == 'true') {
          // Assignation du dernier bouton du sous-niveau 1
          i = Data.navigation.links.all[i.children[0]];
          if (i.ismenu && i.block.getAttribute('aria-expanded') == 'true') {
            // Assignation du dernier bouton du sous-niveau 2
            i = Data.navigation.links.all[i.children[0]];
          }
        }
      } else if (
        k == 35 &&
        Data.navigation.links.all[
          Data.navigation.links.level0[Data.navigation.links.level0.length - 1]
        ] != null
      ) {
        i =
          Data.navigation.links.all[
            Data.navigation.links.level0[
              Data.navigation.links.level0.length - 1
            ]
          ];
        if (i.ismenu && i.block.getAttribute('aria-expanded') == 'true') {
          // Assignation du dernier bouton du sous-niveau 1
          i = Data.navigation.links.all[i.children[i.children.length - 1]];
          if (i.ismenu && i.block.getAttribute('aria-expanded') == 'true') {
            // Assignation du dernier bouton du sous-niveau 2
            i = Data.navigation.links.all[i.children[i.children.length - 1]];
          }
        }
      }

      if (i != null) {
        i.button.setAttribute('tabindex', 0);
        i.button.focus();
        d.button.setAttribute('tabindex', -1);
        Data.navigation.access = i;
      }

      // Blocage de l'action initiale de la touche
      e.preventDefault();
      return false;
    }

    if (k == 27) {
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -> arret du suivi (touche "escape"){
      // Deplacement du focus sur le lien de premier niveau
      if (d.level > 0) {
        m = 10;
        i = Data.navigation.links.all[d.parent];
        while (i.level > 0) {
          i = Data.navigation.links.all[i.parent];
        }

        i = i.index;
      } else {
        i = d.index;
      }

      // Fermeture integrale du menu
      navigation_closeall(i, true);
      // Blocage de l'action initiale de la touche
      e.preventDefault();
      return false;
    }

    if (k == 9) {
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -> arret du suivi (touche "tab"){
      // Deplacement du focus sur le premier lien du menu
      i = Data.navigation.links.all[0];
      i.button.setAttribute('tabindex', 0);
      i.button.focus();
      d.button.setAttribute('tabindex', -1);
      Data.navigation.access = i;
      // Fermeture integrale du menu
      navigation_closeall(null, false);
      return true;
    }

    return true;
  }

  return true;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// MENU DE NAVIGATION (REDUIT) : Demande d'ouverture ou de fermeture du menu principal
function navigation_display(view, focus) {
  let v;
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  clearInterval(Data.timer);
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Verification de la validite de la demande
  if (String(view) != Boolean(Data.navigation.menu.dataset.expanded)) {
    // Mise a jour du visuel de la page
    if (view) {
      // Calcul de la largeur de la barre de defilement du site
      document.body.style.overflow = '';
      v = document.body.offsetWidth;
      document.body.style.overflow = 'hidden';
      v =
        v < document.body.offsetWidth
          ? document.body.offsetWidth - v + 'px'
          : '';
      // Blocage de l'evenement scroll sur mobile
      document.body.addEventListener(
        'touchmove',
        navigation_scrollfollow,
        false
      );
      Data.navigation.bar.addEventListener(
        'touchstart',
        navigation_scrollevent,
        false
      );
      Data.navigation.bar.addEventListener(
        'touchend',
        navigation_scrollevent,
        false
      );
      // Sauvegarde de l'information de suivi du scroll
      Data.navigation.scrollfollow = false;
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      // Correction de l'affichage du bouton d'ouverture selon la taille de la barre de defilement
      Data.navigation.menuaccess.style.paddingRight = v;
      // Correction de l'affichage des elements de la page selon la taille de la barre de defilement
      Data.navigation.headerpage.style.paddingRight = v;
      Data.navigation.headerbar.style.paddingRight = v;
      Data.navigation.bodypage.style.paddingRight = v;
      // // http://www.jira.ep.parl.union.eu/ep-jira/browse/ENG-25978
      if (Data.navigation.footerpage) {
        Data.navigation.footerpage.style.paddingRight = v;
      }

      Data.navigation.menu.style.marginRight = '-' + v;
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      // Mise a jour de l'affichage du menu avec anim
      Data.navigation.menu.dataset.expanded = true;
      Data.navigation.anim.setAttribute('aria-hidden', false);
      Data.navigation.menuaccess.setAttribute('aria-expanded', true);
      // Mise a jour de l'affichage selon la resolution
      navigation_calculatemenu();
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      // Assignation du focus au premier element du menu
      Data.navigation.links.all[Data.navigation.links.level0[0]].button.focus();
    } else {
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      // Affichage de la barre de defilement de la page
      document.body.removeAttribute('style');
      // Deblocage de l'evenement scroll sur mobile
      document.body.removeEventListener(
        'touchmove',
        navigation_scrollfollow,
        false
      );
      Data.navigation.bar.removeEventListener(
        'touchstart',
        navigation_scrollevent,
        false
      );
      Data.navigation.bar.removeEventListener(
        'touchend',
        navigation_scrollevent,
        false
      );
      // Sauvegarde de l'information de suivi du scroll
      Data.navigation.scrollfollow = false;
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      Data.navigation.menuaccess.setAttribute('tabindex', '0');
      // Mise a jour de l'affichage du menu avec anim
      Data.navigation.menu.dataset.expanded = false;
      Data.navigation.anim.setAttribute('aria-hidden', true);
      Data.navigation.menuaccess.setAttribute('aria-expanded', false);
      // Suppression des styles ajoutes
      Data.navigation.menuaccess.removeAttribute('style');
      Data.navigation.headerpage.removeAttribute('style');
      Data.navigation.headerbar.removeAttribute('style');
      Data.navigation.bodypage.removeAttribute('style');
      // http://www.jira.ep.parl.union.eu/ep-jira/browse/ENG-25978
      if (Data.navigation.footerpage) {
        Data.navigation.footerpage.removeAttribute('style');
      }

      Data.navigation.menu.removeAttribute('style');
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      // Assignation du focus au bouton de navigation
      if (focus) Data.navigation.menuaccess.focus();
    }
  }
}

// MENU DE NAVIGATION (REDUIT) : Calcul de la hauteur a appliquer au menu selon la resolution
function navigation_calculatemenu() {
  let h;
  // Repositionnement du menu selon le scroll de la page
  h = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
  if (h < Data.toolbar.scrollsize) {
    document.documentElement.scrollTop = Data.toolbar.scrollsize;
    document.body.scrollTop = Data.toolbar.scrollsize;
  }

  // Calcul de la hauteur de la fenetre a assigner au menu (pour le scroll)
  h =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  Data.navigation.anim.style.height = h - 45 + 'px';
  Data.navigation.anim.style.maxHeight = h - 45 + 'px';
}

// MENU DE NAVIGATION (REDUIT) : Demande d'ouverture ou de fermeture des menus accordeons
function navigation_accordion(data, view, focus) {
  // Recherche de l'etat a afficher
  view =
    view === true || view === false
      ? view
      : !(data.block.getAttribute('aria-expanded') === 'true');
  // Verification du changement demande
  if (String(view) != data.block.dataset.expanded && data.ismenu) {
    // Recuperation de la hauteur reelle des liens
    let h = navigation_accordionheight(data);
    // Calcul de la nouvelle hauteur a definir pour l'anim via CSS
    const t = !view ? 0 : h + 'px';
    // Assignation de la hauteur actuelle
    data.animtag.style.height = !view ? h + 'px' : 0;
    data.list.style.display = '';
    // Autorisation d'affichage
    data.block.setAttribute('aria-expanded', view);
    data.button.setAttribute('tabindex', view ? 0 : -1);
    if (view) Data.navigation.selectedlink = data;
    // Assignation du focus clavier
    if (focus === true && view && data.children.length > 0) {
      // Deplacement du focus sur le premier lien du menu
      h = Data.navigation.links.all[data.children[0]];
      h.button.setAttribute('tabindex', 0);
      h.button.focus();
      Data.navigation.selectedlink = h;
      // Blocage du focus sur le bouton parent
      data.button.setAttribute('tabindex', -1);
    }

    // Lancement des fermetures des panneaux enfants
    if (data.level < 2) {
      // Fermeture des panneaux enfant
      h = Data.navigation.links['level' + (data.level + 1)].length - 1;
      for (h = h; h >= 0; h--) {
        navigation_accordion(
          Data.navigation.links.all[
            Data.navigation.links['level' + (data.level + 1)][h]
          ],
          false,
          false
        );
      }
    }

    // Lancement de l'affichage anime
    setTimeout(function () {
      delete data.block.dataset.noanim;
      data.animtag.style.height = t;
    }, 10);
  }
}

function navigation_accordionheight(data) {
  data.animtag.style.height = 0;
  data.list.style.display = 'block';
  data.list.style.opacity = '0';
  data.animtag.style.height = '';
  data.list.style.display = '';
  data.list.style.opacity = '';
  return data.list.offsetHeight;
}

// MENU DE NAVIGATION (REDUIT) : Demande de fermeture globale du menu principal
function navigation_switch(_e) {
  return (
    navigation_display(Data.navigation.menu.dataset.expanded != 'true'), true
  );
}

function navigation_access(e) {
  // Verification de la touche choisie
  let k = null;
  if (e.keyCode) k = e.keyCode;
  else if (e) k = e.which ? e.which : 0;
  // Validation de la demande (touche "enter" ou "space")
  if (k == 13 || k == 32) {
    navigation_switch();
    // Blocage de l'action initiale de la touche
    e.preventDefault();
    return false;
  }

  if (k == 27) {
    // Fermeture integrale du menu)
    navigation_display(false, true);
    // Blocage de l'action initiale de la touche
    e.preventDefault();
    return false;
  }
}

// MENU DE NAVIGATION (REDUIT) : Simulation du scroll dans le menu ouvert suite au blocage du scroll de la page
function navigation_scrollevent(e) {
  if (
    check(e.target) &&
    Data.navigation.mode == 'treeview' &&
    e.target.classList.contains('epjs_navigation')
  ) {
    if (/start/gi.test(e.type)) {
      Data.navigation.scrollfollow = true;
      Data.navigation.scrolltime = new Date();
      Data.navigation.scrollY = Data.navigation.anim.scrollTop;
      Data.navigation.scrollratio =
        Data.navigation.anim.offsetHeight / e.touches[0].screenY;
      Data.navigation.cursorY = e.touches[0].clientY;
      e.preventDefault();
      return false;
    }

    const b = new Date();
    if (
      /end/gi.test(e.type) &&
      Data.navigation.scrolltime != 0 &&
      b - Data.navigation.scrolltime < 350 &&
      Data.navigation.cursorY - e.changedTouches[0].clientY < 10
    ) {
      navigation_mouse(e, null, e.target);
    }

    Data.navigation.scrollfollow = false;
    Data.navigation.scrolltime = 0;
  }
}

function navigation_scrollfollow(e) {
  // Traitement de la demande
  if (
    check(e.target) &&
    Data.navigation.mode == 'treeview' &&
    Data.navigation.scrollfollow &&
    e.target.classList.contains('epjs_navigation')
  )
    Data.navigation.anim.scrollTop =
      Data.navigation.scrollY +
      (Data.navigation.cursorY - e.touches[0].clientY) *
        Data.navigation.scrollratio;
}

// MENU DE NAVIGATION (REDUIT) : Demande de fermeture globale du menu principal
function navigation_expand(e) {
  // Identification du lien a deployer
  let b = this == window && e != null ? e.button : this;
  let d = Data.navigation.links.all[b.dataset.navindex];
  // Verification du type d'evenement
  b = new Date();
  if (Data.navigation.scrolltime != 0 && b - Data.navigation.scrolltime > 200)
    d = null;
  // Changement de l'affichage
  if (d != null) navigation_accordion(d, null, !document.body.dataset.ismobile);
}

// MENU DE NAVIGATION (REDUIT) : Nettoyage des styles CSS apres animation d'effet accordeon
function navigation_expandend(_e, _index) {
  // Identification de l'objet de donnees
  const data = Data.navigation.links.all[this.dataset.navindex];
  if (data != null) {
    // Nettoyage des styles CSS de l'animation
    data.animtag.style.height = '';
    data.list.style.display = '';
    data.list.style.opacity = '';
    delete data.block.dataset.callback;
    delete data.block.dataset.noanim;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// MENU DE NAVIGATION (DEPLOYE) : Demande d'ouverture ou de fermeture des liens du menu
function navigation_view(data, display, focus) {
  // Recherche de l'etat a afficher
  display =
    display === true || display === false
      ? display
      : !(data.block.getAttribute('aria-expanded') === 'true');
  // Verification du changement demande
  if (String(display) != data.block.dataset.expanded && data.ismenu) {
    // Recuperation de la hauteur reelle des liens
    let h = navigation_calculatesubmenu(data);
    // Calcul de la nouvelle hauteur a definir pour l'anim via CSS
    const t = !display ? 0 : h + 'px';
    // Assignation de la hauteur actuelle
    data.animtag.style.height = !display ? h + 'px' : 0;
    data.list.style.display = '';
    // Autorisation d'affichage
    data.block.setAttribute('aria-expanded', display);
    data.button.setAttribute('tabindex', display ? 0 : -1);
    if (display) Data.navigation.selectedlink = data;
    // Assignation du focus clavier
    if (focus === true && display && data.children.length > 0) {
      // Deplacement du focus sur le premier lien du menu
      h = Data.navigation.links.all[data.children[0]];
      h.button.setAttribute('tabindex', 0);
      h.button.focus();
      Data.navigation.selectedlink = h;
      // Blocage du focus sur le bouton parent
      data.button.setAttribute('tabindex', -1);
    }

    // Lancement des fermetures des panneaux enfants
    if (data.level < 2) {
      // Fermeture des panneaux enfant
      h = Data.navigation.links['level' + (data.level + 1)].length - 1;
      for (h = h; h >= 0; h--) {
        navigation_view(
          Data.navigation.links.all[
            Data.navigation.links['level' + (data.level + 1)][h]
          ],
          false,
          false
        );
      }
    }

    // Lancement de l'affichage anime
    setTimeout(function () {
      delete data.block.dataset.noanim;
      data.animtag.style.height = t;
    }, 10);
  }
}

function navigation_hide(target) {
  if (Data.navigation.mode != 'treeview') navigation_closeall(target, false);
}

// MENU DE NAVIGATION (DEPLOYE) : Demande d'ouverture ou de fermeture via le survol de la souris
function navigation_mouse(e, notmenu, originalcaller) {
  let t = check(originalcaller) && !check(this) ? originalcaller : this;
  let d;
  d = notmenu
    ? Data.navigation.links.all[0]
    : Data.navigation.links.all[t.dataset.navindex];
  // Verification de l'acces aux donnees et de l'affichage en mode "menu"
  if (Data.navigation.mode != 'treeview') {
    // Analyse de l'action a effectuer
    if (/out/gi.test(e.type) || /touchend/gi.test(e.type) || notmenu === true) {
      // Arret de la fermeture automatique du menu
      clearTimeout(Data.navigation.timer);
      // Fermeture du menu
      if (d.level == 1) d = Data.navigation.links.all[d.parent].index;
      else if (d.level == 2)
        d =
          Data.navigation.links.all[Data.navigation.links.all[d.parent].index];
      if (/touchend/gi.test(e.type)) {
        navigation_hide(d);
      } else {
        Data.navigation.timer = setTimeout(function () {
          navigation_hide(d);
        }, 500);
      }

      // Blocage de l'action initiale
      e.preventDefault();
      return false;
    }

    if (/over/gi.test(e.type) || /touchstart/gi.test(e.type)) {
      // Arret de la fermeture automatique du menu
      clearTimeout(Data.navigation.timer);
      // Identification des parents du menu a ouvrir
      let level0 = null;
      let level1 = null;
      if (d.level == 0) {
        level0 = d.index;
      }

      if (d.level == 1) {
        level0 = d.parent;
        level1 = d.index;
      }

      if (d.level == 2) {
        level0 = Data.navigation.links.all[d.parent].parent;
        level1 = d.parent;
      }

      // Fermeture des menus
      for (let i = Data.navigation.links.level0.length - 1; i >= 0; i--) {
        if (Data.navigation.links.level0[i] != level0) {
          navigation_view(
            Data.navigation.links.all[Data.navigation.links.level0[i]],
            false,
            false
          );
        }
      }

      for (let i = Data.navigation.links.level1.length - 1; i >= 0; i--) {
        if (Data.navigation.links.level1[i] != level1) {
          navigation_view(
            Data.navigation.links.all[Data.navigation.links.level1[i]],
            false,
            false
          );
        }
      }

      // Traitement du menu
      navigation_view(d, true, false);
      // Blocage de l'action initiale
      if (d.ismenu) {
        e.preventDefault();
        return false;
      }
    }
  } else if (/touchstart/gi.test(e.type)) {
    if (String(t.tagName).toLowerCase() != 'a') {
      e.preventDefault();
      return false;
    }
  } else if (/touchend/gi.test(e.type)) {
    t = String(t.tagName).toLowerCase() == 'span' ? t.parentNode : t;
    // Recherche du lien
    if (String(t.tagName).toLowerCase() == 'a') {
      if (t.getAttribute('target') == '_blank') {
        window.open(t.getAttribute('href'), '_blank');
      } else {
        location.assign(t.getAttribute('href'));
      }
    }
  }
}

// MENU DE NAVIGATION (DEPLOYE) : Fermeture du menu lors du survol hors du menu
function navigation_checkover(e) {
  if (check(e.target) && Data.navigation.mode != 'treeview') {
    if (!e.target.classList.contains('epjs_navigation')) {
      e.type = /touchstart/gi.test(e.type) ? 'touchend' : 'mouseout';
      navigation_mouse(
        {
          preventDefault() {},
          type: /touchstart/gi.test(e.type) ? 'touchend' : 'mouseout',
        },
        true
      );
    }
  } else if (
    Data.navigation.mode == 'treeview' &&
    Boolean(Data.navigation.menu.dataset.expanded == 'true') &&
    e.type.match('touchstart')
  )
    navigation_checkclick(e);
}

// OUVERTURE/FERMETURE : Calcul de la hauteur du bloc de contenu pour l'animation
function navigation_calculatesubmenu(data) {
  data.animtag.style.height = 0;
  data.list.style.display = 'block';
  data.list.style.opacity = '0';
  data.animtag.style.height = '';
  data.list.style.display = '';
  data.list.style.opacity = '';
  return data.list.offsetHeight;
}

// - GESTION DE L'AFFICHAGE (MOTEUR DE RECHERCHE) - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// MOTEUR DE RECHERCHE : Definition de l'acces au formulaire
function search_refresh() {
  if (Data.search != null) {
    // Arret de l'ecouteur de l'etat du champ
    search_scan();
    // Mise a jour de l'etat du bouton
    search_validation();
    // Analyse de l'etat du menu : restreint ou non
    if (Data.search.access.offsetWidth > 1) {
      if (!Data.search.reducemode) {
        // Mise a jour de l'indicateur d'affichage
        Data.search.reducemode = true;
        // Verification de l'etat actuel
        const view = Data.search.parent.dataset.expanded === 'true';
        // Blocage de l'effet d'animation si le menu n'est pas encore ouvert (pour bloquer l'assignation automatique du focus)
        if (!view) Data.search.parent.dataset.noanim = true;
        // Ajout des parametres de controle
        Data.search.form.dataset.expanded = view;
        Data.search.animate.setAttribute('aria-hidden', !view);
        Data.search.access.setAttribute(
          'aria-controls',
          Data.search.animate.id
        );
        Data.search.access.setAttribute('aria-expanded', view);
        Data.search.access.setAttribute('tabindex', 0);
      }
    } else if (Data.search.reducemode) {
      Data.search.reducemode = false;
      // Suppression des parametres
      Data.search.form.removeAttribute('style');
      delete Data.search.parent.dataset.expanded;
      Data.search.animate.removeAttribute('aria-hidden');
      Data.search.access.removeAttribute('aria-controls');
      Data.search.access.removeAttribute('aria-expanded');
      Data.search.access.removeAttribute('tabindex');
    }
  }
}

// MOTEUR DE RECHERCHE : Definition de l'acces au formulaire
function search_validation() {
  if (Data.search != null) {
    // Mise a jour de l'etat du bouton
    if (Data.search.field.value.length === 0) {
      Data.search.button.setAttribute('disabled', 'disabled');
      return false;
    }

    Data.search.button.removeAttribute('disabled');
    return true;
  }
}

// MOTEUR DE RECHERCHE : Lancement de la boucle d'ecoute de l'etat du champ texte
function search_scan(e) {
  if (Data.search != null) {
    if (typeof e !== 'object') e = { type: '' };
    if (e.type.match('focus')) {
      Data.search.scanmode = setInterval(search_validation, 500);
    } else {
      clearInterval(Data.search.scanmode);
    }
  }
}

// MOTEUR DE RECHERCHE : Gestion du changement d'etat d'affichage du menu reduit
function search_display(e, display, nofocus) {
  if (Data.search.reducemode) {
    // Analyse de l'etat actuel du menu avant changement
    let todisplay = !Data.search.parent.dataset.open;
    if (typeof display === 'boolean') todisplay = display;
    // Changement des parametres d'accessibilite
    Data.search.access.setAttribute('aria-expanded', todisplay);
    Data.search.animate.setAttribute('aria-hidden', !todisplay);
    // Verification de la demande d'ouverture du menu
    if (todisplay) {
      // Fermeture des autres menus
      navigation_display(false, false, false);
      // Initialisation du champ texte
      Data.search.field.value = '';
      search_validation();
      // Lancement de l'affichage du menu
      delete Data.search.parent.dataset.noanim;
      Data.search.parent.dataset.open = true;
      // Lancement automatique de l'acces au champ texte sur la version mobile (pas d'animation d'affichage)
      if (document.body.dataset.ismobile && !nofocus) search_focus();
    } else {
      delete Data.search.parent.dataset.open;
      if (nofocus) Data.search.parent.dataset.noanim = true;
    }

    // Blocage de l'action par defaut
    if (e !== null) e.preventDefault();
  }
}

// MOTEUR DE RECHERCHE : Assignation du focus
function search_focus(_e) {
  // Assignation du focus
  if (Data.search.parent.dataset.open) {
    Data.search.field.focus();
  } else if (
    !document.body.dataset.ismobile &&
    !Data.search.parent.dataset.noanim
  ) {
    delete Data.search.parent.dataset.noanim;
    Data.search.access.focus();
  } else {
    Data.search.field.blur();
  }
}

// MOTEUR DE RECHERCHE : Validation de l'envoi du formulaire
function search_submit(e) {
  clearTimeout(Data.search.timer);
  // Blocage du formulaire si le champ n'est pas correctement rempli
  if (!search_validation() && e != null) e.preventDefault();
  // Mise a jour du visuel de la page apres fermeture du champ texte
  search_display(null, false);
}

function search_button(e) {
  if (Data.search.reducemode && Data.toolbar.page.dataset.ismobile) {
    clearTimeout(Data.search.timer);
    if (e.type.toLowerCase().match('touch')) {
      if (search_validation()) Data.search.form.submit();
      e.preventDefault();
    } else if (e.type.toLowerCase().match('focus')) {
      Data.search.parent.dataset.open = true;
    } else {
      Data.search.timer = setTimeout(function () {
        delete Data.search.parent.dataset.open;
      }, 50);
    }
  }
}

// MOTEUR DE RECHERCHE : Fermeture du menu si clic hors de la zone autorisee
function search_check(e) {
  if (
    check(e.target) &&
    Data.search.reducemode &&
    Data.search.parent.dataset.open &&
    !e.target.classList.contains('epjs_search')
  )
    search_display(e, false, true);
}

// MOTEUR DE RECHERCHE : Navigation au sein du moteur de recherche
function search_key(e) {
  let t;
  // Identification de la balise appelante
  const { target } = e;
  // Recherche de la touche choisie
  let k = null;
  if (e.keyCode) k = e.keyCode;
  else if (e) k = e.which ? e.which : 0;
  if (Data.search.reducemode) {
    if (k == 9) {
      // - - - - - - - - - - - - - - - - - - - - - - - - - - - - -> bouclage de la navigation dans le menu (touche "tab")
      if (
        (target == Data.search.button && !e.shiftKey) ||
        (target == Data.search.field && e.shiftKey)
      ) {
        if (target == Data.search.button) {
          Data.search.field.focus();
        } else if (!Data.search.button.getAttribute('disabled')) {
          Data.search.button.focus();
        }

        e.preventDefault();
        t = false;
      } else if (
        target == Data.search.field &&
        Data.search.button.getAttribute('disabled')
      ) {
        e.preventDefault();
        t = false;
      } else {
        t = true;
      }

      return t;
    }

    if (k == 27) {
      // - - - - - - - - - - - - - - - - - - - - - - - - -> fermeture sans selection (touche "escape")
      search_display(e, false);
      e.preventDefault();
      return false;
    }

    if (k == 50 || k == 40 || k == 13 || k == 32) {
      // - - - - - - -> Ouverture du menu (touche "2" ou "arrow down" ou "enter" ou "space")
      if (target == Data.search.access) {
        search_display(e, true);
        e.preventDefault();
        return false;
      }
    } else {
      search_validation();
      return true;
    }
  } else {
    // Verification de l'etat du texte apres touche clavier
    search_validation();
    // Autorisation de poursuite du code
    return true;
  }
}

// - LANCEMENT DU TRAITEMENT DE LA CLASSE JS  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const globals = new Map();

globals.set('init', init);
globals.set('refresh', refresh);
globals.set('scroll', scroll);
globals.set('navigation_checkclick', navigation_checkclick);
globals.set('navigation_keyboard', navigation_keyboard);
globals.set('navigation_switch', navigation_switch);
globals.set('navigation_access', navigation_access);
globals.set('navigation_scrollevent', navigation_scrollevent);
globals.set('navigation_scrollfollow', navigation_scrollfollow);
globals.set('navigation_expand', navigation_expand);
globals.set('navigation_expandend', navigation_expandend);
globals.set('navigation_hide', navigation_hide);
globals.set('navigation_mouse', navigation_mouse);
globals.set('navigation_checkover', navigation_checkover);
globals.set('search_validation', search_validation);
globals.set('search_scan', search_scan);
globals.set('search_display', search_display);
globals.set('search_focus', search_focus);
globals.set('search_submit', search_submit);
globals.set('search_button', search_button);
globals.set('search_check', search_check);
globals.set('search_key', search_key);

export { globals, init };
