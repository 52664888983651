/* eslint func-names: off, eqeqeq: off, camelcase: off, no-self-assign: off, unicorn/prefer-number-properties: off, unicorn/prevent-abbreviations: off, no-eq-null: off, max-depth: off, complexity: off, max-params: off, unicorn/prefer-query-selector: off, no-negated-condition: off, prefer-rest-params: off */

// Classes JS definies dans ce fichier :
//  . Accordion       : Script convertissant l'affichage en accordeon (expand/collapse [+]/[-])

// Fonctionnalites JS gerees par ce fichier :
//  . detection du support/device utilise pour visionner le site (mobile/desktop)
//  . affichage parallaxe de la barre d'outil inferieure
//  . ouverture/fermeture du menu de recherche en mode reduit
//  . ouverture/fermeture du menu de navigation en mode reduit
//  . navigation clavier dans le menu de navigation
//  . convertion des selecteurs <select> presents dans l'entete en composant JavaScript
//  . convertion de la liste des sites "other websites" en select JS pour le mode reduit
//  . affichage accordeon du pied de page en mode reduit
//  . gestion de l'affichage sur les menus lateraux (effet parallaxe + mode accordeon)

/* ============================================================================================================================================================================ */
/* === GESTION DES ACTIONS ACCORDEONS (expand/collapse [+]/[-]) =============================================================================================================== */
/* ============================================================================================================================================================================ */

const Data = { convertlist: [], reference: {} };

// - VALIDATION - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// VALIDATION : controle de l'objet de donnees passe en parametre
function check(d) {
  if (typeof d === 'object') {
    if (d != null) {
      return d.tagName != null;
    }

    return false;
  }

  return false;
}

// - CONVERTION - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

function convert(parent, newbutton, animatedcontent, realcontent, parameters) {
  // Initialisation des variables
  let i;
  let m;
  let t;
  let b;
  let data = null;
  // Verification des balise transmises en parametre
  if (
    check(parent) &&
    check(newbutton) &&
    check(animatedcontent) &&
    check(realcontent) &&
    !parent.dataset.accordion
  ) {
    // Blocage des convertions ulterieures
    parent.dataset.accordion = false;
    // Analyse de l'objet de parametre
    if (typeof parameters !== 'object') {
      parameters = {};
    } else if (parameters == null) {
      parameters = {};
    }

    // Definition de l'objet
    //    > params.clickonicon                          // [BOOLEAN] (default value: false)       : Indicateur d'ajout des actions de souris sur l'icone et non l'entierete du bouton
    //    > params.openbtn                            // [NODE]                   : Conteneur du bouton d'ouverture  [+] d'origine (utilise sans JS) a supprimer dans la version JS
    //    > params.closebtn                             // [NODE]                   : Conteneur du bouton de fermeture [-] d'origine (utilise sans JS) a supprimer dans la version JS
    //    > params.cb_afterconvert                        // [FUNCTION] (need a return value = true)  : Fonction appelee apres la convertion du bloc
    //    > params.cb_beforeanim                          // [FUNCTION] (need a return value = true)  : Fonction appelee avant l'animation d'ouverture ou la fermeture du bloc de contenu
    //    > params.cb_afteranim                           // [FUNCTION] (need a return value = true)  : Fonction appelee apres l'animation d'ouverture ou la fermeture du bloc de contenu
    // Initialisation de l'objet de donnees du widget
    data = {};
    data.id = null; // [STRING]                   : Identifiant de la balise originale
    data.reference = null; // [STRING]                  : Identifiant de la balise convertie
    data.index = null; // [INT]                   : Identifiant du widget dans le listing
    data.parent = parent; // [NODE]                   : Conteneur global du bouton et du contenu
    data.button = newbutton; // [NODE]                  : Bouton gerant l'ouverture et la fermeture du contenu
    data.buttoninside = false; // [BOOLEAN]                 : Indicateur si le bouton est ou non un enfant du conteneur a animer
    data.iconbutton = null; // [NODE]                   : Icone flottante/independante utilisee pour le clic
    data.content = realcontent; // [NODE]                   : Contenu a afficher/masquer
    data.container = animatedcontent; // [NODE]                   : Conteneur a animer lors de l'ouverture et la fermeture
    data.focus = null; // [NODE]                  : Balise devant receptionner le focus clavier lors de l'ouverture
    data.clickonicon = false; // [BOOLEAN]                : Indicateur d'ajout des actions de souris sur l'icone et non l'entierete du bouton
    data.dependencies = []; // [ARRAY]                  : Liste des index des panneaux a fermer lorsque le panneau s'ouvre
    data.indexdependency = null; // [NUMBER]                  : Position du conteneur accordeon au sein des dependances
    data.callback = {};
    data.callback.beforeanim = null; // [FUNCTION]                  : Fonction a appeler avant l'animation de changement de l'affichage
    data.callback.afteranim = null; // [FUNCTION]                 : Fonction a appeler apres l'animation de changement de l'affichage
    // Sauvegarde des donnees passees par parametre
    data.clickonicon = Boolean(parameters.clickonicon);
    data.callback.beforeanim =
      typeof parameters.cb_beforeanim === 'function'
        ? parameters.cb_beforeanim
        : function () {
            return true;
          };

    data.callback.afteranim =
      typeof parameters.cb_afteranim === 'function'
        ? parameters.cb_afteranim
        : function () {
            return true;
          };

    // Identification du conteneur
    if (String(parent.id).length > 0) {
      data.id = parent.id;
    } else {
      i = new Date();
      data.id = String('aID-' + i.getTime() + '_' + Math.random()).replace(
        '.',
        '-'
      );
    }

    data.reference = 'accordionid_' + data.id;
    // Parametrage des donnees et attributs JS
    data.parent.dataset.expanded = false;
    // Suppression des liens superflus (liens d'ouverture/fermeture utilises dans la version statique sans JS)
    if (check(parameters.openbtn)) parameters.openbtn.remove();
    if (check(parameters.closebtn)) parameters.closebtn.remove();
    // Creation de la balise "icone" [+]/[-]
    if (data.clickonicon) {
      b = document.createElement('span');
      b.classList.add('epjs_accordionbutton');
      data.iconbutton = document.createElement('span');
      data.iconbutton.append(document.createTextNode(' '));
      data.iconbutton.classList.add('epjs_accordionicon');
      b.append(data.iconbutton);
      data.button.append(b);
    }

    // Identification de la position du bouton par rapport au conteneur d'animation
    t = data.container.querySelectorAll('*');
    m = t.length;
    for (i = 0; i < m; i++) {
      if (t[i] == data.button) {
        data.buttoninside = true;
        break;
      }
    }

    // Recherche de la premiere balise devant receptionner le focus
    t = data.content.querySelectorAll('*');
    m = t.length;
    for (i = 0; i < m; i++) {
      if (
        (t[i].getAttribute('tabindex') &&
          t[i].getAttribute('tabindex') != '-1') ||
        t[i].tagName.toLowerCase() == 'a' ||
        t[i].tagName.toLowerCase() == 'input' ||
        t[i].tagName.toLowerCase() == 'button' ||
        t[i].tagName.toLowerCase() == 'textarea'
      ) {
        data.focus = t[i];
        break;
      }
    }

    // Traitement des elements interactifs du menu
    t = data.content.querySelectorAll('*');
    m = t.length;
    for (i = 0; i < m; i++) {
      if (
        (t[i].getAttribute('tabindex') &&
          t[i].getAttribute('tabindex') != '-1') ||
        t[i].tagName.toLowerCase() == 'a' ||
        t[i].tagName.toLowerCase() == 'input' ||
        t[i].tagName.toLowerCase() == 'button' ||
        t[i].tagName.toLowerCase() == 'textarea'
      ) {
        t[i].addEventListener(
          'keyup',
          function (e) {
            keyboard(e, i);
          },
          false
        );
      }
    }

    // Sauvegarde des donnees dans la classe
    Data.convertlist.push(data);
    Data.reference[data.reference] = Data.convertlist.length - 1;
    data.index = Data.convertlist.length - 1;
    i = data.index;
    // Deplacement des actions d'ouverture/fermeture sur le nouveau bouton
    data.container.addEventListener(
      'transitionend',
      function (e) {
        endanim(e, i);
      },
      false
    );
    data.button.addEventListener(
      'keydown',
      function (e) {
        keyboard(e, i);
      },
      false
    );
    if (data.clickonicon)
      data.iconbutton.addEventListener(
        'click',
        function (e) {
          click(e, i);
        },
        false
      );
    else
      data.button.addEventListener(
        'click',
        function (e) {
          click(e, i);
        },
        false
      );
    // Activation de l'etat
    state(data, null, true);
  }

  // Appel de la fonction "callback" apres la convertion du selecteur si demandee
  b = false;
  if (
    parameters != null &&
    typeof parameters === 'object' &&
    typeof parameters.cb_afterconvert === 'function'
  )
    b = true;
  if (b) parameters.cb_afterconvert(data != null, data);
  else return { converted: data != null, data };
}

// Ajout des liaisons entre les panneaux (fermeture des panneaux lorsque le panneau cible est ouvert)
function setdependency(index, dependencies) {
  const data = Data.convertlist[index];
  if (data != null && dependencies.length > 0) {
    let i;
    const { length } = dependencies;
    for (i = 0; i < length; i++) {
      if (dependencies[i] != index) data.dependencies.push(dependencies[i]);
      else data.indexdependency = data.dependencies.length - 1;
    }

    return { executed: true, data };
  }

  return { executed: false, data: null };
}

// - GESTION DES ETATS DU WIDGET  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// ETAT DU WIDGET : Appel public pour le changement d'etat
function activate(reference) {
  if (isNaN(reference)) {
    state(reference, null, true);
  } else {
    state(null, reference, true);
  }
}

function desactivate(reference) {
  if (isNaN(reference)) {
    state(reference, null, false);
  } else {
    state(null, reference, false);
  }
}

// ETAT DU WIDGET : Activation et desactivation des fonctionnalites
function state(data, index, active) {
  // Identification de l'objet de donnees
  if (data == null) data = Data.convertlist[index];
  if (data == null) return;
  // Verification du traitement prealable du widget
  if (active) {
    if (data.parent.dataset.accordion != 'true') {
      delete data.parent.dataset.noanim;
      data.parent.dataset.accordion = true;
      data.parent.dataset.expanded = false;
      data.button.setAttribute('aria-selected', false);
      data.button.setAttribute('aria-expanded', false);
      data.button.setAttribute('aria-controls', data.content.id);
      data.button.setAttribute('role', 'tab');
      data.content.setAttribute('aria-hidden', true);
      data.container.setAttribute('role', 'tablist');
      data.container.setAttribute('multiselectable', true);
      data.container.style.height = '';
      data.content.style.display = '';
      data.content.style.opacity = '';
      if (data.dependencies.length > 0) {
        data.button.setAttribute(
          'tabindex',
          data.indexdependency == -1 ? 0 : -1
        );
      } else {
        data.button.setAttribute('tabindex', 0);
      }
    }
  } else {
    if (data.parent.dataset.accordion) {
      data.parent.dataset.accordion = false;
      data.parent.dataset.expanded = false;
    }

    delete data.parent.dataset.noanim;
    data.button.removeAttribute('aria-selected');
    data.button.removeAttribute('aria-expanded');
    data.button.removeAttribute('aria-controls');
    data.button.removeAttribute('role');
    data.button.removeAttribute('tabindex');
    data.content.removeAttribute('aria-hidden');
    data.container.removeAttribute('role');
    data.container.removeAttribute('multiselectable');
    data.container.style.height = '';
    data.content.style.display = '';
    data.content.style.opacity = '';
  }
}

// - GESTION DES ACTIONS SUR LES ELEMENTS DU MENU - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// ACTION : Demande d'ouverture ou de fermeture via le clic "souris" sur le bouton
function click(_e, index) {
  // Identification de l'objet de donnees
  const data = Data.convertlist[index];
  if (data != null) change(data, null, false, true);
}

// ACTION : Demande d'ouverture ou de fermeture via la navigation clavier
function keyboard(e, index) {
  let b;
  // Identification de l'objet de donnees
  const data = Data.convertlist[index];
  if (data != null) {
    // Verification de la touche choisie
    let k = null;
    if (e.keyCode) k = e.keyCode;
    else if (e) k = e.which ? e.which : 0;
    // Identification de l'objet de l'appel
    if (
      e.target == data.button &&
      data.dependencies.length > 0 &&
      data.indexdependency != null
    ) {
      // Titre d'un panneau
      if (k == 104 || k == 38 || k == 37 || k == 100) {
        // - - - - - - - - - - - - - - - - - - - - - - - - -> deplacement de la selection (touche "8" ou "arrow up" ou "arrow left" ou "4")
        if (data.indexdependency >= 0) {
          Data.convertlist[
            data.dependencies[data.indexdependency]
          ].button.focus();
        }

        b = false;
      } else if (k == 98 || k == 40 || k == 39 || k == 102) {
        // - - - - - - - - - - - - - - - - - - - - -> deplacement de la selection (touche "2" ou "arrow bottom" ou "arrow right" ou "6")
        if (data.indexdependency < data.dependencies.length - 1) {
          Data.convertlist[
            data.dependencies[data.indexdependency + 1]
          ].button.focus();
        }

        b = false;
      } else if (k == 13 || k == 32) {
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -> ouverture/fermeture du menu (touche "enter" ou "space"){
        change(data, null, null, true, true);
        b = false;
      } else if (k == 36 || k == 35) {
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -> deplacement de la selection au debut ou la fin du menu (touche "home" ou "end"){
        if (
          (data.indexdependency < 0 && k == 36) ||
          (data.indexdependency > data.dependencies.length - 2 && k == 35)
        ) {
          data.button.focus();
        } else {
          Data.convertlist[
            data.dependencies[k == 36 ? 0 : data.dependencies.length - 1]
          ].button.focus();
        }

        b = false;
      } else b = true;
    } else if (e.ctrlKey && (k == 38 || k == 37)) {
      // Element interactif de l'accordeon
      data.button.focus();
      b = false;
    } else b = true;
  } else b = true;
  // Autorisation de la suite d'evenement
  if (b) {
    return true;
  }

  e.preventDefault();
  return false;
}

// ACTION : Demande specifique d'ouverture
function open(index, noaction, dependencies, noanim) {
  const data = Data.convertlist[index];
  if (data != null)
    change(data, true, noaction, dependencies === true, false, noanim);
}

// ACTION : Demande specifique de fermeture
function close(index, noaction, dependencies, noanim) {
  const data = Data.convertlist[index];
  if (data != null)
    change(data, false, noaction, dependencies === true, false, noanim);
}

// - GESTION DE L'OUVERTURE ET FERMETURE DE PANNEAUX  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// OUVERTURE/FERMETURE : Activation et desactivation des fonctionnalites
function change(data, view, noaction, dependencies, focus, noanim) {
  // Recherche de l'etat a afficher
  view =
    view === true || view === false
      ? view
      : !(data.parent.dataset.expanded === 'true');
  // Verification du changement demande
  if (
    data.parent.dataset.accordion == 'true' &&
    String(view) != data.parent.dataset.expanded
  ) {
    // Recuperation de la hauteur reelle des liens
    let h = calcul(data);
    // Calcul de la nouvelle hauteur a definir pour l'anim via CSS
    const i = data.buttoninside ? data.button.offsetHeight + 'px' : 0;
    const t = !view ? i : h + 'px';
    // Assignation de la hauteur actuelle
    data.parent.dataset.noanim = true;
    data.container.style.height = !view ? h + 'px' : i;
    data.content.style.display = '';
    // Autorisation d'affichage
    data.parent.dataset.expanded = view;
    data.button.setAttribute('aria-selected', view);
    data.button.setAttribute('aria-expanded', view);
    data.content.setAttribute('aria-hidden', !view);
    // Lancement des fermetures des panneaux lies lors de l'ouverture
    if (view && dependencies && data.dependencies.length > 0) {
      h = data.dependencies.length - 1;
      for (h = h; h >= 0; h--) {
        close(data.dependencies[h], true, false);
      }
    }

    // Gestion du focus sur le panneau et des panneaux associes
    if (dependencies && data.dependencies.length > 0) {
      // Assignation du focus sur le panneau appelant la fonction
      data.button.setAttribute('tabindex', 0);
      // Desactivation des panneaux lies (Skip focus for other footer's menu buttons) (Bug #ENG-34202)
      // h = data.dependencies.length - 1;
      // for (h = h; h >= 0; h--) {
      //   Data.convertlist[data.dependencies[h]].button.setAttribute('tabindex', '-1');
      // }
    } else if (data.dependencies.length === 0) {
      // Activation du panneau independant
      data.button.setAttribute('tabindex', 0);
    }

    // Autorisation de lancement des scripts callback en debut et fin d'animation
    if (noaction) delete data.parent.dataset.callback;
    else data.parent.dataset.callback = true;
    // Lancement de l'affichage anime
    if (noanim === true) {
      data.container.style.height = t;
    } else if (noaction)
      setTimeout(function () {
        delete data.parent.dataset.noanim;
        data.container.style.height = t;
      }, 10);
    else if (data.callback.beforeanim(null, data, view))
      setTimeout(function () {
        delete data.parent.dataset.noanim;
        data.container.style.height = t;
      }, 10);
  }
}

// OUVERTURE/FERMETURE : Nettoyage des styles CSS apres animation
function endanim(e, index) {
  // Identification de l'objet de donnees
  const data = Data.convertlist[index];
  if (data != null) {
    const a = Boolean(data.parent.dataset.callback);
    // Nettoyage des styles CSS de l'animation
    data.container.style.height = '';
    data.content.style.display = '';
    data.content.style.opacity = '';
    delete data.parent.dataset.callback;
    delete data.parent.dataset.noanim;
    // Lancement de l'appel "callback" si besoin
    if (a)
      data.callback.afteranim(
        null,
        data,
        !(data.parent.dataset.expanded === 'true')
      );
  }
}

// OUVERTURE/FERMETURE : Calcul de la hauteur du bloc de contenu pour l'animation
function calcul(data) {
  let height;
  data.parent.dataset.noanim = true;
  data.container.style.height = data.buttoninside
    ? data.button.offsetHeight + 'px'
    : 0;
  data.content.style.display = 'block';
  data.content.style.opacity = '0';
  height = data.content.offsetHeight;
  if (data.buttoninside) height += data.button.offsetHeight;
  data.container.style.height = '';
  data.content.style.display = '';
  data.content.style.opacity = '';
  delete data.parent.dataset.noanim;
  return height;
}

// - GESTION DES FONCTIONS PUBLIQUES  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

const accordionHandler = {
  // Fonction de convertion d'un conteneur en bloc "expand/collapse"
  convert, // Parametres de la fonction : Accordion.convert(container, button, animatedcontent, realcontent, params)
  //    > container             // [NODE]               (mandatory)   : Reference de la balise "conteneur global" comprenant le bouton et le contenu
  //    > button              // [NODE]               (mandatory)   : Reference de la balise "bouton" gerant l'ouverture et la fermeture du contenu.
  //    > animatedcontent         // [NODE]               (mandatory)   : Reference de la balise a animer lors de l'ouverture et la fermeture.
  //    > realcontent           // [NODE]               (mandatory)   : Reference de la balise utilisee comme reference pour la hauteur du panneau ouvert (doit etre un enfant de "animatedcontent").
  //    > params              // [OBJECT]             (optional)    : Objet de personnalisation du composant
  //      > params.clickonicon      // [BOOLEAN] (default value: false)         : Indicateur d'ajout des actions de souris sur l'icone et non l'entierete du bouton
  //      > params.openbtn        // [NODE]                     : Conteneur du bouton d'ouverture  [+] d'origine (utilise sans JS) a supprimer dans la version JS
  //      > params.closebtn         // [NODE]                     : Conteneur du bouton de fermeture [-] d'origine (utilise sans JS) a supprimer dans la version JS
  //      > params.cb_afterconvert    // [FUNCTION] (need a return value = true)    : Fonction appelee apres la convertion du bloc
  //      > params.cb_beforeanim    // [FUNCTION] (need a return value = true)    : Fonction appelee avant l'animation d'ouverture ou la fermeture du bloc de contenu
  //      > params.cb_afteranim       // [FUNCTION] (need a return value = true)    : Fonction appelee apres l'animation d'ouverture ou la fermeture du bloc de contenu
  //
  // Reponse de la fonction : Accordion.convert() = result
  //    > result.converted          // [BOOLEAN]                  : Defini si la fonctionnalite a pu etre ajoutee ou non
  //    > result.data             // [OBJECT]                     : Objet de donnees contenant toutes les informations du composant converti (vaut "null" si la convertion a echoue)

  // Fonction de liaison des autres composants "accordion" deja convertis a fermer lors de l'ouverture du composant principal
  setdependency, // Parametres de la fonction : Accordion.setdependency(reference, dependencies)
  //    > reference           // [NUMBER]               (mandatory) : Reference (numerique) du composant converti concerne
  //    > dependencies            // [ARRAY]                (mandatory) : Liste de references des composants (index "numerique") a associer lors de l'ouverture
  // Reponse de la fonction : Accordion.setdependency() = result
  //    > result.executed           // [BOOLEAN]                  : Defini si la demande a reussi ou non
  //    > result.data             // [OBJECT]                     : Objet de donnees contenant toutes les informations du composant

  // Fonction d'activation et de desactivation du composant apres convertion
  activate, // Parametres de la fonction : Accordion.activate(reference)
  //    > reference           // [NUMBER]               (mandatory) : Reference (numerique) du composant converti a activer
  desactivate, // Parametres de la fonction : Accordion.desactivate(reference)
  //    > reference           // [NUMBER]               (mandatory) : Reference (numerique) du composant converti a desactiver

  // Fonction demandant l'ouverture et fermeture d'un composant "accordion"
  open, // Parametres de la fonction : Accordion.open(reference, no-action, depedency, no-anim)
  //    > reference           // [NUMBER]               (mandatory) : Reference (numerique) du composant converti a activer
  //    > no-action           // [BOOLEAN] (default value: false)         : Defini si les fonctions "callback" doivent etre exectuee lors de l'action
  //    > dependencies          // [BOOLEAN] (default value: false)         : Defini si l'action doit modifier l'etat des composants associes
  //    > no-anim             // [BOOLEAN] (default value: false)         : Defini si l'action doit etre animee ou non
  // Fonction d'activation du composant apres convertion
  close, // Parametres de la fonction : Accordion.close(reference, no-action, depedency, no-anim)
  //    > reference           // [NUMBER]               (mandatory) : Reference (numerique) du composant converti a desactiver
  //    > no-action           // [BOOLEAN] (default value: false)         : Defini si les fonctions "callback" doivent etre exectuee lors de l'action
  //    > dependencies          // [BOOLEAN] (default value: false)         : Defini si l'action doit modifier l'etat des composants associes
  //    > no-anim             // [BOOLEAN] (default value: false)         : Defini si l'action doit etre animee ou non
};

export { accordionHandler as Accordion };
