/* eslint-env browser */
/* eslint prefer-arrow-callback: "off",  no-var: "off" */

// AMD define

function loader(context) {
  // bailout if our bootstrap is not present
  if (!context.__amd$delayedDefines) {
    return;
  }

  var ENG_SCRIPTS_MODULE = 'eng-scripts';
  var loadedModules = {};
  var queuedModules = [];
  var fetchDependencyIfNeeded = function fetchDependencyIfNeeded(dependency) {
    var ref;
    var refs;
    var element;
    var script;
    var scripts = loadedModules[ENG_SCRIPTS_MODULE];
    if (
      scripts &&
      Object.prototype.hasOwnProperty.call(scripts, dependency) &&
      !scripts[dependency].fetched
    ) {
      script = scripts[dependency];
      script.fetched = true;
      element = document.createElement('script');
      element.setAttribute('type', 'text/javascript');
      element.setAttribute('async', '');
      element.setAttribute('src', script.url);
      refs = document.querySelectorAll('head')[0].childNodes;
      ref = refs[refs.length - 1];
      // Using insertBefore instead of appendChild
      // see http://www.paulirish.com/2011/surefire-dom-element-insertion/
      ref.parentNode.insertBefore(element, ref.nextSibling);
    }
  };

  var inQueue = function inQueue(dependency) {
    var qm;
    for (const queuedModule of queuedModules) {
      qm = queuedModule;
      if (qm[0] === dependency) {
        return true;
      }
    }

    return false;
  };

  var dependenciesAreLoaded = function (dependencies) {
    var dependency;
    var allLoaded = true;
    for (const dependency_ of dependencies) {
      dependency = dependency_;
      if (!Object.prototype.hasOwnProperty.call(loadedModules, dependency)) {
        allLoaded = false;
        if (!inQueue(dependency)) {
          fetchDependencyIfNeeded(dependency);
        }
      }
    }

    return allLoaded;
  };

  var queueModule = function (id, dependencies, factory) {
    queuedModules.push([id, dependencies, factory]);
  };

  var loadModule = function (id, dependencies, factory) {
    var module = {};
    var exports;

    dependencies = dependencies.map((dependency) => {
      if (dependency === 'module') {
        return module;
      }

      if (dependency === 'exports') {
        module.exports = {};
        return module.exports;
      }

      return loadedModules[dependency];
    });

    exports = factory.apply(context, dependencies);

    if (exports) {
      module.exports = exports;
      // !!! Highcharts exception
      if (
        !id &&
        exports.product &&
        typeof exports.product === 'string' &&
        exports.product.toLowerCase() === 'highcharts'
      ) {
        id = 'highcharts';
        if (context[exports.product] === undefined) {
          context[exports.product] = exports;
        }
      }
    }

    if (id) {
      module.id = id;
      loadedModules[id] = module.exports;
      evaluateQueuedModules(id);
    } else if (
      exports &&
      typeof exports === 'function' &&
      (exports.toString().includes('highcharts') ||
        exports.toString().includes('chart'))
    ) {
      // !!! Highcharts exception: register the module w/ Highcharts
      try {
        exports(context.Highcharts);
      } catch (error) {
        console.log(error);
      }
    }
  };

  var evaluateQueuedModules = function (_id) {
    var module;
    var i = 0;
    while (i < queuedModules.length) {
      module = queuedModules[i];
      if (dependenciesAreLoaded(module[1])) {
        queuedModules.splice(i, 1);
        loadModule(...module);
      } else {
        i++;
      }
    }
  };

  const extractId = (args) =>
    typeof args[0] === 'string' ? [args[0], args.slice(1)] : [null, args];

  const extractDependencies = (args) =>
    Array.isArray(args[0])
      ? [args[0], args[1]]
      : [['require', 'exports', 'module'], args[0]];

  const extractFactory = (last) =>
    typeof last === 'object' ? () => last : last;

  const define = function (...args /* id, dependencies, factory */) {
    // `define(id, dependencies, factory)`
    // `define(dependencies, factory)`
    // `define(id, factory)`
    // `define(factory)`

    const [id, rest] = extractId(args);

    if (id !== null && (loadedModules[id] || inQueue(id))) {
      return;
    }

    const [dependencies, last] = extractDependencies(rest);

    const factory = extractFactory(last);

    if (dependenciesAreLoaded(dependencies)) {
      loadModule(id, dependencies, factory);
    } else {
      queueModule(id, dependencies, factory);
    }
  };

  define.amd = { loadedModules, queuedModules };

  define('require', [], function factory() {
    return function require(...args) {
      const [id] = args;
      if (typeof id === 'string') {
        return loadedModules[id];
      }

      define.apply(context, args);
    };
  });

  define('exports', [], function factory() {});
  define('module', [], function factory() {});

  /* eslint-disable unicorn/prefer-module, camelcase */
  if (typeof module === 'object' && typeof exports === 'object') {
    module.exports = define;
  } else {
    context.define = define;
    if (context.__amd$delayedDefines) {
      for (const fn of context.__amd$delayedDefines) {
        fn();
      }

      context.__amd$delayedDefines = [];
    }
  }
  /* eslint-enable unicorn/prefer-module, camelcase */
}

export { loader };
